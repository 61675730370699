import { Draggable } from '@hello-pangea/dnd'
import { ActionIcon, Group, rem, Stack, Text, Tooltip } from '@mantine/core'
import { IconGripVertical, IconMinus } from '@tabler/icons-react'
import cx from 'clsx'

import classes from '~/components/dashboard/draggable_agenda_item.module.css'
import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import { AgendaItemIndexView } from '~/types/agenda_item'

type DraggableAgendaItemProps = {
  agendaItem: AgendaItemIndexView
  index: number
  remove: (itemId: string) => void
}

function DraggableAgendaItem({
  agendaItem,
  index,
  remove,
}: DraggableAgendaItemProps) {
  return (
    <Draggable draggableId={agendaItem.id} index={index} key={agendaItem.id}>
      {(provided, snapshot) => (
        <Group
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          justify="space-between"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Group>
            <div {...provided.dragHandleProps} className={classes.dragHandle}>
              <IconGripVertical style={{ height: rem(18), width: rem(18) }} />
            </div>
            <LabeledAvatar size="sm" user={agendaItem.creator} />
            <Stack gap="0">
              <Text>{agendaItem.title}</Text>
              <Text c="gray" fz="sm">
                {agendaItem.description}
              </Text>
            </Stack>
          </Group>
          {agendaItem.meeting_id && (
            <Tooltip
              label="Remove from this meeting"
              onClick={() => remove(agendaItem.id)}
            >
              <ActionIcon size="sm" variant="outline">
                <IconMinus />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      )}
    </Draggable>
  )
}

export default DraggableAgendaItem
