import { Container, Group, rem } from '@mantine/core'
import {
  IconFriends,
  IconHierarchy,
  IconHierarchy2,
  IconHierarchy3,
  IconRadioactive,
  IconWindow,
} from '@tabler/icons-react'
import { Outlet } from 'react-router-dom'

import SecondNav, { NavItem } from '~/components/dashboard/second_nav'
import { useSession } from '~/utils/session'

const managerSubNavItems: NavItem[] = [
  {
    i18nKey: 'nav:direct_reports',
    icon: <IconHierarchy />,
    url: 'direct_reports',
  },
  { i18nKey: 'nav:all_reports', icon: <IconHierarchy3 />, url: 'all_reports' },
  { i18nKey: 'nav:my_peers', icon: <IconFriends />, url: 'peers' },
]

const employeeSubNavItems: NavItem[] = [
  { i18nKey: 'nav:my_department', icon: <IconWindow />, url: 'department' },
]

function MyTeamPage() {
  const { user } = useSession()
  const subNavItems =
    user.has_reports === true ? managerSubNavItems : employeeSubNavItems

  const finalNavItems = [
    ...subNavItems,
    ...(user.has_managers === true
      ? [
          {
            i18nKey: 'nav:managers',
            icon: <IconRadioactive />,
            url: 'managers',
          },
        ]
      : []),
    { i18nKey: 'nav:org_chart', icon: <IconHierarchy2 />, url: 'org_chart' },
  ]

  return (
    <Group className="fullHeight">
      <SecondNav items={finalNavItems} title="Users" />
      <Container
        className="yScroll"
        fluid
        h="100%"
        pl={rem(192)}
        pt="md"
        w="100%"
      >
        <Outlet context={useSession()} />
      </Container>
    </Group>
  )
}

export default MyTeamPage
