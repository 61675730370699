import { Container, Grid, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useSWRImmutable from 'swr/immutable'

import ColleagueCard from '~/components/dashboard/colleague_card'
import VoynLoadingOverlay from '~/components/shared/loading'
import { UserReportView } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

export default function MyDepartmentPage() {
  const { user: currentUser } = useSession()
  const { t: tra } = useTranslation()

  const {
    data: users,
    error,
    isLoading,
  } = useSWRImmutable(
    [`/api/v1/users/${currentUser.id}/department`, currentUser.id],
    ([url]) => Fetcher.get<{}, UserReportView[]>(url),
  )

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (error) {
    return <h1>ERROR</h1>
  }

  if (users) {
    return (
      <Container fluid>
        <Title mb="sm" order={3}>
          {tra('team:my_department')}
        </Title>
        <Grid>
          {users &&
            users.map((user) => (
              <Grid.Col key={user.id} span={3}>
                <ColleagueCard user={user} />
              </Grid.Col>
            ))}
        </Grid>
      </Container>
    )
  }

  return null
}
