import {
  Avatar,
  Combobox,
  Group,
  Input,
  InputBase,
  Stack,
  Text,
  TextInputProps,
  useCombobox,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { User } from '~/types/user'

type UserSingleSelectProps = TextInputProps & {
  onChange: (value: string | null) => void
  users: User[]
}

function getFilteredOptions(users: User[], search: string, limit: number) {
  const normalizedSearch = search.trim().toLowerCase()

  return users
    .filter(
      (user) =>
        user.full_name?.toLowerCase().includes(normalizedSearch) ||
        user.email.toLowerCase().includes(normalizedSearch),
    )
    .slice(0, limit)
}

function SelectOption({ user }: { user: User }) {
  return (
    <Group gap="sm">
      <Avatar size="sm" src={user.profile_picture_url} />
      <Stack gap="0">
        <Text fz="sm">{user.full_name ?? user.email}</Text>
        <Text c="gray" fz="xs">
          {user.title}
        </Text>
      </Stack>
    </Group>
  )
}

function UserSingleSelect(props: UserSingleSelectProps) {
  const { description, error, label, onChange, placeholder, users, value } =
    props
  const [val, setValue] = useState<string | null>(value?.toString() || null)
  const [search, setSearch] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const { t: tra } = useTranslation()

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch('')
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput()
    },
  })

  useEffect(() => {
    setSelectedUser(users.find((u) => u.id === value) || null)
  }, [value])

  useEffect(() => {
    onChange(val)
  }, [val])

  const filteredOptions = getFilteredOptions(users, search, 5)

  const options = filteredOptions.map((u) => (
    <Combobox.Option key={u.id} value={u.id}>
      <SelectOption user={u} />
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(newVal) => {
        setValue(newVal) // Update the component state
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target data-testid="user-single-select-input-combobox">
        <InputBase
          component="button"
          description={description}
          error={error}
          label={label}
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          type="button"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedUser ? (
            selectedUser.full_name ?? selectedUser.email
          ) : (
            <Input.Placeholder>{placeholder}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          data-testid="user-single-select-search-input"
          placeholder={tra('common:searchUser')}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
        {options}
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default UserSingleSelect
