import {
  Container,
  Flex,
  Grid,
  SegmentedControl,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { TFunction } from 'i18next'
import type { Moment } from 'moment'
import { useState } from 'react'

type ViewOption = 'month' | 'week' | 'day'

const formatMonthAndYear = (date: Moment, tra: TFunction) => {
  const [monthName, year] = date.format('MMMM YYYY').split(' ')
  return `${tra(`calendar:${monthName.toLowerCase()}`)} ${year}`
}

function DateDisplay({ date, tra }: { date: Moment; tra: TFunction }) {
  return (
    <Text fw={500} size="xl">
      {date.clone().startOf('isoWeek').format('DD')} -{' '}
      {date.clone().endOf('isoWeek').format('DD')}{' '}
      {formatMonthAndYear(date, tra)}
    </Text>
  )
}

function ViewOptions({
  onChange,
  value,
}: {
  onChange: (value: ViewOption) => void
  value: 'month' | 'week' | 'day'
}) {
  return (
    <SegmentedControl
      data={[
        { label: 'Month', value: 'month' },
        { label: 'Week', value: 'week' },
        { label: 'Day', value: 'day' },
      ]}
      radius="lg"
      size="md"
      value={value}
      onChange={(val) => onChange(val as ViewOption)}
    />
  )
}

function WeekNavigator({
  date,
  onNext,
  onPrevious,
}: {
  date: Moment
  onNext: (date: Moment) => void
  onPrevious: (date: Moment) => void
}) {
  return (
    <Flex
      align="center"
      direction="row"
      gap="md"
      justify="center"
      mih={50}
      wrap="wrap"
    >
      <UnstyledButton onClick={() => onPrevious(date)}>
        &#60;&#60; Previous Week
      </UnstyledButton>
      <UnstyledButton onClick={() => onNext(date)}>
        Next Week &#62;&#62;
      </UnstyledButton>
    </Flex>
  )
}

export default function CalendarTopPanel({
  date,
  setNextWeek,
  setPreviousWeek,
  tra,
}: {
  date: Moment
  setNextWeek: (date: Moment) => void
  setPreviousWeek: (date: Moment) => void
  tra: TFunction
}) {
  const [view, setView] = useState<'month' | 'week' | 'day'>('month')

  const handleDateChange = (newDate: Date) => {}

  const handleViewChange = (newView: 'month' | 'week' | 'day') => {
    setView(newView)
  }

  return (
    <Container fluid style={{ padding: '5px' }}>
      <Grid align="flex-start" gutter="md">
        <Grid.Col span={1}>
          <Stack>
            <DateDisplay date={date} tra={tra} />
            <Text size="xs">{tra('calendar:description')}</Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={1}>
          <WeekNavigator
            date={date}
            onNext={setNextWeek}
            onPrevious={setPreviousWeek}
          />
        </Grid.Col>
      </Grid>
    </Container>
  )
}
