import { Badge, Loader } from '@mantine/core'
import useSWR from 'swr'

import { ManagerNoteDetailView } from '~/types/manager_note'
import Fetcher from '~/utils/fetcher'

function ManagerNotesTab({
  openManagerNoteModal,
  setSelectedManagerNote,
  userId,
}: {
  openManagerNoteModal: () => void
  setSelectedManagerNote: (note: ManagerNoteDetailView) => void
  userId: string
}) {
  const { data: managerNotes, isLoading } = useSWR(
    ['/api/v1/manager_notes', userId],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([url, userId]) =>
      Fetcher.get<{ user_id: string }, ManagerNoteDetailView[]>(url, {
        user_id: userId,
      }),
  )

  if (!managerNotes || isLoading) {
    return <Loader />
  }

  return (
    <>
      {managerNotes.map((note) => (
        <Badge
          onClick={() => {
            setSelectedManagerNote(note)
            openManagerNoteModal()
          }}
        >
          {note.body}
        </Badge>
      ))}
    </>
  )
}

export default ManagerNotesTab
