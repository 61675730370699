import { FileButton, UnstyledButton } from '@mantine/core'
import { Link, RichTextEditor } from '@mantine/tiptap'
import { IconFileUpload } from '@tabler/icons-react'
import type { Editor } from '@tiptap/core'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect } from 'react'

import Fetcher from '~/utils/fetcher'

/* eslint-disable no-param-reassign */
export interface CheckinEditorProps {
  checkinId: string
  editorContent: string
  editorRef: React.MutableRefObject<Editor>
  isEditable: boolean
  onEditorContentUpdated: ({ editor }: { editor: Editor }) => void
}

export default function CheckinEditor({
  checkinId,
  editorContent,
  editorRef,
  isEditable,
  onEditorContentUpdated,
}: CheckinEditorProps) {
  const editor = useEditor({
    content: editorContent,
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      Image.configure({ allowBase64: true }),
      TextAlign.configure({ types: ['paragraph'] }),
    ],
    onUpdate: onEditorContentUpdated,
  })

  useEffect(() => {
    if (editor) {
      editorRef.current = editor
    }
  }, [editor])

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(editorContent)
    }
  }, [editorContent])

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditable)
    }
  }, [editor, isEditable])

  const uploadImage = async (file: File | null) => {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    const response = await Fetcher.uploadFile(
      `/api/v1/checkins/${checkinId}/responses/upload_image`,
      { body: formData },
    )

    editor?.commands.setImage({ src: response.blob_url })
  }

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
          <FileButton accept="image/png,image/jpeg" onChange={uploadImage}>
            {(props) => (
              <UnstyledButton
                {...props}
                style={{
                  border: '1px solid var(--mantine-color-gray-4)',
                  borderBottomRightRadius: 4,
                  borderTopRightRadius: 4,
                  display: 'flex',
                  height: 24,
                  justifyContent: 'center',
                  minWidth: 'calc(1.625rem* var(--mantine-scale))',
                  width: 24,
                }}
              >
                <IconFileUpload
                  color="var(--mantine-color-gray-6)"
                  style={{ height: 16, marginTop: 4, width: 16 }}
                />
              </UnstyledButton>
            )}
          </FileButton>
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  )
}
