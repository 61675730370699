import { Loader, Stack } from '@mantine/core'
import useSWR from 'swr'

import MeetingCard from '~/components/dashboard/meeting_card'
import { MeetingIndexView } from '~/types/meeting'
import Fetcher from '~/utils/fetcher'
import {
  filterToPayload,
  MeetingFilter,
  MeetingFilterPayload,
} from '~/utils/meeting_filter'

function MeetingsTab(props: MeetingFilter) {
  const {
    data: meetings,
    error,
    isLoading,
  } = useSWR({ ...props, url: '/api/v1/meetings' }, ({ url, ...rest }) => {
    return Fetcher.get<MeetingFilterPayload, MeetingIndexView[]>(
      url,
      filterToPayload(rest),
    )
  })

  if (isLoading) {
    return <Loader data-testid="meetings-tab-loader" />
  }

  if (error) {
    return <h1>ERROR</h1> // TODO: Handle error
  }

  if (meetings) {
    return (
      <Stack p="sm">
        {meetings.map((meeting) => (
          <MeetingCard key={meeting.id} meeting={meeting} />
        ))}
      </Stack>
    )
  }
}

export default MeetingsTab
