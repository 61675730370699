import { Button, Center, Image, Paper, Text } from '@mantine/core'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'

import FreeTextQuestion from '~/components/pulse/free_text_question'
import MultipleChoiceQuestion from '~/components/pulse/multiple_choice_question'
import SentimentQuestion from '~/components/pulse/sentiment_question'
import SingleChoiceQuestion from '~/components/pulse/single_choice_question'
import ErrorAlert from '~/components/shared/error_alert'
import VoynLoadingOverlay from '~/components/shared/loading'
import Logo from '~/images/logo.png'
import classes from '~/pages/pre-auth/pulse.module.css'
import { Question } from '~/types/pulse'
import VoynError, { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

type PulseResponse = {
  answer: string
  question_id: string
  question_label: string
}

type PulseAnswerRequestPayload = {
  pulse_id: string
  responses: PulseResponse[]
  user_id: string
}

type DecryptedPulseResponse = {
  id: string
  node_id: string
  pulse_id: string
  questions: Question[]
  user_id: string
}

function buildPulseAnswerPayload(
  deliveryId: string,
  pulseId: string,
  responses: Record<string, string>,
  userId: string,
  nodeId: string,
) {
  return {
    delivery_id: deliveryId,
    node_id: nodeId,
    pulse_id: pulseId,
    responses: Object.keys(responses).map((questionId) => {
      return {
        answer: responses[questionId][1],
        question_id: questionId,
        question_label: responses[questionId][0],
      }
    }),
    user_id: userId,
  }
}

function renderQuestion(
  question: Question,
  onResponseChange: (id: string, questionLabel: string, value: string) => void,
) {
  const commonProps = {
    handleChange: onResponseChange,
    key: question.id,
    question,
  }

  switch (question.question_type) {
    case 'free_text':
      return <FreeTextQuestion {...commonProps} />
    case 'single_choice':
      return <SingleChoiceQuestion {...commonProps} />
    case 'multiple_choice':
      return <MultipleChoiceQuestion {...commonProps} />
    case 'sentiment':
      return <SentimentQuestion {...commonProps} />
    default:
      return null
  }
}

function SuccessMessage() {
  // TODO: Translation
  return (
    <Center style={{ flexDirection: 'column', height: '100vh' }}>
      <Text size="lg">
        Thank you! Your responses have been submitted successfully.
      </Text>
    </Center>
  )
}

function PulsePage() {
  const [searchParams] = useSearchParams()
  const hash = searchParams.get('hash')

  const [responses, setResponses] = useState<Record<string, string[]>>({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleResponseChange = (
    id: string,
    questionLabel: string,
    value: string,
  ) => {
    setResponses((prev) => ({ ...prev, [id]: [questionLabel, value] }))
  }

  const { data, error, isLoading } = useSWRImmutable(
    hash ? `/api/v1/pulse_dispatches/${hash}` : null,
    (url) => Fetcher.get<{}, DecryptedPulseResponse>(url),
  )

  const handleSubmit = async (
    deliveryId: string,
    nodeId: string,
    pulseId: string,
    userId: string,
    userResponses: any,
    questions: Question[],
  ) => {
    try {
      // By default, all the questions are required.
      // TODO: Strip the text answers before doing this validation.
      questions.forEach((question) => {
        if (
          !userResponses[question.id as string] ||
          userResponses[question.id as string][1].length <= 0
        ) {
          throw new VoynError('Please answer all the questions')
        }
      })
      await Fetcher.post<PulseAnswerRequestPayload, {}>(
        '/api/v1/pulse_submissions',
        buildPulseAnswerPayload(
          deliveryId,
          pulseId,
          userResponses,
          userId,
          nodeId,
        ),
      )
      setIsSubmitted(true)
    } catch (e) {
      showErrorNotification(e)
    }
  }

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (error || !data) {
    return <ErrorAlert error={error} />
  }

  if (isSubmitted) {
    return <SuccessMessage />
  }

  return (
    <div className={classes.pulseContainer}>
      <Paper maw={540} p="md" radius="0" w="100%" withBorder>
        <Center>
          <Image alt="voyn.io Logo" fit="contain" mb="md" src={Logo} w={160} />
        </Center>
        <form
          className={classes.pulseForm}
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(
              data.id,
              data.node_id,
              data.pulse_id,
              data.user_id,
              responses,
              data.questions,
            )
          }}
        >
          {data.questions
            .sort((a, b) => a.order - b.order)
            .map((question) => renderQuestion(question, handleResponseChange))}
          <Button mt="sm" size="md" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </div>
  )
}

export default PulsePage
