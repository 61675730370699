import {
  Avatar,
  Box,
  Button,
  FileInput,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { useForm, zodResolver } from '@mantine/form'
import { IconUpload } from '@tabler/icons-react'
import { useState } from 'react'
import { z } from 'zod'

import { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'

const basicInfoSchema = z.object({
  email: z.string().email(),
  first_name: z.string().min(2),
  last_name: z.string().min(2),
  timezone: z.string(),
  title: z.string().min(3),
  working_hours: z.array(z.string()),
})

type BasicInfoType = z.infer<typeof basicInfoSchema>

export default function BasicInfo({ user }: { user: User }) {
  const [isLoading, setIsLoading] = useState(false)
  const [avatarPreview, setAvatarPreview] = useState<string | null>(
    user.profile_picture_url,
  )
  const form = useForm({
    initialValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      timezone: user.timezone || '',
      title: user.title,
      working_hours: user.working_hours || [],
    },
    validate: zodResolver(basicInfoSchema),
  })

  const handleSubmit = async (values: BasicInfoType) => {
    setIsLoading(true)
    await Fetcher.put(`/api/v1/users/${user.id}`, values)
    setIsLoading(false)
  }

  const handleAvatarChange = async (file: File | null) => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response: User = await Fetcher.uploadFile(
        `/api/v1/users/${user.id}/change_avatar`,
        {
          body: formData,
        },
      )
      setAvatarPreview(response.profile_picture_url)
    }
  }

  return (
    <Box maw={400} mx="auto">
      <LoadingOverlay
        overlayProps={{ blur: 2, radius: 'sm' }}
        visible={isLoading}
        zIndex={1000}
      />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Group align="flex-end" mb="md">
          <Avatar radius="xl" size="xl" src={avatarPreview} />
          <FileInput
            accept="image/*"
            leftSection={<IconUpload size={14} />}
            placeholder="Choose avatar"
            onChange={handleAvatarChange}
          />
        </Group>

        <Stack gap="sm">
          <TextInput
            label="First Name"
            placeholder="Your name"
            {...form.getInputProps('first_name')}
            required
          />

          <TextInput
            label="Last Name"
            placeholder="Your name"
            {...form.getInputProps('last_name')}
            required
          />

          <TextInput
            disabled
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps('email')}
            required
          />

          <TextInput
            label="Job Title"
            placeholder="Your job title"
            {...form.getInputProps('title')}
          />

          <Select
            data={Intl.supportedValuesOf('timeZone')}
            label="Timezone"
            placeholder="Your timezone"
            {...form.getInputProps('timezone')}
            required
          />

          <Stack>
            <Text size="sm">Working Hours</Text>
            <Group style={{ marginTop: -15 }}>
              <TimeInput
                value={form.values.working_hours[0]}
                onChange={(e) =>
                  form.setFieldValue('working_hours.0', e.target.value)
                }
                onFocus={(e) => e.target.showPicker()}
              />
              <TimeInput
                value={form.values.working_hours[1]}
                onChange={(e) =>
                  form.setFieldValue('working_hours.1', e.target.value)
                }
                onFocus={(e) => e.target.showPicker()}
              />
            </Group>
          </Stack>

          <Button fullWidth mt="md" type="submit">
            Update Profile
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
