import { LoadingOverlay } from '@mantine/core'
import { Navigate, Outlet } from 'react-router-dom'

import {
  fetchSession,
  homePath,
  isUserToBeOnboarded,
  isUserToBeTakenDashboard,
} from '~/utils/session'

function PreAuthLayout() {
  const { isLoading, session } = fetchSession()

  if (isLoading) {
    return <LoadingOverlay overlayProps={{ blur: 2 }} visible />
  }

  if (session && isUserToBeOnboarded(session)) {
    return <Navigate replace to="onboarding" />
  }

  if (session && isUserToBeTakenDashboard(session)) {
    return <Navigate replace to={homePath} />
  }

  return <Outlet />
}

export default PreAuthLayout
