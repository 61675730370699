import { LineChart } from '@mantine/charts'
import { Grid, Loader, Paper, Title } from '@mantine/core'
import { IconHeartbeat } from '@tabler/icons-react'
import dayjs from 'dayjs'
import useSWR from 'swr'

import { choices } from '~/components/pulse/sentiment_question'
import { PulseResponse } from '~/types/pulse_response'
import Fetcher from '~/utils/fetcher'

function formatYAxis(value: number) {
  return (
    choices.find((choice) => choice.value === value.toString())?.label || ''
  )
}

function mapDataForChart(responses: PulseResponse[]) {
  return responses.map((response: PulseResponse) => ({
    date: dayjs(response.created_at).format('YYYY-MM-DD'),
    sentiment: response.response,
  }))
}

function OverviewTab({ userId }: { userId: string }) {
  const { data, error, isLoading } = useSWR('/api/v1/pulse_responses', (url) =>
    Fetcher.get<{ question_type: string; user_id: string }, PulseResponse[]>(
      url,
      {
        question_type: 'sentiment',
        user_id: userId,
      },
    ),
  )

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <Grid py="md">
      <Grid.Col span={4}>
        <Paper p="md" withBorder>
          <Title c="gray.7" mb="sm" order={5}>
            <IconHeartbeat size="1rem" /> Pulses
          </Title>
          <LineChart
            curveType="bump"
            data={mapDataForChart(data)}
            dataKey="date"
            h={240}
            series={[{ color: 'gray.7', name: 'sentiment' }]}
            w="100%"
            yAxisProps={{
              tick: { fontSize: '1rem' },
              tickFormatter: formatYAxis,
              ticks: [0, 25, 50, 75, 100],
              width: 20,
            }}
          />
        </Paper>
      </Grid.Col>
    </Grid>
  )
}

export default OverviewTab
