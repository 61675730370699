import { Button, Group, Stack, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import type { Feedback } from '~/types/feedback'
import Fetcher from '~/utils/fetcher'
import { editFeedbackFormSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

type EditFeedbackFormSchema = z.infer<typeof editFeedbackFormSchema>

export default function EditFeedbackForm({
  feedback,
  onSubmit,
}: {
  feedback: Feedback
  onSubmit: () => void
}) {
  const { t: tra } = useTranslation()
  const form = useForm<EditFeedbackFormSchema>({
    initialValues: {
      is_draft: feedback.is_draft,
      responses_attributes: feedback.responses.map((response) => ({
        id: response.id,
        question: response.question,
        response: response.response,
      })),
    },
    validate: (values) => validateForm(editFeedbackFormSchema, values),
  })

  const submitForm = async (values: EditFeedbackFormSchema) => {
    await Fetcher.put(`/api/v1/feedbacks/${feedback.id}`, { feedback: values })
    onSubmit()
  }

  return (
    <form
      data-testid="edit-feedback-form-modal"
      onSubmit={form.onSubmit(submitForm)}
    >
      <Stack gap="lg">
        {feedback.responses.map((response, index: number) => (
          <Textarea
            autosize
            data-testid={`feedback-request-question-${index}-textarea`}
            defaultValue={form.values.responses_attributes[index].response}
            key={form.key(`responses_attributes.${index}.response`)}
            label={response.question}
            minRows={4}
            style={{ width: '100%' }}
            {...form.getInputProps(`responses_attributes.${index}.response`)}
          />
        ))}
        <Group justify="flex-end">
          <Button
            data-testid="save-draft-feedback-button"
            type="submit"
            onClick={() => form.setFieldValue('is_draft', true)}
          >
            {tra('feedback_requests:save_draft')}
          </Button>
          <Button
            data-testid="submit-feedback-button"
            type="submit"
            onClick={() => form.setFieldValue('is_draft', false)}
          >
            {tra('feedback_requests:submit_feedback')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
