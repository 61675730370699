import '@fontsource/asap'
import '@fontsource/asap/500.css'
import '@fontsource/asap/700.css'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/spotlight/styles.css'
import '@mantine/tiptap/styles.css'
import '@mantine/charts/styles.css'
import '~/styles.css'

import { MantineTheme, MantineThemeOverride } from '@mantine/core'

const theme: MantineThemeOverride = {
  black: '#27282A',
  colors: {
    blue: [
      '#E5F5FF',
      '#CFE7FF',
      '#9FCCFC',
      '#6BAFF8',
      '#4297F5',
      '#2788F4',
      '#1480F4',
      '#006EDA',
      '#0061C4',
      '#0054AE',
    ],
    brand: [
      '#FEFBFC',
      '#FFE5EC',
      '#FFD5DF',
      '#FFABBF',
      '#FF82A0',
      '#FF5880',
      '#FF2E60',
      '#CC254D',
      '#991C3A',
      '#661226',
    ],
    green: [
      '#EDFEE7',
      '#DEF8D4',
      '#BEEFAB',
      '#9BE67E',
      '#7EDE57',
      '#6BD940',
      '#60D731',
      '#4FBE23',
      '#43A91B',
      '#34920D',
    ],
    orange: [
      '#FFF9E0',
      '#FFF2CA',
      '#FEE49A',
      '#FDD565',
      '#FCC839',
      '#FBC01C',
      '#FBBB06',
      '#E0A500',
      '#C79200',
      '#AD7D00',
    ],
    red: [
      '#FFE8E7',
      '#FFD1CE',
      '#FFA39C',
      '#FE7066',
      '#FC4639',
      '#FB2B1B',
      '#FC1A0B',
      '#E10A00',
      '#C90100',
      '#B00000',
    ],
  },
  components: {
    Anchor: {
      styles: (t: MantineTheme) => ({
        '&:hover': {
          color: t.colors.brand[6],
        },
        root: {
          color: t.black,
          textDecoration: 'underline',
        },
      }),
    },
  },
  fontFamily: 'Asap',
  primaryColor: 'brand',
}

export default theme
