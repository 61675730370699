import { AgendaItemIndexView } from '~/types/agenda_item'
import { MeetingDetailView } from '~/types/meeting'
import { User } from '~/types/user'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

export async function removeTalkingPointFromMeeting(
  id: string,
  cb: () => void,
) {
  try {
    await Fetcher.put<{ meeting_id: string | null }, AgendaItemIndexView[]>(
      `/api/v1/agenda_items/${id}`,
      {
        meeting_id: null,
      },
    )

    cb()
  } catch (e) {
    showErrorNotification(e)
  }
}

export async function startTheMeeting(id: string, cb: () => void) {
  try {
    await Fetcher.post<{}, MeetingDetailView>(`/api/v1/meetings/${id}/start`)
    cb()
  } catch (e) {
    showErrorNotification(e)
  }
}

export function getOtherUser(
  meetingDetail: MeetingDetailView,
  currentUserId: string,
): User {
  return meetingDetail.attendees.find(
    (user) => user.id !== currentUserId,
  ) as User
}
