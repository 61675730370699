import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { Checkin } from '~/types/checkin'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'

import CheckinForm from './form'

function EditCheckinsPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: nodes } = useSWR(`/api/v1/nodes`, (url) =>
    Fetcher.get<{}, VNode[]>(url),
  )
  const { data: checkin, isLoading: checkinLoading } = useSWR(
    `/api/v1/checkins/${id}`,
    (url) => Fetcher.get<{}, Checkin>(url),
  )

  const savePulse = async (values: Partial<Checkin>) => {
    await Fetcher.put<{ checkin: Partial<Checkin> }, Checkin>(
      `/api/v1/checkins/${id}`,
      {
        checkin: values,
      },
    )
    navigate('/dashboard/admin/checkins')
  }

  return (
    <CheckinForm
      checkin={checkin}
      isLoading={checkinLoading}
      nodes={nodes}
      onSave={savePulse}
    />
  )
}

export default EditCheckinsPage
