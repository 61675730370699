import { Grid, Paper, Title } from '@mantine/core'
import cx from 'clsx'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import classes from '~/components/dashboard/kanban.module.css'
import TodoCard from '~/components/dashboard/todo_card'
import { Todo } from '~/types/todo'
import { TodoStatus } from '~/types/todo_status'

function KanbanColumn({
  title,
  todos,
  tra,
}: {
  title: string
  todos: Todo[]
  tra: TFunction
}) {
  return (
    <Paper data-testid={`kanban-${title}`} p="sm" withBorder>
      <Title order={5}>{tra(`todoStatuses:${title}`)}</Title>
      {todos.map((todo) => (
        <TodoCard key={todo.id} todo={todo} />
      ))}
    </Paper>
  )
}

function Kanban({
  direction = 'horizontal',
  todos,
}: {
  direction?: 'horizontal' | 'vertical'
  todos: Todo[]
}) {
  const { t: tra } = useTranslation()
  const isHorizontal = direction === 'horizontal'
  const span = isHorizontal ? 4 : 12
  const todoMap: Record<TodoStatus, Todo[]> = {
    [TodoStatus.OPEN]: [],
    [TodoStatus.IN_PROGRESS]: [],
    [TodoStatus.COMPLETED]: [],
    [TodoStatus.NOT_PLANNED]: [],
  }

  todos.forEach((todo) => {
    todoMap[todo.status as TodoStatus].push(todo)
  })

  return (
    <div className={classes.kanbanContainer}>
      <Grid
        classNames={{
          inner: classes.kanbanInner,
        }}
        h={isHorizontal ? '100%' : 'auto'}
        mt="sm"
        px="sm"
      >
        {Object.entries(todoMap).map(([status, filteredTodos]) => {
          if (status === TodoStatus.NOT_PLANNED) return null
          return (
            <Grid.Col
              className={cx([classes.kanbanColumn, isHorizontal])}
              key={status}
              span={span}
            >
              <KanbanColumn title={status} todos={filteredTodos} tra={tra} />
            </Grid.Col>
          )
        })}
      </Grid>
    </div>
  )
}

export default Kanban
