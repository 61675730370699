import {
  ActionIcon,
  Badge,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import {
  IconArrowRight,
  IconMessage,
  IconPlus,
  IconTextCaption,
} from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import classes from '~/components/dashboard/agenda_item_card.module.css'
import AgendaItemDetail from '~/components/dashboard/agenda_item_detail'
import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import TodoDetail from '~/components/dashboard/todo_detail'
import { AgendaItemIndexView } from '~/types/agenda_item'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

async function addToAgenda(
  agendaItemId: string,
  meetingId: string,
  cb?: () => void,
) {
  try {
    await Fetcher.put(`/api/v1/agenda_items/${agendaItemId}`, {
      meeting_id: meetingId,
    })

    if (cb) cb()
  } catch (e) {
    showErrorNotification(e)
  }
}

// TODO: Think more about the color and design
function PriorityBadge({
  priority,
  tra,
}: {
  priority: string
  tra: TFunction
}) {
  switch (priority) {
    case 'high':
      return (
        <Badge color="red" variant="light">
          {tra('common:priority:high')}
        </Badge>
      )
    case 'medium':
      return (
        <Badge color="blue" variant="light">
          {tra('common:priority:medium')}
        </Badge>
      )
    case 'low':
      return (
        <Badge color="green" variant="light">
          {tra('common:priority:low')}
        </Badge>
      )
    default:
      return <Badge color="green">{tra('common:priority:low')}</Badge>
  }
}

type AgendaItemCardProps = {
  addToAgendaCb?: () => void
  agendaItem: AgendaItemIndexView
  currentMeetingId?: string
  displayAddressee?: boolean
  displayCreator?: boolean
}

function AgendaItemCard(props: AgendaItemCardProps) {
  const {
    addToAgendaCb,
    agendaItem,
    currentMeetingId,
    displayAddressee = false,
    displayCreator = false,
  } = props

  const { t: tra } = useTranslation()
  const [isModalOpen, { close: closeDetailModal, open: openDetailModal }] =
    useDisclosure(false)

  return (
    <>
      <Paper
        className={classes.agendaItem}
        data-testid="agenda-item-card"
        mt="sm"
        p="sm"
        withBorder
        onClick={openDetailModal}
      >
        <Stack gap="xs">
          <Group justify="space-between">
            <Group align="center" gap="0">
              <IconMessage color="gray" size="1rem" />
              <Text ml="0.25rem" truncate="end">
                {agendaItem.title}
              </Text>
            </Group>
            {currentMeetingId && (
              <Tooltip label="Add to meeting agenda">
                <ActionIcon
                  data-testid="agenda-item-card-add-to-meeting-button"
                  onClick={() => {
                    addToAgenda(agendaItem.id, currentMeetingId, addToAgendaCb)
                  }}
                >
                  <IconPlus />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
          <Group align="flex-end" gap="0" justify="space-between">
            <Group gap="xs">
              {agendaItem.description && (
                <Tooltip label={tra('agendaItemComponent:hasDescription')}>
                  <IconTextCaption
                    color="gray"
                    data-testid="agenda-item-card-description-icon"
                    size="1rem"
                  />
                </Tooltip>
              )}
              <PriorityBadge priority={agendaItem.priority} tra={tra} />
            </Group>
            <Group gap="xs">
              {displayCreator && (
                <LabeledAvatar
                  data-testid="agenda-item-card-creator-avatar"
                  size="sm"
                  user={agendaItem.creator}
                />
              )}
              {displayAddressee && displayCreator && (
                <IconArrowRight data-testid="agenda-item-card-addressing-arrow" />
              )}
              {displayAddressee && (
                <LabeledAvatar
                  data-testid="agenda-item-card-addressee-avatar"
                  size="sm"
                  user={agendaItem.addressee}
                />
              )}
            </Group>
          </Group>
        </Stack>
      </Paper>
      {!currentMeetingId && isModalOpen && (
        <AgendaItemDetail
          agendaItem={agendaItem}
          isModalOpen={isModalOpen}
          onModalClosed={closeDetailModal}
        />
      )}
    </>
  )
}

export default AgendaItemCard
