import {
  Button,
  LoadingOverlay,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaLock } from 'react-icons/fa'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import PageHeader from '~/components/shared/page_header'
import classes from '~/pages/pre-auth/reset-password.module.css'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { getCurrentLanguageCode } from '~/utils/i18n'
import { passwordSchema } from '~/utils/schemas'

const resetPasswordFormSchema = z
  .object({
    confirm: passwordSchema,
    password: passwordSchema,
  })
  .refine((data) => data.password === data.confirm, {
    params: { i18n: 'passwordsDontMatch' },
    path: ['confirm'],
  })
type ResetPasswordFormSchemaType = z.infer<typeof resetPasswordFormSchema>
type ResetPasswordRequestType = ResetPasswordFormSchemaType & { token: string }

function ResetPasswordPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { t: tra } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const form = useForm({
    initialValues: {
      confirm: '',
      password: '',
    },
    // Our validateForm method doesn't work since we refine the schema
    validate: zodResolver(resetPasswordFormSchema),
  })

  const onSubmit = async (formData: ResetPasswordFormSchemaType) => {
    await Fetcher.post<ResetPasswordRequestType, {}>(
      '/api/v1/passwords/reset',
      { token: token as string, ...formData },
    )

    const currentLanguageCode = getCurrentLanguageCode()
    navigate(`/login?lang=${currentLanguageCode}&reset_password=true`)
  }

  useEffect(() => {
    async function validateToken(resetToken: string) {
      try {
        await Fetcher.get<{ token: string }, {}>(
          '/api/v1/passwords/reset_requests',
          { token: resetToken },
        )

        setIsLoading(false)
      } catch (e) {
        showErrorNotification(e)
      }
    }

    if (token) {
      validateToken(token)
    } else {
      navigate('/login')
    }
  }, [token])

  if (!isLoading) {
    return (
      <>
        <PageHeader title={tra('resetPassword:setNewPassword')} />
        <Title order={3}>{tra('resetPassword:setNewPassword')}</Title>
        <Text mb="sm">{tra('resetPassword:description')}</Text>
        <form
          className={classes.resetPasswordForm}
          onSubmit={form.onSubmit((values) => onSubmit(values))}
        >
          <PasswordInput
            data-test="resetPassword:passwordInput"
            label={tra('resetPassword:newPassword')}
            leftSection={<FaLock />}
            placeholder={tra('common:passwordPlaceholder')}
            size="md"
            withAsterisk
            {...form.getInputProps('password')}
          />
          <PasswordInput
            data-test="resetPassword:passwordInput"
            label={tra('resetPassword:confirmPassword')}
            leftSection={<FaLock />}
            placeholder={tra('common:passwordPlaceholder')}
            size="md"
            withAsterisk
            {...form.getInputProps('confirm')}
          />
          <Button
            data-test="resetPassword:submitButton"
            size="md"
            type="submit"
          >
            {tra('resetPassword:actionText')}
          </Button>
        </form>
      </>
    )
  }

  return <LoadingOverlay visible={isLoading} />
}

export default ResetPasswordPage
