import { Button, Container, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { FaArrowRight, FaBuilding, FaIdCard } from 'react-icons/fa'
import { z } from 'zod'

import classes from '~/pages/onboarding/base.module.css'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { homePath, useSession } from '~/utils/session'
import validateForm from '~/utils/validate-form'

const completeProfileFormSchema = z.object({
  firstName: z.string().min(1, { message: 'companyNameInvalid ' }),
  lastName: z.string().min(1, { message: 'companyNameInvalid ' }),
})
type CompleteProfileFormSchema = z.infer<typeof completeProfileFormSchema>

function CompleteProfilePage() {
  const { t: tra } = useTranslation()
  const { user: currentUser } = useSession()

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '1-10',
    },
    validate: (values) => {
      return validateForm(completeProfileFormSchema, values)
    },
  })

  const onSubmit = async (formData: CompleteProfileFormSchema) => {
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
    }

    try {
      const response = await Fetcher.put<
        { first_name: string; last_name: string },
        {}
      >(`/api/v1/users/${currentUser.id}`, payload)

      window.location.assign(homePath)
    } catch (e) {
      console.log('exception', e)
      showErrorNotification(e)
    }
  }

  const completeProfileForm = (
    <form
      className={classes.onboardingForm}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <TextInput
        label="First name"
        leftSection={<FaIdCard />}
        placeholder="First name"
        radius="xl"
        size="lg"
        withAsterisk
        {...form.getInputProps('firstName')}
      />
      <TextInput
        label="Last name"
        leftSection={<FaBuilding />}
        placeholder="Last name"
        radius="xl"
        size="lg"
        withAsterisk
        {...form.getInputProps('lastName')}
      />

      <Button
        radius="xl"
        rightSection={<FaArrowRight />}
        size="lg"
        type="submit"
      >
        Start using voyn.io!
      </Button>
    </form>
  )

  return (
    <Container className={classes.onboardingFormContainer}>
      <Title>Complete your profile kanka</Title>
      <Text size="lg">We need some details about you</Text>
      {completeProfileForm}
    </Container>
  )
}

export default CompleteProfilePage
