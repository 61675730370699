import i18next from 'i18next'

import VoynError from './error'
import { getCurrentLanguage } from './i18n'

export default class Fetcher {
  private static defaultHeaders: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  static async get<TReq, TRes>(
    url: string,
    data?: Record<string, any> & TReq,
    headers?: HeadersInit,
  ): Promise<TRes> {
    const searchParams = new URLSearchParams(data)
    const queryString: string = searchParams.toString()

    const finalUrl = queryString.length > 0 ? `${url}?${queryString}` : url

    return this.request<TRes>(finalUrl, { method: 'GET' }, headers)
  }

  static async post<TReq, TRes>(
    url: string,
    data?: TReq,
    headers?: HeadersInit,
  ): Promise<TRes> {
    const options: RequestInit = {
      body: data ? JSON.stringify(data) : undefined,
      method: 'POST',
    }

    return this.request<TRes>(url, options, headers)
  }

  static async put<TReq, TRes>(
    url: string,
    data?: TReq,
    headers?: HeadersInit,
  ): Promise<TRes> {
    const options: RequestInit = {
      body: data ? JSON.stringify(data) : undefined,
      method: 'PUT',
    }
    return this.request<TRes>(url, options, headers)
  }

  static async delete<TRes>(url: string, headers?: HeadersInit): Promise<TRes> {
    const options: RequestInit = {
      method: 'DELETE',
    }
    return this.request<TRes>(url, options, headers)
  }

  private static async request<TRes>(
    url: string,
    options: RequestInit,
    headers?: HeadersInit,
  ): Promise<TRes | any> {
    const controller = new AbortController()

    // Get the CSRF token from the meta element
    const csrfMeta = document.querySelector('[name=csrf-token]')
    const csrfToken = csrfMeta ? csrfMeta.getAttribute('content') : null

    // Pass the current language so that API can translate the error messages without hassle
    const langCode = getCurrentLanguage().code

    const mergedOptions = {
      credentials: 'include' as RequestCredentials,
      headers: new Headers({
        ...this.defaultHeaders,
        ...headers,
        ...options.headers,
        'X-CSRF-TOKEN': csrfToken as string,
        'X-VOYN-LANGUAGE': langCode,
      }),
      signal: controller.signal,
      ...options,
    }

    const response = await fetch(url, mergedOptions)

    if (!response.ok) {
      const errorResponse = await response.json()

      if (errorResponse.errors) {
        throw new VoynError(errorResponse.errors.join(','))
      }

      const errorMessage = i18next.t('error:serverError') as string
      throw new VoynError(errorMessage)
    }

    return response.json()
  }

  // TODO: FIX THIS KOYLULUK
  static async uploadFile<TRes>(
    url: string,
    options: RequestInit,
  ): Promise<TRes | any> {
    const controller = new AbortController()

    // Get the CSRF token from the meta element
    const csrfMeta = document.querySelector('[name=csrf-token]')
    const csrfToken = csrfMeta ? csrfMeta.getAttribute('content') : null

    // Pass the current language so that API can translate the error messages without hassle
    const langCode = getCurrentLanguage().code

    const mergedOptions = {
      credentials: 'include' as RequestCredentials,
      headers: new Headers({
        Accept: 'application/json',
        'X-CSRF-TOKEN': csrfToken as string,
        'X-VOYN-LANGUAGE': langCode,
      }),
      method: 'POST',
      signal: controller.signal,
      ...options,
    }

    const response = await fetch(url, mergedOptions)

    if (!response.ok) {
      const errorResponse = await response.json()

      if (errorResponse.errors) {
        throw new VoynError(errorResponse.errors.join(','))
      }

      const errorMessage = i18next.t('error:serverError') as string
      throw new VoynError(errorMessage)
    }

    return response.json()
  }
}
