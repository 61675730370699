import { Loader, Paper, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useSWRImmutable from 'swr/immutable'

import AgendaItemCard from '~/components/dashboard/agenda_item_card'
import { AgendaItemIndexView } from '~/types/agenda_item'
import {
  AgendaItemFilter,
  AgendaItemFilterPayload,
  filterToPayload,
} from '~/utils/agenda_item_filter'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function TalkingPointsCreatedByYouCard() {
  const { user: currentUser } = useSession()

  const aiFilters: AgendaItemFilter = {
    completed: false,
    creatorId: currentUser.id,
    meetingId: null,
  }

  const { t: tra } = useTranslation()

  const {
    data: agendaItems,
    error,
    isLoading,
  } = useSWRImmutable(
    { ...aiFilters, url: '/api/v1/agenda_items' },
    ({ url, ...rest }) =>
      Fetcher.get<AgendaItemFilterPayload, AgendaItemIndexView[]>(
        url,
        filterToPayload(rest),
      ),
  )

  if (error) {
    return <h1 data-testid="talking-points-created-by-you-error">ERROR</h1> // TODO: Change this
  }

  if (isLoading) {
    return <Loader data-testid="talking-points-created-by-you-loader" />
  }

  if (agendaItems) {
    return (
      <Paper data-testid="talking-points-created-by-you" p="md" withBorder>
        <Title order={4}>{tra('home:talkingPointsCreatedByYouHeader')}</Title>
        {agendaItems.map((ai) => (
          <AgendaItemCard agendaItem={ai} displayAddressee key={ai.id} />
        ))}
      </Paper>
    )
  }

  return null
}

export default TalkingPointsCreatedByYouCard
