import { Button, Group, Table, Text, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useReports } from '~/hooks/api'
import { UserIndexView } from '~/types/user'
import { useSession } from '~/utils/session'

export default function CompensationReviewPage() {
  const { t: tra } = useTranslation()
  const { user: currentUser } = useSession()
  const { isLoading, reports } = useReports<UserIndexView>(currentUser.id)

  const tableData = reports?.map((report: UserIndexView) => (
    <Table.Tr key={report.id}>
      <Table.Td>
        <Text>{report.full_name}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{report.title}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{report.job_level?.name}</Text>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <>
      <Group gap="xl" justify="space-between">
        <Title>Compensation Review Page</Title>
        <Group>
          <Button variant="outline">
            {tra('compensation:exception_review')}
          </Button>
          <Button variant="outline">{tra('compensation:promotions')}</Button>
          <Button variant="outline">
            {tra('compensation:periodic_review')}
          </Button>
        </Group>
      </Group>
      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Employee</Table.Th>
              <Table.Th>Title</Table.Th>
              <Table.Th>Level</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{tableData}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  )
}
