import { Tabs, Tooltip } from '@mantine/core'
import {
  IconCalendarEvent,
  IconGitPullRequest,
  IconHeartbeat,
  IconInfoCircle,
  IconListCheck,
  IconMessage,
} from '@tabler/icons-react'
import { ReactNode } from 'react'

import { UserProfileView } from '~/types/user'

type TabItem = {
  label: string
  leftSection: ReactNode
  onlyForManager?: boolean
  value: string
}

const tabs: TabItem[] = [
  {
    label: 'Profile',
    leftSection: <IconInfoCircle />,
    value: 'profile',
  },
  {
    label: 'Meetings',
    leftSection: <IconCalendarEvent />,
    value: 'meetings',
  },
  {
    label: 'Todos',
    leftSection: <IconListCheck />,
    value: 'todos',
  },
  {
    label: 'Talking points',
    leftSection: <IconMessage />,
    value: 'talking_points',
  },
  {
    label: 'Pulses',
    leftSection: <IconHeartbeat />,
    onlyForManager: true,
    value: 'pulses',
  },
  {
    label: 'Github',
    leftSection: <IconGitPullRequest />,
    onlyForManager: true,
    value: 'github',
  },
]

type ProfileTabsProps = {
  defaultValue?: string
  displayTabHeader: boolean
  userProfile: UserProfileView
}

function reorderTabs(defaultValue: string): TabItem[] {
  const defaultTab = tabs.find((tab) => tab.value === defaultValue)
  const remainingTabs = tabs.filter((tab) => tab.value !== defaultValue)

  if (defaultTab === undefined) {
    return tabs
  }

  return [defaultTab, ...remainingTabs]
}

function ProfileTabs({
  defaultValue = 'profile',
  displayTabHeader,
  userProfile,
}: ProfileTabsProps) {
  const orderedTabs = reorderTabs(defaultValue)

  return (
    <Tabs.List>
      {orderedTabs.map((tab) => {
        if (tab.onlyForManager && !userProfile.reports_to_current_user) {
          return null
        }

        return (
          <Tabs.Tab
            key={tab.value}
            leftSection={
              displayTabHeader ? (
                tab.leftSection
              ) : (
                <Tooltip label={tab.label}>{tab.leftSection}</Tooltip>
              )
            }
            value={tab.value}
          >
            {displayTabHeader ? tab.label : null}
          </Tabs.Tab>
        )
      })}
    </Tabs.List>
  )
}

export default ProfileTabs
