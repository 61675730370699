import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type PageHeaderProps = {
  title: string
}
function PageHeader({ title }: PageHeaderProps) {
  const { t: tra } = useTranslation()

  return (
    <Helmet>
      <title>{`voyn.io | ${tra(title)}`}</title>
    </Helmet>
  )
}

export default PageHeader
