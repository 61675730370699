import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import VoynLoadingOverlay from '~/components/shared/loading'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

function VerifyEmailPage() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)
  const navigate = useNavigate()

  if (!token) {
    return <h1>Error, invalid token</h1>
  }

  useEffect(() => {
    async function verifyEmail(verificationToken: string) {
      try {
        await Fetcher.post<{ token: string }, {}>(
          '/api/v1/users/verify_email',
          {
            token: verificationToken,
          },
        )

        setIsLoading(false)
        setIsEmailVerified(true)
        setTimeout(() => {
          navigate('/login')
        }, 5000)
      } catch (e) {
        showErrorNotification(e)
      }
    }

    verifyEmail(token)
  }, [])

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (isEmailVerified) {
    return (
      <h1>
        Your email is verified, youll be redirected to login page in 5 seconds
      </h1>
    )
  }

  return null
}

export default VerifyEmailPage
