import { useParams } from 'react-router-dom'
import { KeyedMutator } from 'swr'
import useSWRImmutable from 'swr/immutable'

import VoynLoadingOverlay from '~/components/shared/loading'
import FinishedMeetingPage from '~/pages/dashboard/finished_meeting'
import OngoingMeeting from '~/pages/dashboard/ongoing_meeting'
import ScheduledMeetingPage from '~/pages/dashboard/scheduled_meeting'
import { MeetingDetailView } from '~/types/meeting'
import { MeetingStatus } from '~/types/meeting_status'
import Fetcher from '~/utils/fetcher'

export function renderMeetingComponent(
  meetingDetail: MeetingDetailView,
  mutateMeetingDetail: KeyedMutator<MeetingDetailView>,
) {
  switch (meetingDetail.status) {
    case MeetingStatus.SCHEDULED:
      return (
        <ScheduledMeetingPage
          meetingDetail={meetingDetail}
          mutateMeetingDetail={mutateMeetingDetail}
        />
      )
    case MeetingStatus.STARTED:
      return <OngoingMeeting meetingDetail={meetingDetail} />
    case MeetingStatus.FINISHED:
      return (
        <FinishedMeetingPage
          meetingDetail={meetingDetail}
          mutateMeetingDetail={mutateMeetingDetail}
        />
      )
    default:
      return null
  }
}

function MeetingLayout() {
  const { id: meetingId } = useParams()

  const {
    data: meetingDetail,
    error,
    isLoading,
    mutate: mutateMeetingDetail,
  } = useSWRImmutable(`/api/v1/meetings/${meetingId}`, (url) =>
    Fetcher.get<{}, MeetingDetailView>(url),
  )

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (error) {
    return <h1>ERROR</h1>
  }

  if (meetingDetail) {
    return renderMeetingComponent(meetingDetail, mutateMeetingDetail)
  }

  return null
}

export default MeetingLayout
