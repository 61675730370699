import { Button, Group, LoadingOverlay, Table, Title } from '@mantine/core'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import adminClasses from '~/layouts/admin.module.css'
import { Pulse } from '~/types/pulse'
import Fetcher from '~/utils/fetcher'

function PulsesTable({ pulses, tra }: { pulses: Pulse[]; tra: TFunction }) {
  const navigate = useNavigate()
  const deletePulse = async (pulseId: string | undefined) => {
    if (pulseId === undefined) {
      return
    }
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(tra('pulse:are_you_sure_delete'))) {
      await Fetcher.delete(`/api/v1/pulses/${pulseId}`)
    }
  }

  return (
    <Table
      highlightOnHover
      striped
      verticalSpacing="xs"
      withColumnBorders
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{tra('common:title').toLocaleUpperCase()}</Table.Th>
          <Table.Th>{tra('pulse:occurrence').toLocaleUpperCase()}</Table.Th>
          <Table.Th>{tra('common:actions').toLocaleUpperCase()}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {pulses?.map((pulse) => (
          <Table.Tr key={pulse.id}>
            <Table.Td>{pulse.title}</Table.Td>
            <Table.Td>
              {tra('pulse:occurrence_description', {
                day: tra(`calendar:${pulse.occurrence.day}`),
                frequency: tra(`pulse:${pulse.occurrence.frequency}`),
                time: pulse.occurrence.time,
              })}
            </Table.Td>
            <Table.Td>
              <Button onClick={() => navigate(`${pulse.id}/edit`)}>Edit</Button>

              <Button onClick={() => deletePulse(pulse.id)}>Delete</Button>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

function PulsesPage() {
  const { t: tra } = useTranslation()
  const { data, isLoading } = useSWR([`/api/v1/pulses`], ([url]) =>
    Fetcher.get<{}, Pulse[]>(url),
  )
  const navigate = useNavigate()

  if (isLoading) return <LoadingOverlay visible />
  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('common:pulses')}</Title>
        <Button onClick={() => navigate('new')}>{tra('pulse:create')}</Button>
      </Group>
      {data && <PulsesTable pulses={data} tra={tra} />}
    </>
  )
}

export default PulsesPage
