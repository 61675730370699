import { Avatar, Card, Group, Menu, Stack, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import {
  IconBallpen,
  IconBuilding,
  IconCalendarPlus,
  IconDotsVertical,
  IconMail,
  IconMessagePlus,
} from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import classes from '~/components/dashboard/report_card.module.css'
import MeetingForm from '~/pages/dashboard/my_team/meeting_form'
import { UserReportView } from '~/types/user'
import { useSession } from '~/utils/session'

import AgendaItemForm from './agenda_item_form'
import RequestFeedbackForm from './feedback/feedback_request_form'

type ColleagueCardProps = {
  user: UserReportView
}

export default function ColleagueCard({ user }: ColleagueCardProps) {
  const { user: currentUser } = useSession()
  const { t: tra } = useTranslation()

  const openSkipLevelRequestModal = () => {
    modals.open({
      children: <MeetingForm user={user} />,
      modalId: 'request_meeting_modal',
      title: tra('users:send_meeting_request'),
    })
  }

  const openFeedbackRequestModal = () => {
    modals.open({
      children: <RequestFeedbackForm currentUser={currentUser} user={user} />,
      modalId: 'request_feedback_modal',
      title: tra('feedback_requests:request_feedback'),
    })
  }

  const openAgendaItemModal = () => {
    modals.open({
      children: (
        <AgendaItemForm
          addresseeId={user.id}
          onSuccess={() => {
            modals.closeAll()
          }}
        />
      ),
      title: 'Add new talking point',
    })
  }

  return (
    <Card
      className={classes.reportCard}
      data-testid="colleague-card"
      h="100%"
      p="sm"
      radius="sm"
      withBorder
    >
      <Stack align="flex-start" gap="0">
        <Group align="flex-start" justify="space-between" w="100%">
          <Avatar
            data-testid="colleague-avatar"
            radius="50%"
            size="lg"
            src={user.profile_picture_url}
          />
          <Menu
            data-testid="colleague-menu"
            position="bottom-end"
            shadow="md"
            trigger="hover"
            withArrow
          >
            <Menu.Target>
              <IconDotsVertical />
            </Menu.Target>
            <Menu.Dropdown data-testid="colleague-menu-dropdown">
              <Menu.Item
                data-testid="talking-point-action"
                leftSection={<IconMessagePlus />}
                onClick={() => openAgendaItemModal()}
              >
                {tra('users:create_talking_point')}
              </Menu.Item>
              <Menu.Item
                data-testid="skip-level-action"
                leftSection={<IconCalendarPlus />}
                onClick={openSkipLevelRequestModal}
              >
                {tra('users:send_meeting_request')}
              </Menu.Item>
              <Menu.Item
                data-testid="request-feedback-action"
                leftSection={<IconBallpen />}
                onClick={openFeedbackRequestModal}
              >
                {tra('feedback_requests:request_feedback')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Text fw={500} mt="sm">
          {user.full_name || 'Unnamed Hero'}
        </Text>
        <Text c="gray" fz="sm">
          {user.title}
        </Text>
      </Stack>
      <Stack gap="0" mt="sm">
        <Group align="center" gap="0">
          <IconMail color="gray" size="0.8rem" />
          <Text c="gray" fz="sm" ml="0.3rem">
            {user.email}
          </Text>
        </Group>
        <Group align="center" gap="0">
          <IconBuilding color="gray" size="0.8rem" />
          <Text c="gray" fz="sm" ml="0.3rem">
            {user.nodes
              .slice(0, -1)
              .map((node) => node.name)
              .join(', ') +
              (user.nodes.length > 1 ? ' & ' : '') +
              (user.nodes.length ? user.nodes[user.nodes.length - 1].name : '')}
          </Text>
        </Group>
      </Stack>
    </Card>
  )
}
