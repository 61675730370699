import { Loader } from '@mantine/core'
import useSWR from 'swr'

import Kanban from '~/components/dashboard/kanban'
import { Todo } from '~/types/todo'
import Fetcher from '~/utils/fetcher'

function TodosTab({
  direction = 'horizontal',
  userId,
}: {
  direction?: 'horizontal' | 'vertical'
  userId: string
}) {
  const {
    data: todos,
    error,
    isLoading,
  } = useSWR(`/api/v1/todos`, (url) =>
    Fetcher.get<{ assignee_id: string }, Todo[]>(url, {
      assignee_id: userId,
    }),
  )

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <h1>Error</h1>
  }

  if (todos) {
    return <Kanban direction={direction} todos={todos} />
  }

  return null
}

export default TodosTab
