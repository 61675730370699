import { TFunction } from 'i18next'

import { UserIndexView } from '~/types/user'
import { VNode } from '~/types/vnode'
import { Role } from '~/utils/constants'

export function mapRolesToSelectOptions(tra: TFunction) {
  const roles = Object.values(Role)
  const defaultOption = [
    { disabled: true, label: tra('admin:users:selectRole'), value: '' },
  ]

  return defaultOption.concat(
    roles.map((value) => {
      return { disabled: false, label: tra(`common:roles:${value}`), value }
    }),
  )
}

export function mapUsersToSelectOptions(
  users: UserIndexView[],
  tra: TFunction,
) {
  const defaultOption = [
    {
      disabled: true,
      label: tra('admin:users:selectManager'),
      value: '',
    },
  ]

  return defaultOption.concat(
    users.map((user) => ({
      disabled: false,
      label: `${user.first_name} ${user.last_name} (${user.email})`,
      value: user.id,
    })),
  )
}

// TODO: Implement tree view
export function mapNodesToSelectOptions(nodes: VNode[], tra: TFunction) {
  const defaultOption = [
    {
      disabled: true,
      label: tra('admin:users:selectOrganizationalUnit'),
      value: '',
    },
  ]

  return defaultOption.concat(
    nodes.map((node: VNode) => ({
      disabled: false,
      label: node.name,
      value: node.id,
    })),
  )
}
