import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import AdminLayout from '~/layouts/admin'
import DashboardLayout from '~/layouts/dashboard'
import MeetingLayout from '~/layouts/meeting'
import OnboardingLayout from '~/layouts/onboarding'
import PreAuthLayout from '~/layouts/pre-auth'
import PreAuthFormLayout from '~/layouts/pre-auth-form'
import AdminCheckinsPage from '~/pages/dashboard/admin/checkins'
import CreateCheckinsPage from '~/pages/dashboard/admin/checkins/create'
import EditCheckinsPage from '~/pages/dashboard/admin/checkins/edit'
import CompensationsPage from '~/pages/dashboard/admin/jobs/compensations_page'
import JobFunctionsPage from '~/pages/dashboard/admin/jobs/job_functions_page'
import JobLevelsPage from '~/pages/dashboard/admin/jobs/job_levels_page'
import CalendarPage from '~/pages/dashboard/calendar'
import CheckinsPage from '~/pages/dashboard/checkins'
import FeedbackPage from '~/pages/dashboard/feedback'
import FeedbackDraftsPage from '~/pages/dashboard/feedback/feedback_drafts'
import FeedbackGivenPage from '~/pages/dashboard/feedback/feedback_given'
import FeedbackReceivedPage from '~/pages/dashboard/feedback/feedback_received'
import FeedbackRequests from '~/pages/dashboard/feedback/feedback_requests'
import HomePage from '~/pages/dashboard/home'
import IntegrationsPage from '~/pages/dashboard/integrations'
import MyTeamPage from '~/pages/dashboard/my_team'
import AllReportsPage from '~/pages/dashboard/my_team/all_reports'
import DirectReportsPage from '~/pages/dashboard/my_team/direct_reports'
import ManagersPage from '~/pages/dashboard/my_team/managers'
import MyDepartmentPage from '~/pages/dashboard/my_team/my_department'
import MyPeersPage from '~/pages/dashboard/my_team/my_peers'
import NodesPage from '~/pages/dashboard/nodes'
import OrgChartPage from '~/pages/dashboard/org_chart'
import ProfilePage from '~/pages/dashboard/profile'
import PulsesPage from '~/pages/dashboard/pulses'
import CreatePulsesPage from '~/pages/dashboard/pulses/create'
import EditPulsePage from '~/pages/dashboard/pulses/edit'
import MemberProfilePage from '~/pages/dashboard/team/member_profile'
import TodosPage from '~/pages/dashboard/todos'
import UsersPage from '~/pages/dashboard/users'
import CompleteProfilePage from '~/pages/onboarding/complete_profile'
import CreateTenantPage from '~/pages/onboarding/create_tenant'
import AcceptInvitationPage from '~/pages/pre-auth/accept-invitation'
import ForgotPasswordPage from '~/pages/pre-auth/forgot-password'
import LoginPage from '~/pages/pre-auth/login'
import PulsePage from '~/pages/pre-auth/pulse'
import ResetPasswordPage from '~/pages/pre-auth/reset-password'
import VerifyEmailPage from '~/pages/pre-auth/verify_email'
import GetStartedPage from '~/pages/sign-up/get_started'

import CompensationReviewPage from './pages/dashboard/compensation_review'

const router = createBrowserRouter([
  // Pulse doesn't require authentication or authorization since we encrypt everything needed, including user ID, in a
  // URL search param named hash.
  { element: <PulsePage />, path: 'pulse' },
  { element: <VerifyEmailPage />, path: 'verify_email' },
  {
    children: [
      { element: <GetStartedPage />, path: 'get_started' },
      {
        element: <LoginPage />,
        path: 'login',
      },
      {
        children: [
          { element: <ForgotPasswordPage />, path: 'forgot_password' },
          { element: <ResetPasswordPage />, path: 'reset_password' },
          {
            element: <AcceptInvitationPage />,
            path: 'invitations/:invitationId/accept',
          },
        ],
        element: <PreAuthFormLayout />,
        path: '',
      },
    ],
    element: <PreAuthLayout />,
    path: '',
  },
  {
    children: [
      { element: <HomePage />, path: 'home' },
      { element: <TodosPage />, path: 'todos' },
      { element: <MemberProfilePage />, path: 'members/:id' },
      {
        element: <MeetingLayout />,
        path: 'meetings/:id',
      },
      {
        children: [
          { element: <Navigate replace to="department" />, index: true },
          { element: <MyDepartmentPage />, path: 'department' },
          { element: <MyPeersPage />, path: 'peers' },
          { element: <DirectReportsPage />, path: 'direct_reports' },
          { element: <AllReportsPage />, path: 'all_reports' },
          { element: <ManagersPage />, path: 'managers' },
          { element: <OrgChartPage />, path: 'org_chart' },
        ],
        element: <MyTeamPage />,
        path: 'team',
      },
      {
        element: <CalendarPage />,
        path: 'calendar',
      },
      {
        children: [{ element: <CheckinsPage />, path: '' }],
        path: 'checkins',
      },
      {
        children: [
          { element: <Navigate replace to="received" />, index: true },
          { element: <FeedbackDraftsPage />, path: 'drafts' },
          { element: <FeedbackGivenPage />, path: 'given' },
          { element: <FeedbackReceivedPage />, path: 'received' },
          { element: <FeedbackRequests />, path: 'requests' },
        ],
        element: <FeedbackPage />,
        path: 'feedback',
      },
      { element: <ProfilePage />, path: 'profile' },
      { element: <CompensationReviewPage />, path: 'compensation_review' },
      {
        children: [
          { element: <Navigate replace to="users" />, index: true },
          { element: <NodesPage />, path: 'nodes' },
          { element: <IntegrationsPage />, path: 'integrations' },
          { element: <PulsesPage />, path: 'pulses' },
          { element: <CreatePulsesPage />, path: 'pulses/new' },
          { element: <EditPulsePage />, path: 'pulses/:id/edit' },
          { element: <AdminCheckinsPage />, path: 'checkins' },
          { element: <CreateCheckinsPage />, path: 'checkins/new' },
          { element: <EditCheckinsPage />, path: 'checkins/:id/edit' },
          { element: <UsersPage />, path: 'users' },
          { element: <JobFunctionsPage />, path: 'job_functions' },
          { element: <JobLevelsPage />, path: 'job_levels' },
          { element: <CompensationsPage />, path: 'compensations' },
        ],
        element: <AdminLayout />,
        path: 'admin',
      },
    ],
    element: <DashboardLayout />,
    path: 'dashboard',
  },
  {
    children: [
      { element: <CreateTenantPage />, path: 'create_tenant' },
      { element: <CompleteProfilePage />, path: 'complete_profile' },
    ],
    element: <OnboardingLayout />,
    path: 'onboarding',
  },
])

function VoynRoutes() {
  return <RouterProvider router={router} />
}

export default VoynRoutes
