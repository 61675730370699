import { Box, Group, Paper, Stack, Switch, Text, Title } from '@mantine/core'
import {
  IconBrandGithub,
  IconBrandGoogle,
  IconBrandWindows,
} from '@tabler/icons-react'
import React from 'react'

import { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'

const signinWith =
  (
    provider: string,
    setFunction: React.Dispatch<React.SetStateAction<boolean>>,
  ) =>
  async () => {
    console.log(`Signing in with... ${provider}`)
    const response: { redirect_uri: string } = await Fetcher.get(
      `/oauth/${provider}/authorize`,
    )
    const popWidth = 600
    const popHeight = 800
    const leftPos = window.screen.width / 2 - popWidth / 2
    const topPos = window.screen.height / 2 - popHeight / 2

    window.onmessage = (event) => {
      if (event.data.succeeded === true) {
        setFunction(true)
      }
    }

    window.open(
      response.redirect_uri,
      provider,
      `width=${popWidth},height=${popHeight},left=${leftPos},top=${topPos}`,
    )
  }

interface IntegrationProps {
  icon: React.ReactNode
  isConnected: boolean
  name: string
  onToggle: () => void
}

function Integration({ icon, isConnected, name, onToggle }: IntegrationProps) {
  return (
    <Paper p="md" withBorder>
      <Group justify="space-between">
        <Group>
          {icon}
          <Text size="lg" w={300}>
            {name}
          </Text>
        </Group>
        <Switch
          checked={isConnected}
          label={isConnected ? 'Connected' : 'Disconnected'}
          size="lg"
          onChange={onToggle}
        />
      </Group>
    </Paper>
  )
}

export default function Integrations({ user }: { user: User }) {
  const [githubConnected, setGithubConnected] = React.useState(false)
  const [googleConnected, setGoogleConnected] = React.useState(false)
  const [outlookConnected, setOutlookConnected] = React.useState(false)

  return (
    <Box maw={600} mx="auto">
      <Title mb="md" order={2}>
        Integrations
      </Title>
      <Stack gap="md">
        <Integration
          icon={<IconBrandGithub size={24} />}
          isConnected={githubConnected}
          name="GitHub"
          onToggle={signinWith('github', setGithubConnected)}
        />
        <Integration
          icon={<IconBrandGoogle size={24} />}
          isConnected={googleConnected}
          name="Google"
          onToggle={signinWith('google', setGoogleConnected)}
        />
        <Integration
          icon={<IconBrandWindows size={24} />}
          isConnected={outlookConnected}
          name="Outlook"
          onToggle={signinWith('outlook', setOutlookConnected)}
        />
      </Stack>
    </Box>
  )
}
