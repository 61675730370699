import { Stack, Text } from '@mantine/core'
import { ReactNode } from 'react'

type MeetingInfoItemProps = {
  children: ReactNode
  icon: ReactNode
  title: string
}
function MeetingInfoItem({ children, icon, title }: MeetingInfoItemProps) {
  return (
    <Stack gap="0">
      <Text c="gray" fw="500" fz="sm">
        {icon} {title}
      </Text>
      {children}
    </Stack>
  )
}

export default MeetingInfoItem
