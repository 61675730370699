import { Button, Group, Select, Stack } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { UserReportView } from '~/types/user'
import { arrayRange, combinations } from '~/utils/arrays'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const skipLevelMeetingSchema = z.object({
  end_time: z.string(),
  meeting_date: z.date(),
  requested_from_id: z.string().uuid(),
  start_time: z.string(),
  tenant_id: z.string().uuid(),
})

type SkipLevelMeeting = z.infer<typeof skipLevelMeetingSchema>

export default function MeetingForm({ user }: { user: UserReportView }) {
  const { t: tra } = useTranslation()

  const form = useForm({
    initialValues: {
      end_time: '',
      meeting_date: new Date(),
      requested_from_id: user.id,
      start_time: '',
      tenant_id: user.tenant_id,
    },
    validate: (values) => {
      return validateForm(skipLevelMeetingSchema, values)
    },
  })

  const timeOptions = combinations(arrayRange(7, 19), [
    '00',
    '15',
    '30',
    '45',
  ]).map(([hour, minute]) => ({
    label: `${hour.toString().padStart(2, '0')}:${minute} ${
      hour > 12 ? 'PM' : 'AM'
    }`,
    value: `${hour.toString().padStart(2, '0')}:${minute}`,
  }))

  const submitRequest = async (formData: SkipLevelMeeting) => {
    await Fetcher.post('/api/v1/meeting_requests', {
      meeting_request: formData,
    })
    modals.close('request_meeting_modal')
  }

  return (
    <form
      data-testid="meeting-request-form"
      onSubmit={form.onSubmit((values) => submitRequest(values))}
    >
      <Stack gap="md">
        <DateInput
          label={tra('common:date')}
          {...form.getInputProps('meeting_date')}
        />
        <Group>
          <Select
            data={timeOptions}
            label={tra('common:start_time')}
            {...form.getInputProps('start_time')}
          />
          <Select
            data={timeOptions}
            label={tra('common:end_time')}
            {...form.getInputProps('end_time')}
          />
        </Group>
        <Button type="submit">{tra('meetings:send_request')}</Button>
      </Stack>
    </form>
  )
}
