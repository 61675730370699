import { Button, Text } from '@mantine/core'
import { useState } from 'react'

import classes from '~/components/pulse/sentiment_question.module.css'
import { Question } from '~/types/pulse'

interface SentimentQuestionProps {
  handleChange: (id: string, questionLabel: string, value: string) => void
  question: Question
}

export const choices = [
  { label: '☹️', value: '0' },
  { label: '🙁', value: '25' },
  { label: '😑', value: '50' },
  { label: '🙂', value: '75' },
  { label: '😃', value: '100' },
]

function SentimentQuestion(props: SentimentQuestionProps) {
  const { handleChange, question } = props
  const [answer, setAnswer] = useState<string | null>(null)

  const selectChoice = (value: string) => {
    setAnswer(value)
    handleChange(question.id!, question.label, value)
  }

  return (
    <div>
      <Text size="md">{question.label}</Text>
      <Button.Group w="100%">
        {choices.map((choice) => (
          <Button
            className={answer === choice.value ? classes.selected : ''}
            fullWidth
            size="md"
            variant="default"
            onClick={() => selectChoice(choice.value)}
          >
            {choice.label}
          </Button>
        ))}
      </Button.Group>
    </div>
  )
}

export default SentimentQuestion
