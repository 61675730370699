import { Drawer } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import AgendaItemForm from '~/components/dashboard/agenda_item_form'
import { AgendaItem, AgendaItemIndexView } from '~/types/agenda_item'

type AgendaItemDrawerProps = {
  agendaItem?: AgendaItem
  onClose: () => void
  onSuccess: (agendaItem: AgendaItemIndexView) => void
  opened: boolean
}

function AgendaItemDrawer(props: AgendaItemDrawerProps) {
  const { agendaItem, onClose, onSuccess, opened } = props
  const { t: tra } = useTranslation()

  return (
    <Drawer
      opened={opened}
      position="right"
      title={
        agendaItem && agendaItem.id
          ? tra('agendaItemForm:editSummary')
          : tra('agendaItemForm:summary')
      }
      onClose={onClose}
    >
      <AgendaItemForm agendaItem={agendaItem} onSuccess={onSuccess} />
    </Drawer>
  )
}

export default AgendaItemDrawer
