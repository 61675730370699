import { Button, Select, Textarea, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import UserSingleSelect from '~/components/dashboard/user_single_select'
import { Todo, TodoPriority } from '~/types/todo'
import { User } from '~/types/user'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const PRIORITY_OPTIONS = ['low', 'medium', 'high']

const todoFormSchema = z.object({
  assigneeId: z.string({
    invalid_type_error: 'todoItemAssigneeRequired',
    required_error: 'todoItemAssigneeRequired',
  }),
  description: z.string().nullable().optional(),
  dueDate: z.date().nullable().optional(),
  priority: z.enum(PRIORITY_OPTIONS as unknown as [string, ...string[]]),
  title: z
    .string({ required_error: 'todoItemTitleTooShort' })
    .min(1, { message: 'todoItemTitleTooShort' }),
})
export type TodoFormSchemaType = z.infer<typeof todoFormSchema>

type TodoFormProps = {
  agendaItemId?: string
  onSuccess: (todo: Todo) => void
  todo?: Todo
  users: User[]
}

type UpdateTodoRequestPayload = {
  assignee_id: string
  description: string | null
  due_date: Date | null
  priority: TodoPriority
  title: string
}

type CreateTodoRequestPayload = {
  agenda_item_id: string
} & UpdateTodoRequestPayload

function TodoForm(props: TodoFormProps) {
  const { agendaItemId, onSuccess, todo, users } = props
  const { t: tra } = useTranslation()
  const isEditing = todo && todo.id

  const createOrUpdateTodo = async (
    formData: TodoFormSchemaType,
  ): Promise<Todo> => {
    if (isEditing) {
      const updatedTodo = await Fetcher.put<UpdateTodoRequestPayload, Todo>(
        `/api/v1/todos/${todo.id}`,
        {
          assignee_id: formData.assigneeId,
          description: formData.description ? formData.description : null,
          due_date: formData.dueDate ? formData.dueDate : null,
          priority: formData.priority as TodoPriority,
          title: formData.title,
        },
      )

      return updatedTodo
    }

    const newTodo = await Fetcher.post<CreateTodoRequestPayload, Todo>(
      '/api/v1/todos',
      {
        agenda_item_id: agendaItemId as string,
        assignee_id: formData.assigneeId,
        description: formData.description ? formData.description : null,
        due_date: formData.dueDate ? formData.dueDate : null,
        priority: formData.priority as TodoPriority,
        title: formData.title,
      },
    )

    return newTodo
  }

  const form = useForm<TodoFormSchemaType>({
    initialValues: {
      assigneeId: '',
      description: undefined,
      dueDate: undefined,
      priority: 'low',
      title: '',
    },
    validate: (values) => {
      return validateForm(todoFormSchema, values)
    },
  })

  // Initialize the form with the todo data for editing
  useEffect(() => {
    if (todo?.id) {
      form.setValues({
        assigneeId: todo.assignee_id,
        description: todo.description ? todo.description : undefined,
        dueDate: todo.due_date ? new Date(todo.due_date) : undefined,
        priority: todo.priority,
        title: todo.title,
      })
    }
  }, [])

  const onSubmit = async (formData: TodoFormSchemaType) => {
    try {
      const persistedTodo = await createOrUpdateTodo(formData)
      onSuccess(persistedTodo)
    } catch (err) {
      showErrorNotification(err)
    }
  }

  return (
    <form
      className="flexColumnForm"
      data-testid="todo-form"
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <TextInput
        data-testid="todo-form-title-input"
        label={tra('todoForm:titleLabel')}
        placeholder={tra('todoForm:titlePlaceholder')}
        withAsterisk
        {...form.getInputProps('title')}
      />
      <Textarea
        data-testid="todo-form-description-input"
        label={tra('todoForm:descriptionLabel')}
        placeholder={tra('todoForm:descriptionPlaceholder')}
        {...form.getInputProps('description')}
      />
      <UserSingleSelect
        label={tra('todoForm:assigneeLabel')}
        placeholder={tra('todoForm:assigneePlaceholder')}
        users={users}
        {...form.getInputProps('assigneeId')}
      />
      <DatePickerInput
        clearable
        data-testid="todo-form-due-date-input"
        label={tra('todoForm:dueDateLabel')}
        minDate={new Date()}
        placeholder={tra('todoForm:dueDatePlaceholder')}
        {...form.getInputProps('dueDate')}
      />
      <Select
        data={PRIORITY_OPTIONS.map((p) => ({
          label: tra(`common:priority:${p}`),
          value: p,
        }))}
        data-testid="todo-form-priority"
        label={tra('todoForm:priorityLabel')}
        {...form.getInputProps('priority')}
      />
      <Button type="submit">
        {isEditing ? tra('todoForm:edit') : tra('todoForm:create')}
      </Button>
    </form>
  )
}

export default TodoForm
