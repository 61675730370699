import {
  Button,
  Center,
  NativeSelect,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm, UseFormReturnType } from '@mantine/form'
import {
  IconArrowLeft,
  IconArrowRight,
  IconAt,
  IconBuilding,
  IconCheck,
  IconIdBadge,
  IconIdBadge2,
  IconUsersGroup,
} from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { AnyZodObject, z } from 'zod'

import ErrorAlert, { hasFormAnyErrors } from '~/components/shared/error_alert'
import VoynLoadingOverlay from '~/components/shared/loading'
import MultipleEmailsInput from '~/components/shared/multiple_emails_input'
import TopBar from '~/components/shared/top_bar'
import classes from '~/pages/onboarding/base.module.css'
import Fetcher from '~/utils/fetcher'
import { emailSchema } from '~/utils/schemas'
import {
  fetchSession,
  homePath,
  isUserToBeTakenDashboard,
} from '~/utils/session'
import validateForm from '~/utils/validate-form'

const companySizes = ['1-10', '10-50', '50-100', '100-250', '250+'] as const
const createTenantSchema = z.object({
  tenantName: z.string().min(1, { message: 'companyNameInvalid' }),
  tenantSize: z.enum(companySizes),
})

const completeProfileSchema = z.object({
  firstName: z.string().min(1, { message: 'firstNameInvalid' }),
  lastName: z.string().min(1, { message: 'lastNameInvalid' }),
})

const inviteUsersSchema = z.object({
  emails: z.array(emailSchema),
})

const onboardingSchema = completeProfileSchema
  .and(createTenantSchema)
  .and(inviteUsersSchema)
type OnboardingSchema = z.infer<typeof onboardingSchema>
type CompleteOnboardingPayload = {
  emails: string[]
  first_name: string
  last_name: string
  tenant_name: string
  tenant_size: string
}

interface OnboardingStep {
  content: (form: UseFormReturnType<OnboardingSchema>) => ReactNode
  description: string
  key: string
  label: string
  nextStepText: string
  prevStepText?: string
  schema?: AnyZodObject
}

function getOnboardingStates(tra: TFunction): OnboardingStep[] {
  return [
    {
      content: (form) => {
        return (
          <>
            <Stack gap="0">
              <Title mb="0">
                {tra('onboarding:setupProfile:stepperHeader')}
              </Title>
              <Text size="lg">
                {tra('onboarding:setupProfile:formDescription')}
              </Text>
            </Stack>
            <TextInput
              label={tra('common:firstName')}
              leftSection={<IconIdBadge />}
              placeholder={tra('common:firstNamePlaceholder')}
              radius="xl"
              size="lg"
              {...form.getInputProps('firstName')}
            />
            <TextInput
              label={tra('common:lastName')}
              leftSection={<IconIdBadge2 />}
              placeholder={tra('common:lastNamePlaceholder')}
              radius="xl"
              size="lg"
              {...form.getInputProps('lastName')}
            />
          </>
        )
      },
      description: tra('onboarding:setupProfile:stepperDescription'),
      key: 'SETUP_PROFILE',
      label: tra('onboarding:setupProfile:stepperHeader'),
      nextStepText: tra('onboarding:createTenant:stepperHeader'),
      schema: completeProfileSchema,
    },
    {
      content: (form) => {
        return (
          <>
            <Stack gap="0">
              <Title mb="0">
                {tra('onboarding:createTenant:stepperHeader')}
              </Title>
              <Text size="lg">
                {tra('onboarding:createTenant:formDescription')}
              </Text>
            </Stack>
            <TextInput
              label={tra('common:companyName')}
              leftSection={<IconBuilding />}
              placeholder={tra('common:companyNamePlaceholder')}
              radius="xl"
              size="lg"
              {...form.getInputProps('tenantName')}
            />
            <NativeSelect
              data={[...companySizes]}
              label={tra('common:companySize')}
              leftSection={<IconUsersGroup />}
              radius="xl"
              size="lg"
              {...form.getInputProps('tenantSize')}
            />
          </>
        )
      },
      description: tra('onboarding:createTenant:stepperDescription'),
      key: 'CREATE_TENANT',
      label: tra('onboarding:createTenant:stepperHeader'),
      nextStepText: tra('onboarding:inviteYourTeam:stepperHeader'),
      prevStepText: tra('onboarding:setupProfile:stepperHeader'),
      schema: createTenantSchema,
    },
    {
      content: (form) => {
        return (
          <>
            <Stack gap="0">
              <Title mb="0">
                {tra('onboarding:inviteYourTeam:stepperHeader')}
              </Title>
              <Text size="lg">
                {tra('onboarding:inviteYourTeam:formDescription')}
              </Text>
            </Stack>
            <MultipleEmailsInput
              icon={<IconAt />}
              initialValue={form.values.emails}
              radius="xl"
              size="lg"
              onUpdate={(emails) => {
                form.setFieldValue('emails', emails)
              }}
            />
            {form.errors.api}
          </>
        )
      },

      description: tra('onboarding:inviteYourTeam:stepperDescription'),
      key: 'INVITE_YOUR_TEAM',
      label: tra('onboarding:inviteYourTeam:stepperHeader'),
      nextStepText: tra('onboarding:completeSetup'),
      prevStepText: tra('onboarding:createTenant:stepperHeader'),
    },
  ]
}

function OnboardingLayout() {
  const { isLoading: isSessionLoading, session } = fetchSession()
  const [active, setActive] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t: tra } = useTranslation()

  const onboardingStates = getOnboardingStates(tra)

  const form = useForm<OnboardingSchema>({
    initialValues: {
      emails: [] as string[],
      firstName: '',
      lastName: '',
      tenantName: '',
      tenantSize: '1-10',
    },
    onValuesChange: () => {
      form.clearFieldError('api')
    },
    validate: (values) => {
      const activeStep = onboardingStates[active]

      if (activeStep?.schema) {
        return validateForm(activeStep.schema, values)
      }

      return {}
    },
  })

  if (isSessionLoading) {
    return <VoynLoadingOverlay />
  }

  if (!session) {
    return <Navigate replace to="/login" />
  }

  if (isUserToBeTakenDashboard(session)) {
    return <Navigate replace to={homePath} />
  }

  const completeOnboarding = async (values: OnboardingSchema) => {
    setIsLoading(true)

    try {
      await Fetcher.post<{ onboarding: CompleteOnboardingPayload }, {}>(
        '/api/v1/complete_onboarding',
        {
          onboarding: {
            emails: values.emails,
            first_name: values.firstName,
            last_name: values.lastName,
            tenant_name: values.tenantName,
            tenant_size: values.tenantSize,
          },
        },
      )

      window.location.assign(homePath)
    } catch (e) {
      form.setErrors({ api: <ErrorAlert error={e} radius="xl" /> })
    } finally {
      setIsLoading(false)
    }
  }

  const nextStep = () => {
    if (active < onboardingStates.length - 1) {
      // We don't put this condition in the if clause above, otherwise we'll be sending the HTTP request in the else
      // clause below when the form is first rendered and user clicks on submit button.
      if (!form.validate().hasErrors) {
        setActive(active + 1)
      }
    } else {
      // This means the onboarding is completed
      completeOnboarding(form.values)
    }
  }

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current))

  return (
    <>
      <TopBar isLanguageSelectEnabled={false} />
      <Stepper active={active} completedIcon={<IconCheck />} mt="lg" px="lg">
        {onboardingStates.map((step, index) => {
          return (
            <Stepper.Step
              description={step.description}
              key={step.key}
              label={step.label}
            >
              {active === index && (
                <div className={classes.onboardingFormContainer}>
                  {step.content(form)}
                  {step.nextStepText && (
                    <Button
                      disabled={hasFormAnyErrors(form.errors)}
                      loading={isLoading}
                      radius="xl"
                      rightSection={<IconArrowRight />}
                      size="lg"
                      onClick={nextStep}
                    >
                      {step.nextStepText}
                    </Button>
                  )}
                  {step.prevStepText && (
                    <Center>
                      <Button
                        color="gray"
                        leftSection={<IconArrowLeft />}
                        variant="subtle"
                        onClick={prevStep}
                      >
                        {step.prevStepText}
                      </Button>
                    </Center>
                  )}
                </div>
              )}
            </Stepper.Step>
          )
        })}
      </Stepper>
    </>
  )
}

export default OnboardingLayout
