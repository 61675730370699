import {
  AvatarGroup,
  Badge,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { IconCalendarTime, IconRelationOneToOne } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import classes from '~/components/dashboard/meeting_card.module.css'
import { MeetingIndexView } from '~/types/meeting'
import { MeetingKind } from '~/types/meeting_kind'

type MeetingCardProps = {
  meeting: MeetingIndexView
}

function MeetingCard(props: MeetingCardProps) {
  const { meeting } = props
  const { attendees, creator } = meeting

  const attendeesWithoutCreator = attendees.filter(
    (attendee) => attendee.id !== creator.id,
  )
  const navigate = useNavigate()

  return (
    <Paper
      className={classes.meetingCard}
      p="sm"
      withBorder
      onClick={() => navigate(`/dashboard/meetings/${meeting.id}`)}
    >
      <Stack gap="xs">
        <Group align="center" gap="0">
          {meeting.kind === MeetingKind.ONO ? (
            <Tooltip label="One-on-One Meeting">
              <IconRelationOneToOne
                color="gray"
                data-testid="meeting-card-ono-icon"
                size="1rem"
              />
            </Tooltip>
          ) : (
            <IconCalendarTime
              color="gray"
              data-testid="meeting-card-generic-icon"
              size="1rem"
            />
          )}

          <Text ml="0.25rem" truncate="end">
            {meeting.title}
          </Text>
        </Group>
        <Group align="flex-end" gap="0" justify="space-between">
          <Badge
            color="blue"
            leftSection={<IconCalendarTime size={12} />}
            variant="light"
          >
            {meeting.planned_start_time}
          </Badge>
          <AvatarGroup>
            <LabeledAvatar size="sm" user={creator} />
            {attendeesWithoutCreator.map((attendee) => (
              <LabeledAvatar key={attendee.id} size="sm" user={attendee} />
            ))}
          </AvatarGroup>
        </Group>
      </Stack>
    </Paper>
  )
}

export default MeetingCard
