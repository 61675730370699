import { Textarea } from '@mantine/core'

import { Question } from '~/types/pulse'

interface FreeTextQuestionProps {
  handleChange: (id: string, questionLabel: string, value: string) => void
  question: Question
}
function FreeTextQuestion(props: FreeTextQuestionProps) {
  const { handleChange, question } = props

  return (
    <Textarea
      label={question.label}
      placeholder="Write your answer here"
      size="md"
      onChange={(event) =>
        handleChange(question.id!, question.label, event.currentTarget.value)
      }
    />
  )
}

export default FreeTextQuestion
