import { Container, Grid, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import MeetingRequestsCard from '~/components/dashboard/home/meeting_requests'
import TalkingPointsAddressingYouCard from '~/components/dashboard/home/talking_points_addressing_you'
import TalkingPointsCreatedByYouCard from '~/components/dashboard/home/talking_points_created_by_you'
import classes from '~/pages/dashboard/home.module.css'
import { useSession } from '~/utils/session'

function HomePage() {
  const { t: tra } = useTranslation()
  const { user } = useSession()

  return (
    <Container
      className={classes.homeContainer}
      fluid
      h="100%"
      py="md"
      w="100%"
    >
      <Title mb="sm" order={3}>
        {tra('home:welcome', { fullName: user.full_name })}
      </Title>
      <Grid>
        <Grid.Col span={4}>
          <MeetingRequestsCard />
        </Grid.Col>
        <Grid.Col span={4}>
          <TalkingPointsCreatedByYouCard />
        </Grid.Col>
        <Grid.Col span={4}>
          <TalkingPointsAddressingYouCard />
        </Grid.Col>
      </Grid>
    </Container>
  )
}

export default HomePage
