import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { Pulse } from '~/types/pulse'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'

import PulseForm from './form'

function EditPulsePage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: nodes } = useSWR(`/api/v1/nodes`, (url) =>
    Fetcher.get<{}, VNode[]>(url),
  )
  const { data: pulse, isLoading: pulseLoading } = useSWR(
    `/api/v1/pulses/${id}`,
    (url) => Fetcher.get<{}, Pulse>(url),
  )

  const savePulse = async (values: Pulse) => {
    await Fetcher.put<{ pulse: Pulse }, Pulse>(`/api/v1/pulses/${id}`, {
      pulse: values,
    })
    navigate('/dashboard/admin/pulses')
  }

  return (
    <PulseForm
      isLoading={pulseLoading}
      nodes={nodes}
      pulse={pulse}
      onSave={savePulse}
    />
  )
}

export default EditPulsePage
