import { Loader } from '@mantine/core'
import useSWR from 'swr'

import Kanban from '~/components/dashboard/kanban'
import { Todo } from '~/types/todo'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function TodosPage() {
  const { user: currentUser } = useSession()

  const {
    data: todos,
    error,
    isLoading,
  } = useSWR('/api/v1/todos', (url) =>
    Fetcher.get<{ assignee_id: string }, Todo[]>(url, {
      assignee_id: currentUser.id,
    }),
  )

  if (isLoading) {
    return <Loader data-testid="todos-loading" />
  }

  if (error) {
    return <h1 data-testid="todos-error">ERROR</h1>
  }

  if (todos) {
    return <Kanban todos={todos} />
  }

  return null
}

export default TodosPage
