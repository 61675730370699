export type AgendaItemFilter = {
  addresseeId?: string
  completed?: boolean
  creatorId?: string
  meetingId?: string | null
}

export type AgendaItemFilterPayload = {
  addressee_id?: string
  completed?: boolean
  creator_id?: string
  meeting_id?: string | null
}

export function filterToPayload(
  filter: AgendaItemFilter,
): AgendaItemFilterPayload {
  const { addresseeId, completed, creatorId, meetingId } = filter

  const payload: AgendaItemFilterPayload = {}

  if (addresseeId) {
    payload.addressee_id = addresseeId
  }

  if (completed !== undefined) {
    payload.completed = completed
  }

  if (creatorId) {
    payload.creator_id = creatorId
  }

  if (meetingId !== undefined) {
    payload.meeting_id = meetingId
  }

  return payload
}
