import {
  Button,
  Modal,
  MultiSelect,
  NativeSelect,
  Pill,
  PillsInput,
  Stack,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { TFunction } from 'i18next'
import { ChangeEvent, useRef, useState } from 'react'
import { FaAt, FaSitemap, FaUserTag } from 'react-icons/fa'
import useSWR from 'swr'
import { z } from 'zod'

import VoynLoadingOverlay from '~/components/shared/loading'
import {
  mapNodesToSelectOptions,
  mapRolesToSelectOptions,
} from '~/pages/dashboard/admin/users/common'
import { UserIndexView } from '~/types/user'
import { VNode } from '~/types/vnode'
import { Role } from '~/utils/constants'
import Fetcher from '~/utils/fetcher'
import { emailSchema } from '~/utils/schemas'
import { useSession } from '~/utils/session'
import validateForm from '~/utils/validate-form'

import UserSingleSelect from './user_single_select'

const userInviteFormSchema = z.object({
  emails: z.array(emailSchema).min(1, { message: 'emailInvalid' }),
  nodeIds: z.string().uuid().array(),
  parent_id: z.union([z.string().uuid(), z.null()]),
  role: z.nativeEnum(Role),
})
type UserInviteFormSchemaType = z.infer<typeof userInviteFormSchema>
type UserInvitePostData = {
  users: {
    email: string
    node_memberships_attributes: { node_id: string }[]
    parent_id?: string
    role: Role
  }[]
}

export default function UserInviteForm({
  onClose,
  tra,
  users,
}: {
  onClose: () => void
  tra: TFunction
  users: UserIndexView[]
}) {
  const [emails, setEmails] = useState<string[]>([])
  const ref = useRef<HTMLInputElement>(null)
  const { user: currentUser } = useSession()
  // TODO: Fix this for multiple nodes
  const { data, isLoading } = useSWR(
    [`/api/v1/nodes/${currentUser.nodes[0].id}/children`, true],
    ([url, include_self]) =>
      Fetcher.get<{ include_self: boolean }, VNode[]>(url, {
        include_self,
      }),
  )

  const form = useForm({
    initialValues: {
      emails: [] as string[],
      nodeIds: [] as string[],
      parent_id: null,
      role: Role.USER,
    },
    validate: (values) => {
      return validateForm(userInviteFormSchema, values)
    },
  })

  const onSubmit = async (formData: UserInviteFormSchemaType) => {
    const postData = formData.emails.reduce((acc: any[], email: string) => {
      return [
        ...acc,
        {
          email,
          node_memberships_attributes: formData.nodeIds.map((nodeId) => ({
            node_id: nodeId,
          })),
          parent_id: formData.parent_id,
          role: formData.role,
        },
      ]
    }, [])

    await Fetcher.post<UserInvitePostData, UserIndexView[]>('/api/v1/users', {
      users: postData,
    })

    onClose()
  }

  const removeEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email))
  }

  const addNewEmail = (email: string) => {
    try {
      emailSchema.parse(email)
      setEmails([...emails, email])
      form.setValues({ emails: [...emails, email] })
      if (ref?.current?.value) {
        ref.current.value = ''
      }
    } catch (e) {
      // Silently fail if it is not a valid email.
    }
  }

  const onBlur = () => {
    if (ref?.current?.value) {
      addNewEmail(ref.current.value)
    }
  }

  const parseEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const emailStr = event.target.value

    if (emailStr) {
      const newEmails = emailStr.split(',')

      if (newEmails.length > 1) {
        const newEmail = newEmails[0]
        addNewEmail(newEmail)
      }
    }
  }

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  return (
    <Modal
      centered
      opened
      title={tra('admin:users:inviteUserModalTitle')}
      onClose={onClose}
    >
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <Stack gap="sm">
          <PillsInput
            data-testid="emails-input"
            description={tra('admin:users:emailDescription')}
            label={tra('common:email')}
            leftSection={<FaAt />}
            {...form.getInputProps('emails')}
          >
            <Pill.Group>
              {emails.map((o) => (
                <Pill key={o} withRemoveButton onRemove={() => removeEmail(o)}>
                  {o}
                </Pill>
              ))}
              <PillsInput.Field
                placeholder={tra('common:emailPlaceholder')}
                ref={ref}
                onBlur={onBlur}
                onChange={parseEmail}
              />
            </Pill.Group>
          </PillsInput>
          {data && (
            <MultiSelect
              data={mapNodesToSelectOptions(data, tra)}
              data-testid="nodes-select"
              description={tra('admin:users:organizationalUnitDescription')}
              label={tra('common:organizationalUnit')}
              leftSection={<FaSitemap />}
              placeholder={tra('admin:users:selectOrganizationalUnit')}
              {...form.getInputProps('nodeIds')}
            />
          )}
          <NativeSelect
            data={mapRolesToSelectOptions(tra)}
            data-testid="roles-select"
            description={tra('admin:users:selectRole')}
            label={tra('common:role')}
            leftSection={<FaUserTag />}
            {...form.getInputProps('role')}
          />
          <UserSingleSelect
            description={tra('admin:users:selectManager')}
            label={tra('admin:users:manager')}
            placeholder="Choose the manager for the new users."
            users={users}
            {...form.getInputProps('parent_id')}
          />

          <Button data-testid="invite-submit-button" type="submit">
            {tra('admin:users:sendInvitations')}
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}
