import { Button } from '@mantine/core'

import { SlashMenuItem } from '~/components/dashboard/tiptap/slash_menu_items'

function CommandList(props: { command: any; items: SlashMenuItem[] }) {
  const { command, items } = props

  const selectItem = (index: number) => {
    const item = items[index]

    if (item) {
      command(item)
    }
  }

  console.log('items', items)
  return (
    <Button.Group>
      {items.map((item, index) => {
        return (
          <Button
            key={item.title}
            variant="default"
            onClick={() => selectItem(index)}
          >
            {item.title}
          </Button>
        )
      })}
    </Button.Group>
  )
}

export default CommandList
