import { LoadingOverlay } from '@mantine/core'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Calendar } from '~/types/calendar'
import Fetcher from '~/utils/fetcher'

import CalendarEvents from './calendar/calendar_events'
import CalendarTopPanel from './calendar/top_panel'

export default function CalendarPage() {
  const { t: tra } = useTranslation()
  const [calendarDate, setCalendarDate] = useState<Moment>(moment())
  const [weekStart, setWeekStart] = useState<string>(
    calendarDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
  )
  const [weekEnd, setWeekEnd] = useState<string>(
    calendarDate.clone().endOf('isoWeek').format('YYYY-MM-DD'),
  )

  const { data, isLoading, mutate } = useSWR(
    [`/api/v1/calendars?week_start=${weekStart}&week_end=${weekEnd}`],
    ([url]) => Fetcher.get<{}, Calendar>(url),
  )

  const setPreviousWeek = (date: Moment) => {
    const newDate = date.clone().subtract(1, 'week')
    setCalendarDate(newDate)
    setWeekStart(newDate.clone().startOf('isoWeek').format('YYYY-MM-DD'))
    setWeekEnd(newDate.clone().endOf('isoWeek').format('YYYY-MM-DD'))
    mutate({ ...data })
  }

  const setNextWeek = (date: Moment) => {
    const newDate = date.clone().add(1, 'week')
    setCalendarDate(newDate)
    setWeekStart(newDate.clone().startOf('isoWeek').format('YYYY-MM-DD'))
    setWeekEnd(newDate.clone().endOf('isoWeek').format('YYYY-MM-DD'))
    mutate({ ...data })
  }

  if (isLoading) return <LoadingOverlay visible />

  return (
    <div>
      <CalendarTopPanel
        date={calendarDate}
        setNextWeek={setNextWeek}
        setPreviousWeek={setPreviousWeek}
        tra={tra}
      />
      <CalendarEvents date={calendarDate} events={data?.events} tra={tra} />
    </div>
  )
}
