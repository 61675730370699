import {
  Button,
  Group,
  LoadingOverlay,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import CompensationForm from '~/components/dashboard/jobs/compensation_form'
import adminClasses from '~/layouts/admin.module.css'
import { type Compensation } from '~/types/jobs'
import Fetcher from '~/utils/fetcher'

export default function CompensationsPage() {
  const { t: tra } = useTranslation()
  const {
    data: compensations,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/compensations`, (url) =>
    Fetcher.get<{}, Compensation[]>(url),
  )

  const onCompensationSave = () => {
    mutate()
    modals.close('compensation_form_modal')
  }

  const openCompensationForm = (compensation?: Compensation) =>
    modals.open({
      children: (
        <CompensationForm
          compensation={compensation}
          onSave={onCompensationSave}
        />
      ),
      modalId: 'compensation_form_modal',
      title:
        compensation !== undefined
          ? tra('jobs:edit_compensation')
          : tra('jobs:add_new_compensation'),
    })

  const deleteCompensation = async (compensation: Compensation) => {
    modals.openConfirmModal({
      children: [<Text>{tra('jobs:delete_compensation_confirmation')}</Text>],
      confirmProps: { color: 'red' },
      labels: { cancel: tra('common:cancel'), confirm: tra('common:delete') },
      onConfirm: async () => {
        await Fetcher.delete(`/api/v1/compensations/${compensation.id}`)
        mutate()
      },
      title: tra('jobs:delete_compensation'),
    })
  }

  const tableData = compensations?.map((compensation: Compensation) => (
    <Table.Tr key={compensation.id}>
      <Table.Td>{compensation.location ?? tra('common:na')}</Table.Td>
      <Table.Td>{compensation.job_function?.name ?? tra('common:na')}</Table.Td>
      <Table.Td>{compensation.job_level?.name ?? tra('common:na')}</Table.Td>
      <Table.Td>{tra(`jobs:${compensation.compensation_type}`)}</Table.Td>
      <Table.Td>{compensation.compensation_value}</Table.Td>
      <Table.Td>{tra(`common:${compensation.period}`)}</Table.Td>
      <Table.Td>
        <Group>
          <Button onClick={() => openCompensationForm(compensation)}>
            {tra('common:edit')}
          </Button>
          <Button onClick={() => deleteCompensation(compensation)}>
            {tra('common:delete')}
          </Button>
        </Group>
      </Table.Td>
    </Table.Tr>
  ))

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('jobs:compensation_menu_title')}</Title>
        <Button onClick={() => openCompensationForm()}>
          {tra('jobs:add_new_compensation')}
        </Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{tra('common:location')}</Table.Th>
            <Table.Th>{tra('jobs:function_name')}</Table.Th>
            <Table.Th>{tra('jobs:level_name')}</Table.Th>
            <Table.Th>{tra('jobs:compensation_type')}</Table.Th>
            <Table.Th>{tra('common:value')}</Table.Th>
            <Table.Th>{tra('common:period')}</Table.Th>
            <Table.Th>{tra('common:actions')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {compensations !== undefined && compensations.length > 0 ? (
            tableData
          ) : (
            <Table.Tr>
              <Table.Td colSpan={3}>{tra('jobs:no_levels_found')}</Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </>
  )
}
