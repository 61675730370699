import { ActionIcon, Button, Grid, Group, Stack, Textarea } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { IconTrash } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { FeedbackRequest } from '~/types/feedback'
import { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const feedbackRequestFormSchema = z.object({
  deadline: z.date().optional(),
  questions: z.array(z.object({ label: z.string().min(5) })).min(1),
  requested_from_id: z.string().uuid(),
})

type FeedbackRequestFormSchema = z.infer<typeof feedbackRequestFormSchema>

export default function RequestFeedbackForm({
  currentUser,
  user,
}: {
  currentUser: User
  user: User
}) {
  const { t: tra } = useTranslation()
  const form = useForm<FeedbackRequestFormSchema>({
    initialValues: {
      deadline: undefined,
      questions: [
        { label: tra('feedback_requests:what_have_i_delivered') },
        { label: tra('feedback_requests:what_should_i_improve') },
      ],
      requested_from_id: user.id,
    },
    validate: (values) => validateForm(feedbackRequestFormSchema, values),
  })

  const removeQuestion = (index: number) => {
    if (form.values.questions.length === 1) {
      return
    }

    form.setFieldValue(
      'questions',
      form.values.questions.filter((_, i) => i !== index),
    )
  }

  const addQuestion = () => {
    form.setFieldValue('questions', [
      ...form.values.questions,
      { label: tra('feedback_requests:enter_question_here') },
    ])
  }

  const submitForm = async (values: FeedbackRequestFormSchema) => {
    await Fetcher.post<{}, FeedbackRequest>('/api/v1/feedback_requests', {
      feedback_request: { tenant_id: currentUser.tenant_id, ...values },
    })
    modals.close('request_feedback_modal')
  }

  return (
    <form
      data-testid="feedback-request-form"
      onSubmit={form.onSubmit(submitForm)}
    >
      <Stack gap="md">
        {form.values.questions.map((question, index) => (
          <Grid key={`question-${index + 1}`}>
            <Grid.Col span={10}>
              <Textarea
                autosize
                label={`Question #${index + 1}`}
                minRows={3}
                value={question.label}
                onChange={(event) =>
                  form.setFieldValue(
                    'questions',
                    form.values.questions.map((q, i) =>
                      i === index ? { label: event.target.value } : q,
                    ),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2} style={{ alignSelf: 'center' }}>
              <ActionIcon
                disabled={form.values.questions.length === 1}
                size="sm"
                onClick={() => removeQuestion(index)}
              >
                <IconTrash />
              </ActionIcon>
            </Grid.Col>
          </Grid>
        ))}
        <DateInput
          label={tra('common:deadline')}
          {...form.getInputProps('deadline')}
        />
        <Group justify="flex-end">
          <Button
            disabled={form.values.questions.length === 2}
            onClick={addQuestion}
          >
            {tra('feedback_requests:add_question')}
          </Button>
          <Button type="submit">
            {tra('feedback_requests:send_feedback_request')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
