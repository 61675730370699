/* eslint-disable import/prefer-default-export */
import useSWR from 'swr'

import Fetcher from '~/utils/fetcher'

export function useReports<R>(userId: string): {
  isLoading: boolean
  mutate: () => void
  reports: R[] | undefined
} {
  const {
    data: reports,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/users/${userId}/reports`, (url) =>
    Fetcher.get<{}, R[]>(url),
  )

  return { isLoading, mutate, reports }
}
