import { Button, NumberInput, Select, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { z } from 'zod'

import type {
  Compensation,
  JobFunctionsResponse,
  JobLevelsResponse,
} from '~/types/jobs'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const COMPENSATION_PERIODS = [
  'weekly',
  'monthly',
  'annual',
  'one_time',
] as const
const COMPENSATION_TYPES = [
  'bonus',
  'base_salary',
  'rsu',
  'stock_options',
] as const

const compensationFormSchema = z.object({
  compensation_type: z.enum(
    COMPENSATION_TYPES as unknown as [string, ...string[]],
  ),
  compensation_value: z
    .string()
    .transform(Number)
    .pipe(z.number().multipleOf(0.001)),
  job_function: z
    .object({
      id: z.string().uuid(),
    })
    .optional(),
  job_level: z
    .object({
      id: z.string().uuid(),
    })
    .optional(),
  location: z.string().nullable(),
  period: z.enum(COMPENSATION_PERIODS as unknown as [string, ...string[]]),
})

type CompensationFormSchema = z.infer<typeof compensationFormSchema>
type JobLevelOption = { label: string; value: string }

export default function CompensationForm({
  compensation,
  onSave,
}: {
  compensation?: Compensation
  onSave?: () => void
}) {
  const { t: tra } = useTranslation()
  const { data: jobFunctions } = useSWR('/api/v1/job_functions', (url) =>
    Fetcher.get<{}, JobFunctionsResponse>(url),
  )
  const { data: jobLevels } = useSWR('/api/v1/job_levels', (url) =>
    Fetcher.get<{}, JobLevelsResponse>(url),
  )
  const initialJobLevels: JobLevelOption[] =
    compensation !== undefined
      ? jobLevels?.job_levels
          .filter((l) => l.job_function.id === compensation.job_function?.id)
          .map((l) => ({ label: l.name, value: l.id })) ?? []
      : []

  const [jobLevelOptions, setJobLevelOptions] =
    useState<JobLevelOption[]>(initialJobLevels)

  const form = useForm<CompensationFormSchema>({
    initialValues: compensation ?? {
      compensation_type: 'base_salary',
      compensation_value: 0.0,
      job_function: { id: '' },
      job_level: { id: '' },
      location: null,
      period: 'monthly',
    },
    validate: (values) => validateForm(compensationFormSchema, values),
  })

  const submitForm = async (values: CompensationFormSchema) => {
    const formData = {
      job_function_id: values.job_function?.id,
      job_level_id: values.job_level?.id,
      ...values,
    }
    if (compensation?.id) {
      await Fetcher.put(`/api/v1/compensations/${compensation.id}`, {
        compensation: formData,
      })
    } else {
      await Fetcher.post('/api/v1/compensations', { compensation: formData })
    }
    onSave?.()
  }

  const onJobFunctionChange = (value: string) => {
    form.setFieldValue('job_function.id', value)
    form.setFieldValue('job_level.id', null)
    setJobLevelOptions(
      jobLevels?.job_levels
        .filter((l) => l.job_function.id === value)
        .map((l) => ({ label: l.name, value: l.id })) ?? [],
    )
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <Stack gap="lg">
        <Select
          data={jobFunctions?.job_functions?.map((fn) => ({
            label: fn.name,
            value: fn.id,
          }))}
          label={tra('jobs:function_name')}
          {...form.getInputProps('job_function.id')}
          onChange={(value) => onJobFunctionChange(value!)}
        />
        <Select
          data={jobLevelOptions}
          label={tra('jobs:level_name')}
          {...form.getInputProps('job_level.id')}
        />
        <Select
          data={COMPENSATION_PERIODS.map((p) => ({
            label: tra(`common:${p}`),
            value: p,
          }))}
          label={tra('common:period')}
          required
          {...form.getInputProps('period')}
        />
        <Select
          data={COMPENSATION_TYPES.map((t) => ({
            label: tra(`jobs:${t}`),
            value: t,
          }))}
          label={tra('jobs:compensation_type')}
          required
          {...form.getInputProps('compensation_type')}
        />
        <NumberInput
          label={tra('jobs:compensation_value')}
          required
          {...form.getInputProps('compensation_value')}
        />
        <Button type="submit" variant="outline">
          {compensation ? tra('common:update') : tra('common:create')}
        </Button>
      </Stack>
    </form>
  )
}
