import { Stack, Title, UnstyledButton } from '@mantine/core'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import classes from '~/components/dashboard/second_nav.module.css'

export type NavItem = {
  i18nKey: string
  icon: ReactNode
  url: string
}

function SecondNav({ items, title }: { items: NavItem[]; title: string }) {
  const navigate = useNavigate()
  const { t: tra } = useTranslation()

  return (
    <Stack className={classes.secondNavContainer} gap="0">
      <Title mb="sm" order={2}>
        {title}
      </Title>
      {items.map((item) => {
        return (
          <UnstyledButton
            className={clsx(classes.secondNavItem, {
              [classes.active]: window.location.pathname.includes(item.url),
            })}
            key={item.url}
            onClick={() => navigate(item.url)}
          >
            {item.icon}
            {tra(item.i18nKey)}
          </UnstyledButton>
        )
      })}
    </Stack>
  )
}

export default SecondNav
