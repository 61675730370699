import {
  Container,
  Divider,
  Grid,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core'
import { TFunction } from 'i18next'
import moment, { Moment } from 'moment'
import { Link } from 'react-router-dom'

import type { CalendarEvent } from '~/types/calendar'
import { arrayRange } from '~/utils/arrays'

type CalendarDayEvents = Record<number, CalendarEvent[]>

const generateWeekDates = (date: Moment) => {
  const startOfWeek = date.startOf('isoWeek').clone()
  return [
    date.startOf('isoWeek'),
    ...arrayRange(0, 5).map(() => startOfWeek.add(1, 'days').clone()),
  ]
}

const formatEventTime = (event: CalendarEvent) => {
  return `${moment(event.start_time).format('HH:mm')} - ${moment(
    event.start_time,
  )
    .add(event.duration, 'minutes')
    .format('HH:mm')}`
}

function CalendarEventComponent({
  event,
  tra,
}: {
  event: CalendarEvent
  tra: TFunction
}) {
  const { duration } = event
  return (
    <div>
      <Text fw={600}>
        <Link to={`/dashboard/meetings/${event.id}`}>{event.title}</Link>
      </Text>
      <Text fw={300}>
        {formatEventTime(event)} - ({tra('calendar:x_minutes', { duration })})
      </Text>
    </div>
  )
}

export default function CalendarWeekDays({
  date,
  events,
  tra,
}: {
  date: Moment
  events?: CalendarEvent[]
  tra: TFunction
}) {
  const mappedEvents: CalendarDayEvents =
    events?.reduce((acc, event) => {
      const dayIndex = moment(event.start_time).day()
      if (!acc[dayIndex]) {
        acc[dayIndex] = []
      }
      acc[dayIndex].push(event)
      return acc
    }, {} as CalendarDayEvents) || []

  return (
    <Container fluid style={{ marginTop: '50px', padding: 10 }}>
      <Grid grow gutter="xl">
        {generateWeekDates(date.clone()).map((weekDate) => {
          const borderColor = weekDate.isSame(date, 'date')
            ? 'var(--mantine-color-brand-filled)'
            : 'gray'
          const textColor = weekDate.isSame(date, 'date') ? 'brand' : 'black'
          const gridBgColor = weekDate.isSame(date, 'date')
            ? 'var(--mantine-color-gray-2)'
            : 'var(--mantine-color-gray-0)'

          return (
            <Grid.Col
              key={weekDate.unix()}
              span={1}
              style={{
                margin: '5px',
                padding: '5px',
              }}
            >
              <Stack>
                <Text c={textColor} fw={700} size="xl">
                  {tra(`date:${weekDate.format('dddd')}`)}
                </Text>
                <Text c={textColor} fw={700} style={{ fontSize: 24 }}>
                  {parseInt(weekDate.format('D'), 10) < 10
                    ? `0${weekDate.format('D')}`
                    : weekDate.format('D')}{' '}
                </Text>
                <div style={{ background: gridBgColor }}>
                  <Divider
                    size="md"
                    style={{
                      borderTopColor: borderColor,
                      marginTop: '10px',
                      width: '100%',
                    }}
                  />
                  <SimpleGrid cols={1} style={{ margin: 10 }}>
                    {mappedEvents[weekDate.day()]?.map((event) => (
                      <CalendarEventComponent
                        event={event}
                        key={event.id}
                        tra={tra}
                      />
                    ))}
                  </SimpleGrid>
                </div>
              </Stack>
            </Grid.Col>
          )
        })}
      </Grid>
    </Container>
  )
}
