import {
  ActionIcon,
  Avatar,
  Badge,
  Divider,
  Group,
  Menu,
  Modal,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import {
  IconCalendarEvent,
  IconClock,
  IconDots,
  IconEdit,
  IconFlag,
  IconNotes,
  IconTrash,
  IconUser,
  IconUserCheck,
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { AgendaItemIndexView } from '~/types/agenda_item'
import Fetcher from '~/utils/fetcher'

import AgendaItemDrawer from './agenda_item_drawer'

const priorityColors: Record<string, string> = {
  high: 'red',
  low: 'blue',
  medium: 'yellow',
}

type AgendaItemDetailProps = {
  agendaItem: AgendaItemIndexView
  isModalOpen: boolean
  onDelete?: () => void
  onEdit?: (agendaItem: AgendaItemIndexView) => void
  onModalClosed: () => void
}

export default function AgendaItemDetail({
  agendaItem,
  isModalOpen,
  onDelete,
  onEdit,
  onModalClosed,
}: AgendaItemDetailProps) {
  const { t: tra } = useTranslation()
  const [
    isAgendaItemDrawerOpen,
    { close: closeAgendaItemDrawer, open: openAgendaItemDrawer },
  ] = useDisclosure(false)

  const deleteAgendaItem = async () => {
    await Fetcher.delete(`/api/v1/agenda_items/${agendaItem.id}`)
  }

  const confirmDelete = () => {
    modals.openConfirmModal({
      labels: { cancel: 'Cancel', confirm: 'Delete' },
      onConfirm: async () => {
        await deleteAgendaItem()
        onDelete?.()
        onModalClosed()
      },
      title: 'Are you sure you want to delete this agenda item?',
    })
  }

  return (
    <Modal
      closeOnClickOutside
      data-testid="agenda-item-detail-modal"
      opened={isModalOpen}
      size="xl"
      withCloseButton={false}
      onClose={onModalClosed}
    >
      <Paper p="md" shadow="sm" withBorder>
        <Stack gap="md">
          <Text size="xl" w={700}>
            {agendaItem.title}
          </Text>
          <Group gap="xs" justify="space-between">
            <Badge
              color={priorityColors[agendaItem.priority]}
              size="lg"
              variant="filled"
            >
              <Group gap="xs">
                <IconFlag size={14} />
                {agendaItem.priority}
              </Group>
            </Badge>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon>
                  <IconDots size={18} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{tra('common:actions')}</Menu.Label>
                <Menu.Item
                  leftSection={<IconEdit size={14} />}
                  onClick={openAgendaItemDrawer}
                >
                  {tra('common:edit')}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} />}
                  onClick={confirmDelete}
                >
                  {tra('common:delete')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          {agendaItem.description && (
            <Group gap="xs" justify="flex-start" wrap="nowrap">
              <IconNotes size={20} />
              <Text>{agendaItem.description}</Text>
            </Group>
          )}

          {agendaItem.meeting_id !== null && (
            <Stack gap="xs">
              <Group gap="xs" wrap="nowrap">
                <IconCalendarEvent size={20} />
                <Text>Created in meeting: {agendaItem.meeting?.title}</Text>
              </Group>
            </Stack>
          )}

          <Group gap="xs">
            <Group gap="xs" wrap="nowrap">
              <IconUser size={20} />
              <Text>Creator: &nbsp;</Text>
              <Avatar
                radius="xl"
                size="sm"
                src={agendaItem.creator.profile_picture_url}
              />
              <Text w={500}>{agendaItem.creator.full_name}</Text>
            </Group>

            <Group gap="xs" wrap="nowrap">
              <IconUserCheck size={20} />
              <Text>Addressee:</Text>
              <Avatar
                radius="xl"
                size="sm"
                src={agendaItem.addressee.profile_picture_url}
              />
              <Text w={500}>{agendaItem.addressee.full_name}</Text>
            </Group>
          </Group>

          <Divider />

          <Group gap="xs" wrap="nowrap">
            <IconClock size={20} />
            <Text c="dimmed" size="sm">
              Created on {dayjs(agendaItem.created_at).format('MMMM DD, YYYY')}{' '}
              at {dayjs(agendaItem.created_at).format('h:mm A')}
            </Text>
          </Group>
        </Stack>
      </Paper>
      {isAgendaItemDrawerOpen && (
        <AgendaItemDrawer
          agendaItem={agendaItem}
          opened={isAgendaItemDrawerOpen}
          onClose={closeAgendaItemDrawer}
          onSuccess={(updatedAgendaItem) => {
            closeAgendaItemDrawer()
            onEdit?.(updatedAgendaItem)
          }}
        />
      )}
    </Modal>
  )
}
