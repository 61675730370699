import { Select } from '@mantine/core'

import { Question } from '~/types/pulse'

interface SingleChoiceQuestionProps {
  handleChange: (id: string, questionLabel: string, value: string) => void
  question: Question
}

function SingleChoiceQuestion(props: SingleChoiceQuestionProps) {
  const { handleChange, question } = props

  return (
    <Select
      data={question.meta.choices}
      label={question.label}
      placeholder="Select an option"
      size="md"
      onChange={(value) =>
        handleChange(question.id!, question.label, value ?? '')
      }
    />
  )
}

export default SingleChoiceQuestion
