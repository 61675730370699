import { PasswordInput, PasswordInputProps } from '@mantine/core'
import { IconEye, IconEyeOff } from '@tabler/icons-react'

function getVisibilityIcon(reveal: boolean) {
  if (reveal) {
    return <IconEyeOff />
  }

  return <IconEye />
}

function VoynPasswordInput(props: PasswordInputProps) {
  return (
    <PasswordInput
      {...props}
      visibilityToggleIcon={({ reveal }) => {
        return getVisibilityIcon(reveal)
      }}
    />
  )
}

export default VoynPasswordInput
