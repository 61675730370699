import { Table, Text } from '@mantine/core'
import React from 'react'

import type { Feedback, FeedbackResponse } from '~/types/feedback'

export default function FeedbackList({
  columns,
  dataTestId,
  feedbacks,
}: {
  columns: Record<string, Record<string, any>>
  dataTestId: string
  feedbacks: Feedback[]
}) {
  const getFeedbackValue = (
    feedback: Feedback,
    response: FeedbackResponse,
    fieldName: string,
    valueFrom: string,
  ): string | React.ReactNode => {
    let feedbackValue: string
    switch (valueFrom) {
      case 'feedback':
        // @ts-ignore
        feedbackValue = fieldName
          .split('.')
          .reduce(
            (acc, key) => acc[key],
            feedback as unknown as { [key: string]: any },
          )
        break
      case 'response':
        // @ts-ignore
        feedbackValue = fieldName
          .split('.')
          .reduce(
            (acc, key) => acc[key],
            response as unknown as { [key: string]: any },
          )
        break
      default:
        throw new Error('Invalid value_from')
    }

    if (columns[fieldName].transform === undefined) {
      return <Text>{feedbackValue}</Text>
    }

    return columns[fieldName].transform(feedbackValue)
  }

  const updateActionHandlers = (
    actions: React.ReactNode,
    feedback: Feedback,
    response: FeedbackResponse,
  ): React.ReactNode => {
    return React.Children.map(actions, (child) => {
      if (React.isValidElement(child)) {
        if (typeof child.props.onClick !== 'function') {
          return updateActionHandlers(child.props.children, feedback, response)
        }

        return React.cloneElement(child, {
          // @ts-ignore
          onClick: () => child.props.onClick()(feedback),
        })
      }
      return child
    })
  }

  const rowActions = columns.actions

  const tableColumns = Object.entries(columns)
    .filter(([k, _]) => k !== 'actions')
    .map(([key, value]) => <Table.Th key={key}>{value.header}</Table.Th>)

  const tableData = feedbacks.flatMap((feedback) =>
    feedback.responses.flatMap((response) => (
      <Table.Tr key={response.id}>
        {Object.entries(columns)
          .filter(([k, _]) => k !== 'actions')
          .map(([key, value]) => (
            <Table.Td key={key}>
              {getFeedbackValue(feedback, response, key, value.value_from)}
            </Table.Td>
          ))}
        {rowActions && (
          <Table.Td>
            {updateActionHandlers(rowActions.actions, feedback, response)}
          </Table.Td>
        )}
      </Table.Tr>
    )),
  )

  return (
    <Table
      data-testid={dataTestId}
      horizontalSpacing="xl"
      striped
      verticalSpacing="md"
      withColumnBorders
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          {tableColumns}
          {rowActions && <Table.Th>{rowActions.header}</Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{tableData}</Table.Tbody>
    </Table>
  )
}
