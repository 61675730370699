import {
  Avatar,
  Button,
  Group,
  Loader,
  Modal,
  Select,
  Stack,
  Table,
  TextInput,
  Title,
  UnstyledButton,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { z } from 'zod'

import adminClasses from '~/layouts/admin.module.css'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const nodeFormSchema = z.object({
  name: z.string().min(1),
  parentId: z.string().uuid().optional(),
})
type NodeFormSchemaType = z.infer<typeof nodeFormSchema>

function EditNodeForm({
  closeModal,
  node,
  nodes,
}: {
  closeModal: () => void
  node: VNode
  nodes: VNode[]
}) {
  const { t: tra } = useTranslation()

  const form = useForm({
    initialValues: {
      name: node.name,
      parentId: node.parent_id!,
    },
    validate: (values) => {
      return validateForm(nodeFormSchema, values)
    },
  })

  const onSubmit = async (formData: NodeFormSchemaType) => {
    const payload = {
      name: formData.name,
      parent_id: formData.parentId,
    }

    await Fetcher.put(`/api/v1/nodes/${node.id}`, payload)
    closeModal()
  }

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack gap="xs">
        <TextInput
          label={tra('admin:nodes:team_name')}
          {...form.getInputProps('name')}
        />
        <Select
          data={nodes.map((n) => ({ label: n.name, value: n.id }))}
          label={tra('admin:nodes:parent_team')}
          {...form.getInputProps('parentId')}
        />
        <Button type="submit">{tra('common:update')}</Button>
      </Stack>
    </form>
  )
}

function CreateNodeForm({
  closeModal,
  nodes,
}: {
  closeModal: () => void
  nodes: VNode[]
}) {
  const { t: tra } = useTranslation()

  const form = useForm({
    initialValues: {
      name: '',
      parentId: '',
    },
    validate: (values) => {
      return validateForm(nodeFormSchema, values)
    },
  })

  const onSubmit = async (formData: NodeFormSchemaType) => {
    const payload = {
      name: formData.name,
      parent_id: formData.parentId,
    }

    await Fetcher.post('/api/v1/nodes', payload)
    closeModal()
  }

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack gap="xs">
        <TextInput
          label={tra('admin:nodes:team_name')}
          {...form.getInputProps('name')}
        />
        <Select
          data={nodes.map((n) => ({ label: n.name, value: n.id }))}
          label={tra('admin:nodes:parent_team')}
          {...form.getInputProps('parentId')}
        />
        <Button type="submit">{tra('common:create')}</Button>
      </Stack>
    </form>
  )
}

function NodesTable({
  nodes,
  openEditModal,
  tra,
}: {
  nodes: VNode[]
  openEditModal: (node: VNode) => void
  tra: TFunction
}) {
  return (
    <Table
      highlightOnHover
      striped
      verticalSpacing="xs"
      withColumnBorders
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{tra('common:firstName').toLocaleUpperCase()}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {nodes.map((node) => (
          <Table.Tr key={node.id}>
            <Table.Td>
              <Group>
                <Avatar>{node.name ? node.name.charAt(0) : '?'}</Avatar>
                <UnstyledButton onClick={() => openEditModal(node)}>
                  {node.name}
                </UnstyledButton>
              </Group>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

function NodesPage() {
  const { t: tra } = useTranslation()
  const [nodeToEdit, setNodeToEdit] = useState<VNode | null>(null)

  const [
    isCreateModalOpened,
    { close: closeCreateModal, open: openCreateModal },
  ] = useDisclosure(false)

  const [isEditModalOpened, { close: closeEditModal, open: openEditModal }] =
    useDisclosure(false)

  const editNode = (node: VNode) => {
    setNodeToEdit(node)
    openEditModal()
  }

  const { data, error, isLoading } = useSWR('/api/v1/nodes', (url) =>
    Fetcher.get<{ include_self: boolean }, VNode[]>(url),
  )

  if (isLoading) return <Loader />

  if (error) return <h1>error</h1>

  return (
    <>
      <Modal
        opened={isCreateModalOpened}
        title={tra('admin:nodes:create')}
        onClose={closeCreateModal}
      >
        <CreateNodeForm closeModal={closeCreateModal} nodes={data ?? []} />
      </Modal>
      {nodeToEdit && (
        <Modal
          opened={isEditModalOpened}
          title={tra('admin:nodes:edit')}
          onClose={closeEditModal}
        >
          <EditNodeForm
            closeModal={closeEditModal}
            node={nodeToEdit as VNode}
            nodes={data ?? []}
          />
        </Modal>
      )}
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('admin:nodes:title')}</Title>
        <Button onClick={openCreateModal}>{tra('admin:nodes:create')}</Button>
      </Group>
      <NodesTable nodes={data ?? []} openEditModal={editNode} tra={tra} />
    </>
  )
}

export default NodesPage
