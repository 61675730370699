import { Button, PasswordInput, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { FaIdBadge, FaIdCard, FaKey } from 'react-icons/fa'
import { useParams, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { z } from 'zod'

import VoynLoadingOverlay from '~/components/shared/loading'
import PageHeader from '~/components/shared/page_header'
import classes from '~/pages/pre-auth/accept-invitation.module.css'
import { Invitation } from '~/types/invitation'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { passwordSchema } from '~/utils/schemas'
import { homePath } from '~/utils/session'

const acceptInvitationFormSchema = z
  .object({
    confirm: passwordSchema,
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    password: passwordSchema,
  })
  .refine((data) => data.password === data.confirm, {
    params: { i18n: 'passwordsDontMatch' },
    path: ['confirm'],
  })
type AcceptInvitationFormSchemaType = z.infer<typeof acceptInvitationFormSchema>

function AcceptInvitationPage() {
  const { invitationId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') as string
  const { t: tra } = useTranslation()

  const { error, isLoading } = useSWR(
    `/api/v1/invitations/${invitationId}`,
    (url) => Fetcher.get<{}, Invitation>(url),
  )

  if (error) {
    window.location.assign('/login')
  }

  const form = useForm({
    initialValues: {
      confirm: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    // Our validateForm method doesn't work since we refine the schema
    validate: zodResolver(acceptInvitationFormSchema),
  })

  const onSubmit = async (formData: AcceptInvitationFormSchemaType) => {
    try {
      await Fetcher.post<
        {
          confirm: string
          first_name: string
          id: string
          last_name: string
          password: string
          token: string
        },
        {}
      >(`/api/v1/invitations/${invitationId}/accept`, {
        confirm: formData.confirm,
        first_name: formData.firstName,
        id: invitationId as string,
        last_name: formData.lastName,
        password: formData.password,
        token,
      })
      window.location.assign(homePath)
    } catch (e) {
      showErrorNotification(e)
    }
  }

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  return (
    <>
      <PageHeader title={tra('acceptInvitation:pageTitle')} />
      <Title order={3}>{tra('acceptInvitation:header')}</Title>
      <form
        className={classes.acceptInvitationForm}
        data-testid="accept-invitation-form"
        onSubmit={form.onSubmit((values) => onSubmit(values))}
      >
        <TextInput
          data-testid="first-name-input"
          label={tra('common:firstName')}
          leftSection={<FaIdBadge />}
          placeholder={tra('common:firstNamePlaceholder')}
          size="md"
          withAsterisk
          {...form.getInputProps('firstName')}
        />
        <TextInput
          data-testid="last-name-input"
          label={tra('common:lastName')}
          leftSection={<FaIdCard />}
          placeholder={tra('common:lastNamePlaceholder')}
          size="md"
          withAsterisk
          {...form.getInputProps('lastName')}
        />
        <PasswordInput
          data-testid="password-input"
          label={tra('common:password')}
          leftSection={<FaKey />}
          placeholder={tra('common:passwordPlaceholder')}
          size="md"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <PasswordInput
          data-testid="password-confirmation-input"
          label={tra('common:passwordConfirmation')}
          leftSection={<FaKey />}
          placeholder={tra('common:passwordPlaceholder')}
          size="md"
          withAsterisk
          {...form.getInputProps('confirm')}
        />
        <Button data-testid="submit-button" size="md" type="submit">
          {tra('acceptInvitation:action')}
        </Button>
      </form>
    </>
  )
}

export default AcceptInvitationPage
