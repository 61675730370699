import { ActionIcon, Button, rem, Stack } from '@mantine/core'
import { DateInput, TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { IconClock } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Meeting } from '~/types/meeting'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const rescheduleMeetingFormSchema = z.object({
  id: z.string().uuid(),
  new_date: z.date(),
  new_time: z.string().time(),
})

type ReschuleMeetingFormSchema = z.infer<typeof rescheduleMeetingFormSchema>

export default function RescheduleMeetingForm({
  meeting,
  onSubmit,
}: {
  meeting: Meeting
  onSubmit: () => void
}) {
  const { t: tra } = useTranslation()
  const currentDate = dayjs(meeting.planned_start_time)
  const form = useForm<ReschuleMeetingFormSchema>({
    initialValues: {
      id: meeting.id,
      new_date: currentDate.toDate(),
      new_time: currentDate.format('HH:mm'),
    },
    validate: (values) => {
      return validateForm(rescheduleMeetingFormSchema, {
        ...values,
        new_time: `${values.new_time}:00`,
      })
    },
  })
  const timeInputRef = useRef<HTMLInputElement>(null)
  const pickerControl = (
    <ActionIcon
      color="gray"
      variant="subtle"
      onClick={() => timeInputRef.current?.showPicker()}
    >
      <IconClock stroke={1.5} style={{ height: rem(16), width: rem(16) }} />
    </ActionIcon>
  )

  const submitForm = async (values: ReschuleMeetingFormSchema) => {
    const [newHour, newMinute] = values.new_time.split(':').map(Number)
    const plannedStartTime = dayjs(values.new_date)
      .set('hour', newHour)
      .set('minute', newMinute)
    await Fetcher.put(`/api/v1/meetings/${meeting.id}`, {
      meeting: { planned_start_time: plannedStartTime },
    })
    onSubmit()
  }

  return (
    <form
      data-testid="reschedule-meeting-form"
      onSubmit={form.onSubmit(submitForm)}
    >
      <Stack gap="lg">
        <DateInput
          data-testid="new-date-input"
          label={tra('common:new_date')}
          {...form.getInputProps('new_date')}
        />
        <TimeInput
          data-testid="new-time-input"
          label={tra('common:new_time')}
          ref={timeInputRef}
          rightSection={pickerControl}
          {...form.getInputProps('new_time')}
        />
        <Button data-testid="submit-button" type="submit">
          {tra('common:reschedule')}
        </Button>
      </Stack>
    </form>
  )
}
