import { z } from 'zod'

export const emailSchema = z
  .string({ required_error: 'emailRequired' })
  .email('emailInvalid')

export const passwordSchema = z
  .string({ required_error: 'passwordRequired' })
  .min(8, 'passwordShort')

export const uuidSchema = z.string().uuid()

export const agendaItemFormSchema = z.object({
  addresseeId: z.string({
    invalid_type_error: 'addresseeIdRequired',
    required_error: 'addresseeIdRequired',
  }),
  description: z.string().nullable(),
  priority: z.string(),
  title: z.string().min(1, 'agendaItemTitleTooShort'),
})

const questionSchema = z.object({
  label: z.string(),
  meta: z.object({
    choices: z.array(z.string()),
    element: z.enum(['radiobox', 'checkbox', 'dropdown', 'textarea']),
  }),
  order: z.number(),
  question_type: z.enum([
    'free_text',
    'multiple_choice',
    'single_choice',
    'sentiment',
  ]),
})

export const newPulseFormSchema = z.object({
  nodes: z.array(z.any()), // to be fixed
  occurrence: z.object({
    day: z.string().optional(),
    frequency: z.string().optional(),
    time: z.string(),
  }),
  questions: z.array(questionSchema).min(1),
  title: z.string().min(5, { message: 'pulseTitleTooShort' }),
})

export const newCheckinFormSchema = z.object({
  nodes: z.array(z.any()), // to be fixed
  occurrence: z.object({
    day: z.string().optional(),
    frequency: z.string().optional(),
    time: z.string(),
  }),
  question: z.string().min(5, { message: 'pulseTitleTooShort' }),
})

export const newCheckinResponseFormSchema = z.object({
  response: z.string().min(20, { message: 'checkinResponseTooShort' }),
})

export const feedbackFormSchema = z.object({
  feedback_request_id: z.string().uuid(),
  is_draft: z.boolean().default(false),
  receiver_id: z.string().uuid(),
  responses_attributes: z
    .array(
      z
        .object({
          question: z.string(),
          response: z.string().min(5, { message: 'feedbackResponseTooShort' }),
        })
        .required(),
    )
    .min(1),
})

export const editFeedbackFormSchema = z.object({
  is_draft: z.boolean().default(false),
  responses_attributes: z
    .array(
      z
        .object({
          id: z.string().uuid(),
          question: z.string(),
          response: z.string().min(5, { message: 'feedbackResponseTooShort' }),
        })
        .required(),
    )
    .min(1),
})
