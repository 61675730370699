import classes from './header.module.css'

type HeaderProps = {
  children: React.ReactNode
}

function Header({ children }: HeaderProps) {
  return <div className={classes.header}>{children}</div>
}

export default Header
