import { Container, Group, rem } from '@mantine/core'
import {
  IconChefHat,
  IconClockCheck,
  IconHeartbeat,
  IconHierarchy2,
  IconMoneybag,
  IconPlug,
  IconStackPop,
  IconUsers,
} from '@tabler/icons-react'
import { Outlet } from 'react-router-dom'

import SecondNav, { NavItem } from '~/components/dashboard/second_nav'
import { useSession } from '~/utils/session'

const subNavItems = [
  { i18nKey: 'Integrations', icon: <IconPlug />, url: 'integrations' },
  { i18nKey: 'Pulses', icon: <IconHeartbeat />, url: 'pulses' },
  { i18nKey: 'Checkins', icon: <IconClockCheck />, url: 'checkins' },
  { i18nKey: 'Users', icon: <IconUsers />, url: 'users' },
  { i18nKey: 'Departments', icon: <IconHierarchy2 />, url: 'nodes' },
  { i18nKey: 'Job Functions', icon: <IconChefHat />, url: 'job_functions' },
  { i18nKey: 'Job Levels', icon: <IconStackPop />, url: 'job_levels' },
  { i18nKey: 'Compensation', icon: <IconMoneybag />, url: 'compensations' },
] as NavItem[]

function AdminLayout() {
  return (
    <Group className="fullHeight">
      <SecondNav items={subNavItems} title="Settings" />
      <Container className="yScroll" fluid h="100%" pl={rem(192)} w="100%">
        <Outlet context={useSession()} />
      </Container>
    </Group>
  )
}

export default AdminLayout
