import {
  Avatar,
  AvatarGroup,
  Center,
  Divider,
  Paper,
  rem,
  Text,
  Title,
} from '@mantine/core'
import { IconBuilding, IconUser, IconUsers } from '@tabler/icons-react'

import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import { UserProfileView } from '~/types/user'

type MeetingUserInfoProps = {
  userProfile: UserProfileView
}

const iconStyle = {
  height: rem(12),
  width: rem(12),
}

function MeetingUserInfo({ userProfile }: MeetingUserInfoProps) {
  console.log('userProfile', userProfile)
  if (userProfile) {
    return (
      <>
        <Title order={4}>Profile</Title>
        <Divider />
        <Paper mt="sm" p="sm" withBorder>
          <Center>
            <Avatar size="xl" src={userProfile.profile_picture_url} />
          </Center>
          <Title mt="sm" order={4} ta="center">
            {userProfile.full_name}
          </Title>
          <Text c="gray" ta="center">
            {userProfile.title}
          </Text>
          <Text c="gray" fz="sm">
            <IconBuilding style={iconStyle} /> Department
          </Text>
          <Text>
            {userProfile.nodes
              .map((node) => (node.name ? node.name : ''))
              .join(', ')}
          </Text>
          <Text c="gray" fz="sm">
            <IconUser style={iconStyle} /> Manager
          </Text>
          <Text>
            {userProfile.direct_manager
              ? userProfile.direct_manager.full_name
              : '-'}
          </Text>
          <Text c="gray" fz="sm">
            <IconUsers style={iconStyle} /> Direct reports
          </Text>
          <AvatarGroup>
            {userProfile.direct_reports &&
              userProfile.direct_reports.map((report) => (
                <LabeledAvatar size="sm" user={report} />
              ))}
          </AvatarGroup>
        </Paper>
      </>
    )
  }

  return null
}

export default MeetingUserInfo
