/**
 * Tiptap expects a DOM element for collaboration cursor, that's the reason of having
 * this not as a React component.
 */
export type CollaborationUser = {
  avatar: string
  color: string
  name: string
}

export default function createCollaborationCursor(
  user: any,
  classes: Record<any, string>,
): HTMLElement {
  const cursor = document.createElement('span')

  cursor.classList.add(classes.collaborationCaret)
  cursor.setAttribute('style', `border-color: ${user.color}`)

  const label = document.createElement('div')

  label.classList.add(classes.collaborationLabel)
  label.setAttribute('style', `background-color: ${user.color}`)
  const avatarImg = document.createElement('img')
  avatarImg.src = user.avatar // Assuming 'user.avatar' contains the URL of the avatar
  avatarImg.classList.add(classes.collaborationAvatar) // Add a class for styling
  avatarImg.style.borderRadius = '50%' // Make the image round
  avatarImg.style.width = '12px' // Set the size of the avatar (adjust as needed)
  avatarImg.style.height = '12px'
  avatarImg.style.marginRight = '4px' // Optional: add some margin
  label.appendChild(avatarImg)
  label.insertBefore(document.createTextNode(user.name), null)
  cursor.insertBefore(label, null)

  return cursor
}
