import {
  Button,
  Combobox,
  Modal,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { TFunction } from 'i18next'
import { useEffect } from 'react'
import { z } from 'zod'

import { UserProfileView } from '~/types/user'
import { arrayRange, combinations } from '~/utils/arrays'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const

const DURATIONS = ['15_mins', '30_mins', '45_mins', '60_mins'] as const

const RECURRENCES = [
  'daily',
  'weekly',
  'biweekly',
  'monthly',
  'annual',
] as const

type OneOnOneSettingsModalProps = {
  onClose: () => void
  opened: boolean
  tra: TFunction
  user: UserProfileView
}

const oneOnOneSettingsSchema = z
  .object({
    day: z.enum(DAYS as unknown as [string, ...string[]]),
    duration: z.enum(DURATIONS as unknown as [string, ...string[]]),
    recurrence: z.enum(RECURRENCES as unknown as [string, ...string[]]),
    time: z.string(),
    user_id: z.string().uuid().optional(),
  })
  .required({ duration: true, recurrence: true, time: true })

type OneOnOneSettingsFormSchema = z.infer<typeof oneOnOneSettingsSchema>

export default function OneOnOneSettingsModal(
  props: OneOnOneSettingsModalProps,
) {
  const { onClose, opened, tra, user } = props
  const form = useForm({
    initialValues: {
      day: 'monday',
      duration: '30_mins',
      initial_date: '',
      recurrence: 'biweekly',
      time: '14:00',
    } as OneOnOneSettingsFormSchema,
    validate: (values) => {
      return validateForm(oneOnOneSettingsSchema, values)
    },
  })
  const timeBox = useCombobox()

  useEffect(() => {
    Object.entries(user.one_on_one_settings || []).forEach(([key, value]) => {
      form.setFieldValue(key, value)
    })
    form.setFieldValue('user_id', user.id)
  }, [user])

  const saveSettings = async () => {
    try {
      const fetcherMethod =
        user.one_on_one_settings?.id === undefined ? 'post' : 'put'

      await Fetcher[fetcherMethod]<
        { one_on_one_settings: OneOnOneSettingsFormSchema },
        OneOnOneSettingsFormSchema
      >('/api/v1/one_on_one_settings', {
        one_on_one_settings: form.values,
      })

      onClose()
      window.location.reload() // TODO: remove this and actually update the user profile or send a request already
    } catch (e) {
      showErrorNotification(e)
    }
  }

  const title = tra('oneOnOneSettings:title', {
    name: user.first_name,
  })

  const recurrences = RECURRENCES.map((rec) => ({
    label: tra(`common:${rec}`),
    value: rec,
  }))

  const days = DAYS.map((day) => ({
    label: tra(`calendar:${day}`),
    value: day,
  }))

  const durations = DURATIONS.map((rec) => ({
    label: tra(`common:${rec}`),
    value: rec,
  }))

  const timeOptions = combinations(arrayRange(8, 18), [0, 15, 30, 45])

  return (
    <Modal opened={opened} title={<strong>{title}</strong>} onClose={onClose}>
      <Stack>
        <Select
          data={recurrences}
          description={tra('oneOnOneSettings:recurrenceDescription')}
          inputWrapperOrder={['label', 'error', 'input', 'description']}
          label={tra('oneOnOneSettings:recurrence')}
          value={form.values.recurrence}
          onChange={(value) => form.setFieldValue('recurrence', value!)}
        />
        {form.values.recurrence !== 'daily' && (
          <Select
            data={days}
            description={tra('oneOnOneSettings:dayDescription')}
            inputWrapperOrder={['label', 'error', 'input', 'description']}
            label={tra('oneOnOneSettings:day')}
            value={form.values.day}
            onChange={(value) => form.setFieldValue('day', value!)}
          />
        )}
        <Select
          data={durations}
          description={tra('oneOnOneSettings:durationDescription')}
          inputWrapperOrder={['label', 'error', 'input', 'description']}
          label={tra('oneOnOneSettings:duration')}
          value={form.values.duration}
          onChange={(value) => form.setFieldValue('duration', value!)}
        />
        <Combobox
          store={timeBox}
          onOptionSubmit={(optionValue) => {
            form.setFieldValue('time', optionValue)
            timeBox.closeDropdown()
          }}
        >
          <Combobox.Target>
            <TextInput
              label={tra('oneOnOneSettings:time')}
              placeholder={tra('oneOnOneSettings:time')}
              value={form.values.time}
              onChange={(event) => {
                form.setFieldValue('time', event.currentTarget.value)
                timeBox.openDropdown()
                timeBox.updateSelectedOptionIndex()
              }}
              onClick={() => timeBox.openDropdown()}
              onFocus={() => timeBox.openDropdown()}
            />
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
              {timeOptions.map(([hour, minute]) => {
                const fixedHour = hour.toString().padStart(2, '0')
                const fixedMinute = minute.toString().padStart(2, '0')
                const timeOption = `${fixedHour}:${fixedMinute}`

                return (
                  <Combobox.Option key={timeOption} value={timeOption}>
                    {timeOption}
                  </Combobox.Option>
                )
              })}
            </Combobox.Options>
          </Combobox.Dropdown>
          <Text color="dimmed" size="xs" style={{ marginTop: -11 }}>
            {tra('oneOnOneSettings:timeDescription')}
          </Text>
        </Combobox>
      </Stack>
      <Space h="md" />
      <Button size="md" type="submit" onClick={() => saveSettings()}>
        {tra('common:save')}
      </Button>
    </Modal>
  )
}
