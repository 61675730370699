import { Paper, Stack, Text } from '@mantine/core'
import { Tree, TreeNode } from 'react-organizational-chart'

import { UserOrgChartView } from '~/types/user'
import { useSession } from '~/utils/session'

function OrgChartNode({ user }: { user: UserOrgChartView }) {
  return (
    <TreeNode
      label={
        <Paper p="sm" withBorder>
          <Stack gap="0">
            <Text>
              {user.full_name} {user.email}
            </Text>
            <Text c="gray" fz="sm">
              {user.title}
            </Text>
          </Stack>
        </Paper>
      }
    >
      {user.direct_reports?.map((report) => (
        <OrgChartNode key={report.id} user={report} />
      ))}
    </TreeNode>
  )
}

function OrgChartContainer({ users }: { users: UserOrgChartView[] }) {
  const { tenant } = useSession()

  return (
    <Tree label={tenant.name}>
      <OrgChartNode user={users[0]} />
    </Tree>
  )
}

export default OrgChartContainer
