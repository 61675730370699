/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Button,
  Center,
  Group,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import {
  IconCalendarPlus,
  IconChevronLeft,
  IconMessagePlus,
} from '@tabler/icons-react'
import i18next from 'i18next'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import AgendaItemForm from '~/components/dashboard/agenda_item_form'
import classes from '~/components/dashboard/new_action_card_button.module.css'
import NewMeetingForm from '~/components/dashboard/new_meeting_form'
import { Session } from '~/utils/session'

function BackButton({ session }: { session: Session }) {
  return (
    <Center>
      <Button
        color="gray"
        leftSection={<IconChevronLeft />}
        mt="sm"
        variant="subtle"
        w="fit-content"
        onClick={() => openNewActionModal(session)}
      >
        {i18next.t('common:back')}
      </Button>
    </Center>
  )
}

function ActionCardButton({
  color,
  description,
  icon,
  onClick,
  title,
}: {
  color: string
  description: string
  icon: ReactNode
  onClick: () => void
  title: string
}) {
  return (
    <UnstyledButton
      className={classes.actionCardButton}
      size="xl"
      onClick={onClick}
    >
      <Group>
        <ThemeIcon color={color} radius="lg">
          {icon}
        </ThemeIcon>
        <Stack gap="0">
          <Text c="black">{title}</Text>
          <Text c="gray" fz="sm">
            {description}
          </Text>
        </Stack>
      </Group>
    </UnstyledButton>
  )
}

function openNewAgendaItemModal(session: Session) {
  modals.open({
    children: (
      <>
        <AgendaItemForm
          onSuccess={() => {
            modals.closeAll()
          }}
        />
        <BackButton session={session} />
      </>
    ),
    onClose: () => modals.closeAll(),
    title: i18next.t('actionModal:newTalkingPoint'),
  })
}

export function openNewMeetingModal(session: Session, attendeeIds?: string[]) {
  modals.open({
    children: (
      <>
        <NewMeetingForm
          attendeeIds={attendeeIds}
          session={session}
          onSuccess={(meeting) =>
            window.location.assign(`/dashboard/meetings/${meeting.id}`)
          }
        />
        <BackButton session={session} />
      </>
    ),
    onClose: () => modals.closeAll(),
    title: i18next.t('actionModal:newMeeting'),
  })
}

function NewActionModal({ session }: { session: Session }) {
  const { t: tra } = useTranslation()

  return (
    <Stack>
      <ActionCardButton
        color="brand"
        description={tra('actionModal:newTalkingPointDescription')}
        icon={<IconMessagePlus size="1rem" />}
        title={tra('actionModal:newTalkingPoint')}
        onClick={() => openNewAgendaItemModal(session)}
      />
      <ActionCardButton
        color="blue"
        description={tra('actionModal:newMeetingDescription')}
        icon={<IconCalendarPlus size="1rem" />}
        title={tra('actionModal:newMeeting')}
        onClick={() => openNewMeetingModal(session)}
      />
    </Stack>
  )
}

export default function openNewActionModal(session: Session) {
  modals.open({
    children: <NewActionModal session={session} />,
    onClose: () => modals.closeAll(),
    title: i18next.t('actionModal:modalTitle'),
  })
}
