import { Loader } from '@mantine/core'
import useSWRImmutable from 'swr/immutable'

import AgendaItemCard from '~/components/dashboard/agenda_item_card'
import { AgendaItemIndexView } from '~/types/agenda_item'
import {
  AgendaItemFilter,
  AgendaItemFilterPayload,
} from '~/utils/agenda_item_filter'
import Fetcher from '~/utils/fetcher'

function TalkingPointsTab(
  props: AgendaItemFilter & {
    currentMeetingId?: string
  },
) {
  const { addresseeId, completed, creatorId, currentMeetingId, meetingId } =
    props

  const {
    data: agendaItems,
    error,
    isLoading,
    mutate,
  } = useSWRImmutable(
    ['/api/v1/agenda_items', addresseeId, completed, creatorId, meetingId],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([url, addresseeId, completed, creatorId, meetingId]) => {
      const payload: AgendaItemFilterPayload = {}
      if (addresseeId) {
        payload.addressee_id = addresseeId
      }
      if (completed !== null) {
        payload.completed = completed
      }
      if (creatorId) {
        payload.creator_id = creatorId
      }
      if (meetingId !== undefined) {
        payload.meeting_id = meetingId
      }

      return Fetcher.get<AgendaItemFilter, AgendaItemIndexView[]>(url, payload)
    },
  )

  if (isLoading) return <Loader />

  if (error) return <h1>ERROR</h1>

  if (agendaItems) {
    return (
      <>
        {agendaItems.map((agendaItem) => (
          <AgendaItemCard
            addToAgendaCb={() => {
              mutate(agendaItems.filter((ai) => ai.id !== agendaItem.id))
              console.log('mutating')
            }}
            agendaItem={agendaItem}
            currentMeetingId={currentMeetingId}
            key={agendaItem.id}
          />
        ))}
      </>
    )
  }

  return null
}

export default TalkingPointsTab
