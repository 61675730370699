import { Flex, Image, Paper } from '@mantine/core'
import { Outlet } from 'react-router-dom'

import Logo from '~/images/logo.png'
import classes from '~/layouts/pre-auth-form.module.css'

function PreAuthFormLayout() {
  return (
    <Flex className={classes.preAuthContainer}>
      <Image alt="Login Image" fit="contain" mb="md" src={Logo} w={160} />
      <Paper className={classes.preAuthContent}>
        <Outlet />
      </Paper>
    </Flex>
  )
}

export default PreAuthFormLayout
