import { HocuspocusProvider } from '@hocuspocus/provider'
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Group,
  List,
  Paper,
  rem,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconCalendar, IconCalendarCheck, IconLock } from '@tabler/icons-react'
import { BubbleMenu, Editor, EditorContent, FloatingMenu } from '@tiptap/react'
import { useEffect, useState } from 'react'
import * as Y from 'yjs'

import Header from '~/components/dashboard/header'
import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import MeetingAgenda from '~/components/dashboard/meeting_agenda'
import MeetingTimer from '~/components/dashboard/meeting_timer'
import createEditor from '~/components/dashboard/tiptap/editor_factory'
import TodoDrawer from '~/components/dashboard/todo_drawer'
import classes from '~/pages/dashboard/ongoing_meeting.module.css'
import { AgendaItemIndexView } from '~/types/agenda_item'
import { MeetingDetailView } from '~/types/meeting'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'
import useSocket from '~/utils/use_socket'

function OngoingMeetingPage(props: { meetingDetail: MeetingDetailView }) {
  const { meetingDetail } = props
  const [isTodoDrawerOpened, { close: closeTodoDrawer, open: openTodoDrawer }] =
    useDisclosure(false)
  const { user: currentUser } = useSession()
  const initialActiveItem = meetingDetail.agenda_items.find(
    (i) => i.id === meetingDetail.active_agenda_item_id,
  )
  const [
    isPrivateNotesOpened,
    { close: closePrivateNotes, open: openPrivateNotes },
  ] = useDisclosure(false)

  const [editorMap, setEditorMap] = useState<Record<string, Editor>>({})

  const meetingId = meetingDetail.id
  const [activeItem, setActiveItem] = useState<AgendaItemIndexView>(
    initialActiveItem || meetingDetail.agenda_items[0],
  )

  useEffect(() => {
    const doc = new Y.Doc()
    const provider = new HocuspocusProvider({
      document: doc,
      name: meetingId,
      // TODO: Replace this
      token: 'notoken',
      url: import.meta.env.VITE_HOCUSPOCUS_URL,
    })

    const newMap: Record<string, Editor> = {}
    console.log('meetingDetail', meetingDetail.attendees)
    meetingDetail.agenda_items.forEach((item) => {
      const editor = createEditor({
        agendaItem: item,
        cssClasses: classes,
        doc,
        meetingUsers: [meetingDetail.creator, ...meetingDetail.attendees],
        openTodoDrawer,
        provider,
        user: currentUser,
      })
      newMap[item.id] = editor
      setEditorMap(newMap)
    })

    return () => {
      // Destroy all the editors, provider (that will close the WebSocket connection) and the YDoc.
      Object.keys(editorMap).forEach((koko) => editorMap[koko].destroy())
      provider.destroy()
      doc.destroy()
    }
  }, [])

  const setNextItem = async (itemId?: string) => {
    try {
      await Fetcher.post<
        { agenda_item_id: string | undefined },
        AgendaItemIndexView
      >(`/api/v1/meetings/${meetingId}/next_item`, {
        agenda_item_id: itemId,
      })
      // setActiveItem(response)
    } catch (e) {
      showErrorNotification(e)
    }
  }

  const endMeeting = async () => {
    try {
      await Fetcher.post(`/api/v1/meetings/${meetingId}/finish`)
    } catch (e) {
      showErrorNotification(e)
    }
  }

  useSocket({
    channelName: 'OngoingMeetingChannel',
    onDataReceived: (data) => {
      if (data.previous_item) {
        const prevItem = meetingDetail.agenda_items.find(
          (item) => item.id === data.previous_item.id,
        )

        if (prevItem) {
          prevItem.completed_at = data.previous_item.completed_at
        }
      }
      setActiveItem(data.next_item)
    },
    roomId: meetingId,
    roomName: 'ongoing_meeting',
  })

  if (!activeItem || !activeItem.id || Object.keys(editorMap).length <= 0) {
    return <h1>Meeting ended</h1>
  }

  const checkRemainingItems = () => {
    const incompleteItems = meetingDetail.agenda_items.filter(
      (item) => !item.completed_at,
    )

    if (incompleteItems.length > 0) {
      modals.openConfirmModal({
        children: (
          <>
            <Text size="sm">
              There are still <strong>{incompleteItems.length}</strong> items
              left. Are you sure?
            </Text>
            <List mt="sm" size="sm">
              {incompleteItems.map((item) => (
                <List.Item key={item.id}>
                  <strong>{item.creator.full_name}: </strong>
                  {item.title}
                </List.Item>
              ))}
            </List>
          </>
        ),
        labels: { cancel: 'Cancel', confirm: 'End meeting' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => endMeeting(),
        title: 'Please confirm your action',
      })
    } else {
      endMeeting()
    }
  }

  return (
    <div className={classes.container}>
      <Drawer
        opened={isPrivateNotesOpened}
        position="right"
        title="Private notes"
        onClose={closePrivateNotes}
      >
        <Text>Private notes</Text>
      </Drawer>
      <Header>
        <Group justify="space-between">
          <Group gap="xs">
            <Title order={3}>{meetingDetail.title}</Title>
            <Avatar.Group>
              {meetingDetail.attendees.map((user) => (
                <LabeledAvatar key={user.id} size="sm" user={user} />
              ))}
            </Avatar.Group>
            <Badge
              leftSection={
                <IconCalendar style={{ height: rem(12), width: rem(12) }} />
              }
              variant="outline"
            >
              {meetingDetail.planned_start_time}
            </Badge>
            {meetingDetail.actual_start_time && (
              <MeetingTimer
                duration={meetingDetail.duration}
                initialTime={meetingDetail.actual_start_time}
              />
            )}
          </Group>
          <Button
            leftSection={<IconCalendarCheck />}
            variant="outline"
            onClick={checkRemainingItems}
          >
            End meeting
          </Button>
        </Group>
      </Header>
      <Group className={classes.content}>
        <Stack className={classes.left} flex={1} h="100%" maw={rem(300)} p="sm">
          <Title order={4}>Agenda</Title>
          <MeetingAgenda
            activeItemId={activeItem.id}
            agendaItems={meetingDetail.agenda_items}
            goTo={setNextItem}
          />
        </Stack>
        <Stack
          flex={2}
          gap="0"
          h="100%"
          pr="md"
          py="sm"
          style={{ overflowY: 'auto' }}
        >
          <Stack gap="0">
            <Group justify="space-between">
              <Title mb="sm" order={4}>
                Current talking point
              </Title>
              <Button
                color="gray"
                leftSection={<IconLock />}
                variant="transparent"
                onClick={openPrivateNotes}
              >
                Private notes
              </Button>
            </Group>
            <Group align="start" mb="sm">
              <Stack align="center" gap="0">
                <LabeledAvatar user={activeItem.creator} />
                <Text>{activeItem.creator.first_name}</Text>
              </Stack>
              <Paper className={classes.conversation} component="span">
                <Text>{activeItem.title}</Text>
                <Text c="gray" fz="sm">
                  {activeItem.description}
                </Text>
              </Paper>
            </Group>
            <Title order={4}>Shared notes</Title>
          </Stack>
          <FloatingMenu
            editor={editorMap[activeItem.id]}
            tippyOptions={{ duration: 100, placement: 'bottom' }}
          >
            <Button
              className={
                editorMap[activeItem.id].isActive('heading', { level: 1 })
                  ? 'is-active'
                  : ''
              }
              onClick={() =>
                editorMap[activeItem.id]
                  .chain()
                  .focus()
                  .toggleHeading({ level: 1 })
                  .run()
              }
            >
              h1
            </Button>
            <Button
              className={
                editorMap[activeItem.id].isActive('heading', { level: 2 })
                  ? 'is-active'
                  : ''
              }
              onClick={() =>
                editorMap[activeItem.id]
                  .chain()
                  .focus()
                  .toggleHeading({ level: 2 })
                  .run()
              }
            >
              h2
            </Button>
            <Button
              className={
                editorMap[activeItem.id].isActive('bulletList')
                  ? 'is-active'
                  : ''
              }
              onClick={() =>
                editorMap[activeItem.id]
                  .chain()
                  .focus()
                  .toggleBulletList()
                  .run()
              }
            >
              bullet list
            </Button>
            <Button
              className={
                editorMap[activeItem.id].isActive('bulletList')
                  ? 'is-active'
                  : ''
              }
              onClick={() =>
                editorMap[activeItem.id].chain().focus().openTodoDrawer().run()
              }
            >
              Todo
            </Button>
          </FloatingMenu>
          <BubbleMenu
            editor={editorMap[activeItem.id]}
            tippyOptions={{ duration: 100 }}
          >
            <Button
              className={
                editorMap[activeItem.id].isActive('bold') ? 'is-active' : ''
              }
              onClick={() =>
                editorMap[activeItem.id].chain().focus().toggleBold().run()
              }
            >
              bold
            </Button>
            <Button
              className={
                editorMap[activeItem.id].isActive('italic') ? 'is-active' : ''
              }
              onClick={() =>
                editorMap[activeItem.id].chain().focus().toggleItalic().run()
              }
            >
              italic
            </Button>
            <Button
              className={
                editorMap[activeItem.id].isActive('strike') ? 'is-active' : ''
              }
              onClick={() =>
                editorMap[activeItem.id].chain().focus().toggleStrike().run()
              }
            >
              strike
            </Button>
          </BubbleMenu>
          <EditorContent
            className={classes.editorContent}
            editor={editorMap[activeItem.id]}
          />
          <Button
            mih="36px"
            mt="sm"
            w="fit-content"
            onClick={() => setNextItem()}
          >
            Next item
          </Button>
        </Stack>
      </Group>
      {isTodoDrawerOpened && (
        <TodoDrawer
          agendaItemId={activeItem.id}
          opened={isTodoDrawerOpened}
          users={[...meetingDetail.attendees]}
          onClose={closeTodoDrawer}
          onSuccess={async (todo) => {
            editorMap[activeItem.id]
              .chain()
              .insertContent([
                {
                  attrs: {
                    id: todo.id,
                  },
                  type: 'todoCard',
                },
                { type: 'paragraph' },
                { type: 'paragraph' },
              ])
              .focus()
              .run()
            closeTodoDrawer()
          }}
        />
      )}
    </div>
  )
}

export default OngoingMeetingPage
