import { Badge, Group, Paper, Stack, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconClock, IconSquare, IconTextCaption } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import classes from '~/components/dashboard/todo_card.module.css'
import TodoDetail from '~/components/dashboard/todo_detail'
import { Todo } from '~/types/todo'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

const priorityColors = {
  high: 'red',
  low: 'blue',
  medium: 'orange',
}

type TodoCardProps = {
  todo: Todo
}

export default function TodoCard(props: TodoCardProps) {
  const { todo } = props
  const { t: tra } = useTranslation()
  const [todoDetail, setTodoDetail] = useState<Todo>(todo)
  const [isModalOpen, { close: closeDetailModal, open: openDetailModal }] =
    useDisclosure(false)

  const updateTodoStatus = async (status: string) => {
    try {
      const response = await Fetcher.put<{ status: string }, Todo>(
        `/api/v1/todos/${todo.id}`,
        {
          status,
        },
      )
      setTodoDetail(response)
    } catch (e) {
      showErrorNotification(tra('common:something_went_wrong'))
    }
  }

  return (
    <>
      <Paper
        className={classes.todoCard}
        data-testid="todo-card"
        mt="sm"
        p="sm"
        withBorder
        onClick={openDetailModal}
      >
        <Stack gap="xs">
          <Group align="center" gap="0">
            <IconSquare color="gray" size="1rem" />
            <Text ml="0.25rem" truncate="end">
              {todo.title}
            </Text>
          </Group>
          <Group align="flex-end" gap="0" justify="space-between">
            <Group gap="xs">
              {todo.description && (
                <Tooltip label={tra('todoCard:hasDescription')}>
                  <IconTextCaption
                    color="gray"
                    data-testid="todo-card-description-icon"
                    size="1rem"
                  />
                </Tooltip>
              )}
              {todo.due_date && (
                <Tooltip label={dayjs(todo.due_date).format('D MMMM YYYY')}>
                  <Badge
                    color="blue"
                    data-testid="todo-card-due-date"
                    leftSection={<IconClock size={12} />}
                    variant="light"
                  >
                    {dayjs(todo.due_date).format('MMM D')}
                  </Badge>
                </Tooltip>
              )}
              {todo.priority && (
                <Badge
                  color={priorityColors[todo.priority]}
                  data-testid="todo-card-priority"
                  variant="light"
                >
                  {tra(`todoPriorities:${todo.priority}`)}
                </Badge>
              )}
            </Group>
            <LabeledAvatar
              data-testid="todo-card-assignee-avatar"
              size="sm"
              user={todo.assignee}
            />
          </Group>
        </Stack>
      </Paper>
      {isModalOpen && (
        <TodoDetail
          isModalOpen={isModalOpen}
          todo={todoDetail}
          onDelete={() => {}}
          onEdit={(updatedTodo) => setTodoDetail(updatedTodo)}
          onModalClosed={closeDetailModal}
          onStatusChange={updateTodoStatus}
        />
      )}
    </>
  )
}
