import { rem } from '@mantine/core'
import {
  createSpotlight,
  Spotlight,
  SpotlightActionData,
  SpotlightActionGroupData,
} from '@mantine/spotlight'
import { IconSearch } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

import { useSession } from '~/utils/session'

import { openNewMeetingModal } from './new_action_modal'

export const [searchStore, searchHandlers] = createSpotlight()

export function VoynSpotlight() {
  const navigate = useNavigate()
  const session = useSession()
  const actions: (SpotlightActionGroupData | SpotlightActionData)[] = [
    {
      actions: [
        {
          description: "See what's happening within your team",
          id: 'myTeam',
          label: 'My Team',
          onClick: () => {
            navigate('/dashboard/team')
          },
        },
        {
          description: 'Get an overview of blabla',
          id: 'home',
          label: 'Home',
          onClick: () => {
            navigate('/dashboard/home')
          },
        },
      ],
      group: 'Navigation',
    },
    {
      actions: [],
      group: 'Direct reports',
    },
    {
      actions: [
        {
          description: 'Create or schedule instant meeting',
          id: 'createMeeting',
          label: 'Create meeting',
          onClick: () => {
            openNewMeetingModal(session)
          },
        },
      ],
      group: 'Actions',
    },
  ]
  return (
    <Spotlight
      actions={actions}
      clearQueryOnClose
      highlightQuery
      limit={7}
      nothingFound="Nothing found..."
      radius="md"
      searchProps={{
        leftSection: <IconSearch style={{ height: rem(20), width: rem(20) }} />,
        placeholder: 'Search documentation...',
      }}
      shortcut={['mod + K']}
      store={searchStore}
      tagsToIgnore={[]}
    />
  )
}
