import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { SWRConfig } from 'swr'

import { setupI18N } from '~/utils/i18n'
import theme from '~/utils/theme'
import VoynRoutes from '~/voyn_routes'

setupI18N()
const rootElement = document.getElementById('root')

if (rootElement) {
  const root = createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <MantineProvider theme={theme}>
          <ModalsProvider>
            <Notifications position="top-right" />
            <SWRConfig />
            <VoynRoutes />
          </ModalsProvider>
        </MantineProvider>
      </HelmetProvider>
    </React.StrictMode>,
  )
}
