import { Stack, Text, Title } from '@mantine/core'
import { ReactNode } from 'react'

import classes from '~/components/shared/information_right_pane.module.css'

type InformationProps = {
  button?: ReactNode
  description: string
  heading: string
  image: ReactNode
}

function InformationRightPane({
  button,
  description,
  heading,
  image,
}: InformationProps) {
  return (
    <Stack className={classes.informationContainer}>
      {image}
      <Title order={3}>{heading}</Title>
      <Text mb="md">{description}</Text>
      {button}
    </Stack>
  )
}

export default InformationRightPane
