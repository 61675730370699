import { Button, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { FaAt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import PageHeader from '~/components/shared/page_header'
import classes from '~/pages/pre-auth/forgot-password.module.css'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { getCurrentLanguageCode } from '~/utils/i18n'
import { emailSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

const forgotPasswordFormSchema = z.object({
  email: emailSchema,
})
type ForgotPasswordFormSchemaType = z.infer<typeof forgotPasswordFormSchema>

function ForgotPasswordPage() {
  const { t: tra } = useTranslation()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      return validateForm(forgotPasswordFormSchema, values)
    },
  })

  const onSubmit = async (formData: ForgotPasswordFormSchemaType) => {
    const payload = {
      email: formData.email.toLowerCase(),
    }

    try {
      await Fetcher.post<ForgotPasswordFormSchemaType, {}>(
        '/api/v1/passwords/send_reset_instructions',
        payload,
      )

      const currentLanguageCode = getCurrentLanguageCode()
      navigate(`/login?lang=${currentLanguageCode}&forgot_password=true`)
    } catch (e) {
      showErrorNotification(e)
    }
  }

  return (
    <>
      <PageHeader title={tra('forgotPassword:resetYourPassword')} />
      <Title order={3}>{tra('forgotPassword:resetYourPassword')}</Title>
      <Text mb="sm">{tra('forgotPassword:description')}</Text>
      <form
        className={classes.forgotPasswordForm}
        onSubmit={form.onSubmit((values) => onSubmit(values))}
      >
        <TextInput
          label={tra('common:email')}
          leftSection={<FaAt />}
          placeholder={tra('common:emailPlaceholder')}
          size="md"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <Button size="md" type="submit">
          {tra('forgotPassword:action')}
        </Button>
      </form>
    </>
  )
}

export default ForgotPasswordPage
