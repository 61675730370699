import { ComboboxItem, NativeSelect } from '@mantine/core'
import { useSearchParams } from 'react-router-dom'

import { getCurrentLanguage, type Language, languages } from '~/utils/i18n'

function languageToSelectOption(lang: Language): ComboboxItem {
  return { label: `${lang.flag} ${lang.name}`, value: lang.code }
}

function LanguageSelector() {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentLanguage = getCurrentLanguage()

  function setLanguage(lang: string) {
    const params = new URLSearchParams(searchParams)
    params.set('lang', lang)
    setSearchParams(params)
    window.location.reload()
  }

  const languageOptions: ComboboxItem[] = [
    languageToSelectOption(currentLanguage),
    ...languages.reduce((acc: ComboboxItem[], lang: Language) => {
      if (currentLanguage.code !== lang.code) {
        acc.push(languageToSelectOption(lang))
      }
      return acc
    }, []),
  ]

  return (
    <NativeSelect
      data={languageOptions}
      data-test="common:languageSelector"
      display={{ base: 'none', xs: 'block' }}
      size="sm"
      onChange={(e) => setLanguage(e.target.value)}
    />
  )
}

export default LanguageSelector
