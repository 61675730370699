// @ts-nocheck
// TODO: This is copied and pasted from an example found online.
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'

import CommandList from '~/components/dashboard/tiptap/command_list'

const renderItems = () => {
  let component: ReactRenderer
  let popup

  return {
    onExit() {
      popup[0].destroy()
      component.destroy()
    },

    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        popup[0].hide()

        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onStart: (props) => {
      component = new ReactRenderer(CommandList, {
        editor: props.editor,
        props,
      })

      popup = tippy('body', {
        appendTo: () => document.body,
        content: component.element,
        getReferenceClientRect: props.clientRect,
        interactive: true,
        placement: 'bottom-start',
        showOnCreate: true,
        trigger: 'manual',
      })
    },

    onUpdate(props) {
      component.updateProps(props)

      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      })
    },
  }
}

export default renderItems
