import { Button, Group, LoadingOverlay, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import EditFeedbackForm from '~/components/dashboard/feedback/edit_feedback_form'
import FeedbackList from '~/components/dashboard/feedback/feedback_list'
import type { Feedback } from '~/types/feedback'
import Fetcher from '~/utils/fetcher'

export default function FeedbackDraftsPage() {
  const { t: tra } = useTranslation()
  const { data: feedbacks, isLoading } = useSWR(
    `/api/v1/feedbacks/?draft=true`,
    (url) => Fetcher.get<{}, Feedback[]>(url),
  )

  const openEditFeedbackModal = (feedback: Feedback) => {
    modals.open({
      children: [
        <EditFeedbackForm
          feedback={feedback}
          onSubmit={() => modals.close('feedback_form_modal')}
        />,
      ],
      closeOnClickOutside: false,
      key: 'feedback_form_modal',
      modalId: 'feedback_form_modal',
      size: 'xl',
      title: tra('feedback_requests:edit_feedback'),
    })
  }

  const deleteFeedbackDraft = (feedback: Feedback) => {
    modals.openConfirmModal({
      children: [
        <Text>
          {tra('feedback_requests:are_you_sure_delete_feedback_draft')}
        </Text>,
      ],
      confirmProps: { color: 'red' },
      labels: { cancel: tra('common:cancel'), confirm: tra('common:delete') },
      onConfirm: () => {
        Fetcher.delete(`/api/v1/feedbacks/${feedback.id}/`)
      },
      title: tra('feedback_requests:delete_draft'),
    })
  }

  const feedbackTableColumns = {
    actions: {
      actions: (
        <Group>
          <Button
            data-testid="edit-feedback-draft-button"
            onClick={() => (feedback: Feedback) =>
              openEditFeedbackModal(feedback)}
          >
            {tra('common:edit')}
          </Button>
          <Button
            data-testid="delete-feedback-draft-button"
            onClick={() => deleteFeedbackDraft}
          >
            {tra('common:delete')}
          </Button>
        </Group>
      ),
      header: tra('common:actions'),
    },
    created_at: { header: tra('calendar:date'), value_from: 'feedback' },
    question: { header: tra('common:question'), value_from: 'response' },
    'receiver.full_name': {
      header: tra('feedback_requests:given_to'),
      value_from: 'feedback',
    },
    response: {
      header: tra('common:feedback'),
      value_from: 'response',
    },
  } as const

  if (isLoading || !feedbacks) {
    return <LoadingOverlay data-testid="feedback-drafts-loader" visible />
  }

  return (
    <FeedbackList
      columns={feedbackTableColumns}
      dataTestId="feedback-drafts"
      feedbacks={feedbacks}
    />
  )
}
