import { Pill, PillsInput } from '@mantine/core'
import { IconAt } from '@tabler/icons-react'
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { emailSchema } from '~/utils/schemas'

interface MultipleEmailsInputProps {
  description?: string
  icon?: ReactNode
  initialValue?: string[]
  label?: string
  onUpdate: (emails: string[]) => void
  placeholder?: string
  radius?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

function MultipleEmailsInput({
  description,
  icon = <IconAt />,
  initialValue = [],
  label,
  onUpdate,
  placeholder,
  radius = 'md',
  size = 'md',
}: MultipleEmailsInputProps) {
  const [emails, setEmails] = useState<string[]>([])
  const ref = useRef<HTMLInputElement>(null)
  const { t: tra } = useTranslation()

  useEffect(() => {
    if (initialValue.length > 0) {
      setEmails(initialValue)
    }
  }, [])

  const removeEmail = (email: string) => {
    const newEmails = emails.filter((e) => e !== email)
    setEmails(newEmails)
    onUpdate(newEmails)
  }

  const addNewEmail = (email: string) => {
    try {
      emailSchema.parse(email)
      const newEmails = [...emails, email]
      setEmails(newEmails)
      onUpdate(newEmails)
      if (ref?.current?.value) {
        ref.current.value = ''
      }
    } catch (e) {
      // Silently fail if it is not a valid email.
    }
  }

  const parseEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const emailStr = event.target.value

    if (emailStr) {
      const newEmails = emailStr.split(',')

      if (newEmails.length > 1) {
        const newEmail = newEmails[0]
        addNewEmail(newEmail)
      }
    }
  }

  const onBlur = () => {
    if (ref?.current?.value) {
      addNewEmail(ref.current.value)
    }
  }

  return (
    <PillsInput
      description={description || tra('admin:users:emailDescription')}
      label={label || tra('common:email')}
      leftSection={icon}
      radius={radius}
      size={size}
    >
      <Pill.Group>
        {emails.map((o) => (
          <Pill key={o} withRemoveButton onRemove={() => removeEmail(o)}>
            {o}
          </Pill>
        ))}
        <PillsInput.Field
          placeholder={placeholder || tra('common:emailPlaceholder')}
          ref={ref}
          onBlur={onBlur}
          onChange={parseEmail}
        />
      </Pill.Group>
    </PillsInput>
  )
}

export default MultipleEmailsInput
