import {
  Avatar,
  Badge,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  GridCol,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { Checkin, CheckinUser } from '~/types/checkin'
import Fetcher from '~/utils/fetcher'

import CheckinDetail from './checkins/detail'

function CheckinCard({
  checkin,
  isActive,
  setActiveCheckin,
  tra,
}: {
  checkin: Checkin
  isActive: boolean
  setActiveCheckin: (checkin: Checkin) => void
  tra: TFunction
}) {
  const {
    checkin_users: checkinUsers,
    occurrence,
    users_count: usersCount,
  } = checkin
  return (
    <Card
      padding="md"
      radius="md"
      shadow="md"
      style={{ backgroundColor: isActive ? 'white' : '#E9ECEF' }}
      withBorder
    >
      <Group justify="space-between" mb="xs" mt="md">
        <Text fw={500} size="lg">
          {checkin.question}
        </Text>
        {checkin.is_paused === false ? (
          <Badge color="pink">
            {tra('checkins:asking', {
              frequency: occurrence.frequency?.toUpperCase(),
            })}
          </Badge>
        ) : (
          <Badge color="yellow">{tra('checkins:paused')}</Badge>
        )}
      </Group>
      <Divider mb="md" />
      <Group>
        {checkinUsers!.map((user: CheckinUser) => (
          <Avatar alt={user.full_name} color="cyan" key={user.id} radius="xl">
            {user.full_name
              .split(' ')
              .map((n: string) => n[0])
              .join('')}
          </Avatar>
        ))}
        <Text>
          {(checkin.users_count ?? 0) > 10
            ? tra('checkins:and_x_users_receiving', {
                user_count: usersCount! - 10,
              })
            : ''}
        </Text>
      </Group>
      <Divider mb="md" mt="md" />
      <Group>
        <Button
          color="pink"
          fullWidth={false}
          onClick={() => setActiveCheckin(checkin)}
        >
          {tra('checkins:view_responses')}
        </Button>
      </Group>
    </Card>
  )
}

export default function CheckinsPage() {
  const { t: tra } = useTranslation()
  const { data: checkins, isLoading } = useSWR([`/api/v1/checkins`], ([url]) =>
    Fetcher.get<{}, Checkin[]>(url),
  )
  const [activeCheckin, setActiveCheckin] = useState<Checkin | null>(null)

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Container fluid>
      <Group>
        <Text size="xl">{tra('checkins:title')}</Text>
      </Group>
      <Divider mb="lg" mt="lg" />
      <Grid columns={4} justify="space-between">
        <Grid.Col span={1}>
          <Stack>
            {checkins?.map((checkin: Checkin) => (
              <CheckinCard
                checkin={checkin}
                isActive={activeCheckin?.id === checkin.id}
                key={checkin.id}
                setActiveCheckin={setActiveCheckin}
                tra={tra}
              />
            ))}
          </Stack>
        </Grid.Col>
        <GridCol span={3}>
          {activeCheckin && <CheckinDetail checkin={activeCheckin} />}
        </GridCol>
      </Grid>
    </Container>
  )
}
