import useSWRSubscription from 'swr/subscription'

import consumer from '~/utils/consumer'

interface UseSocketProps {
  channelName: string
  onDataReceived: (data: any) => void
  roomId: string
  roomName: string
}

function useSocket(props: UseSocketProps) {
  const { channelName, onDataReceived, roomId, roomName } = props

  return useSWRSubscription(
    [channelName, roomName, roomId],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([channelName, roomName, roomId]) => {
      const subscription = consumer.subscriptions.create(
        {
          channel: channelName,
          id: roomId,
          room: roomName,
        },
        {
          received(data) {
            onDataReceived(data)
          },
        },
      )

      return () => subscription.unsubscribe()
    },
  )
}

export default useSocket
