import { Button, Select, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { z } from 'zod'

import type { JobFunctionsResponse, JobLevel } from '~/types/jobs'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const jobLevelFormSchema = z.object({
  job_function: z.object({
    id: z.string().uuid(),
  }),
  name: z.string().min(1),
  title: z.string().min(1),
})

type JobLevelFormSchema = z.infer<typeof jobLevelFormSchema>

export default function JobLevelForm({
  jobLevel,
  onSave,
}: {
  jobLevel?: JobLevel
  onSave?: () => void
}) {
  const { t: tra } = useTranslation()
  const { data: jobFunctions } = useSWR('/api/v1/job_functions', (url) =>
    Fetcher.get<{}, JobFunctionsResponse>(url),
  )

  const form = useForm<JobLevelFormSchema>({
    initialValues: jobLevel ?? {
      job_function: { id: '' },
      name: '',
      title: '',
    },
    validate: (values) => validateForm(jobLevelFormSchema, values),
  })

  const submitForm = async (values: JobLevelFormSchema) => {
    const formData = {
      job_function_id: values.job_function.id,
      name: values.name,
      title: values.title,
    }
    if (jobLevel?.id) {
      await Fetcher.put(`/api/v1/job_levels/${jobLevel.id}`, {
        job_level: formData,
      })
    } else {
      await Fetcher.post('/api/v1/job_levels', { job_level: formData })
    }
    onSave?.()
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <Stack gap="lg">
        <Select
          data={jobFunctions?.job_functions?.map((fn) => ({
            label: fn.name,
            value: fn.id,
          }))}
          label="Job Function"
          required
          {...form.getInputProps('job_function.id')}
        />
        <TextInput
          label={tra('jobs:job_level_name')}
          required
          {...form.getInputProps('name')}
        />
        <TextInput
          label={tra('jobs:job_title')}
          required
          {...form.getInputProps('title')}
        />
        <Button type="submit" variant="outline">
          {jobLevel ? tra('common:update') : tra('common:create')}
        </Button>
      </Stack>
    </form>
  )
}
