import { Anchor, Avatar, Badge, Grid, Group, Stack, Text } from '@mantine/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import type { CheckinResponse } from '~/types/checkin'

export default function Response({
  canEdit,
  onEditResponse,
  response,
}: {
  canEdit: boolean
  onEditResponse: (response: CheckinResponse) => void
  response: CheckinResponse
}) {
  const { t: tra } = useTranslation()
  const { created_at: createdAt, response: postedResponse, user } = response
  const date = moment(createdAt)

  return (
    <Grid columns={12}>
      <Grid.Col span={1}>
        <Stack>
          <Badge color="cyan">{date.fromNow()}</Badge>
          <Text c="dimmed" fw={400} ta="left">
            {date.format('HH:mm')}
          </Text>
          {canEdit && (
            <Anchor
              component="button"
              ta="left"
              onClick={() => onEditResponse(response)}
            >
              {tra('common:edit')}
            </Anchor>
          )}
        </Stack>
      </Grid.Col>
      <Grid.Col span={11}>
        <Stack>
          <Group>
            <Avatar size="lg" src={user.profile_picture_url} />
            <Text>{user.full_name}</Text>
            <Text c="dimmed" style={{ marginLeft: -10 }}>
              {user.title}
            </Text>
          </Group>
          <div dangerouslySetInnerHTML={{ __html: postedResponse }} />
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
