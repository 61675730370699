import { notifications } from '@mantine/notifications'
import i18next from 'i18next'

export default class VoynError extends Error {}

export function showErrorNotification(err: unknown) {
  if (err instanceof VoynError) {
    notifications.show({
      color: 'red',
      message: err.message,
      title: i18next.t('errors:oops'),
    })
  }
}
