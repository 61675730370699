import {
  Button,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { TFunction } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { MeetingRequest } from '~/types/meeting'
import type { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

/* eslint-disable no-restricted-globals */
// @TODO get rid of this ^^ move alert/confirm to centralized mantine component utils

function MeetingRequestDetail({
  currentUser,
  meetingRequest,
  onAccept,
  onReject,
  tra,
}: {
  currentUser: User
  meetingRequest: MeetingRequest
  onAccept: () => void
  onReject: () => void
  tra: TFunction
}) {
  const {
    requested_date: requestedDate,
    requested_from: requestedFrom,
    requester,
    status,
  } = meetingRequest
  const requestFromSomeoneElse = requester.id !== currentUser.id
  const detail = requestFromSomeoneElse ? (
    <Trans
      i18nKey="home:request_sent_by"
      values={{ name: requester.full_name }}
    />
  ) : (
    <Trans
      i18nKey="home:request_sent_to"
      values={{ name: requestedFrom.full_name }}
    />
  )

  const acceptRequest = async () => {
    if (confirm(tra('home:confirm_accept_request'))) {
      try {
        await Fetcher.post(
          `/api/v1/meeting_requests/${meetingRequest.id}/accept`,
        )
        onAccept()
      } catch (error) {
        alert(tra('home:request_accept_failed'))
      }
    }
  }

  const rejectRequest = async () => {
    if (confirm(tra('home:confirm_reject_request'))) {
      try {
        await Fetcher.post(
          `/api/v1/meeting_requests/${meetingRequest.id}/reject`,
        )
        onReject()
      } catch (error) {
        alert(tra('home:request_reject_failed'))
      }
    }
  }

  return (
    <Table.Tr>
      <Table.Td width={200}>{detail}</Table.Td>
      <Table.Td>{requestedDate}</Table.Td>
      <Table.Td>{tra(`common:${status}`)}</Table.Td>
      <Table.Td>
        {requestFromSomeoneElse && status === 'pending' && (
          <Group gap="xs">
            <Button
              data-testid="accept-meeting-request"
              onClick={acceptRequest}
            >
              {tra('common:accept')}
            </Button>
            <Button
              data-testid="reject-meeting-request"
              onClick={rejectRequest}
            >
              {tra('common:reject')}
            </Button>
          </Group>
        )}
        {!requestFromSomeoneElse && (
          <Text c="gray" size="sm">
            {tra('common:na')}
          </Text>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

export default function MeetingRequestsCard() {
  const { t: tra } = useTranslation()
  const { user } = useSession()
  const {
    data: meetingRequests,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/meeting_requests`, (url) =>
    Fetcher.get<{}, MeetingRequest[]>(url),
  )
  const hasMeetingRequests = (meetingRequests?.length ?? 0) > 0

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Paper p="md" withBorder>
      <Title order={4}>{tra('home:meeting_requests')}</Title>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{tra('common:detail')}</Table.Th>
            <Table.Th>{tra('calendar:request_date')}</Table.Th>
            <Table.Th>{tra('common:status')}</Table.Th>
            <Table.Th>{tra('common:actions')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {hasMeetingRequests ? (
            meetingRequests?.map((meetingRequest) => (
              <MeetingRequestDetail
                currentUser={user}
                key={meetingRequest.id}
                meetingRequest={meetingRequest}
                tra={tra}
                onAccept={() => mutate()}
                onReject={() => mutate()}
              />
            ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Text fw={500}>{tra('home:no_meeting_requests')}</Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Paper>
  )
}
