import {
  Avatar,
  Card,
  Group,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core'
import {
  IconBuilding,
  IconListCheck,
  IconMail,
  IconMessage,
  IconMoodEmpty,
} from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction } from 'react-router-dom'

import classes from '~/components/dashboard/report_card.module.css'
import { UserReportView } from '~/types/user'

type ReportCardProps = {
  navigate: NavigateFunction
  user: UserReportView
}
function ReportCard({ navigate, user }: ReportCardProps) {
  const { t: tra } = useTranslation()
  return (
    <UnstyledButton
      h="100%"
      w="100%"
      onClick={() => navigate(`/dashboard/members/${user.id}`)}
    >
      <Card
        className={classes.reportCard}
        data-testid="report-card"
        h="100%"
        p="sm"
        radius="sm"
        withBorder
      >
        <Stack align="flex-start" gap="0">
          <Group align="flex-start" justify="space-between" w="100%">
            <Avatar radius="50%" size="lg" src={user.profile_picture_url} />
            {/* @TODO(msa): display the real mood */}
            <Tooltip label="Mood: Average">
              <IconMoodEmpty
                color="gray"
                data-testid="report-mood"
                size="1.2rem"
              />
            </Tooltip>
          </Group>
          <Text fw={500} mt="sm">
            {user.full_name || 'Unnamed Hero'}
          </Text>
          <Text c="gray" fz="sm">
            {user.title}
          </Text>
        </Stack>
        <Stack gap="0" mt="sm">
          <Group align="center" gap="0">
            <IconMail color="gray" size="0.8rem" />
            <Text c="gray" fz="sm" ml="0.3rem">
              {user.email}
            </Text>
          </Group>
          <Group align="center" gap="0">
            <IconBuilding color="gray" size="0.8rem" />
            <Text c="gray" fz="sm" ml="0.3rem">
              {user.nodes
                .slice(0, -1)
                .map((node) => node.name)
                .join(', ') +
                (user.nodes.length > 1 ? ' & ' : '') +
                (user.nodes.length
                  ? user.nodes[user.nodes.length - 1].name
                  : '')}
            </Text>
          </Group>
        </Stack>
        <Group
          gap="0"
          grow
          mt="sm"
          pt="sm"
          style={{ borderTop: '1px solid var(--app-shell-border-color)' }}
        >
          <Stack
            align="center"
            gap="0"
            justify="center"
            style={{ borderRight: '1px solid var(--app-shell-border-color)' }}
          >
            <IconListCheck color="gray" size="0.8rem" />
            {user.assigned_todos !== undefined && (
              <Text c="gray" fz="sm">
                {tra('users:assigned_todos', {
                  count: user.assigned_todos.length,
                })}
              </Text>
            )}
          </Stack>
          <Stack align="center" gap="0" justify="center">
            <IconMessage color="gray" size="0.8rem" />
            {user.created_agenda_items !== undefined && (
              <Text c="gray" fz="sm">
                {tra('users:created_agenda_items', {
                  count: user.created_agenda_items.length,
                })}
              </Text>
            )}
          </Stack>
        </Group>
      </Card>
    </UnstyledButton>
  )
}

export default ReportCard
