import { Avatar, Group, Loader, Paper, Stack, Text } from '@mantine/core'
import useSWR from 'swr'

import Fetcher from '~/utils/fetcher'

function PulsesTab({ userId }: { userId: string }) {
  const { data, isLoading } = useSWR('/api/v1/pulse_submissions', (url) =>
    Fetcher.get<{ user_id: string }, { pulse_responses: any[] }[]>(url, {
      user_id: userId,
    }),
  )

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <>
      {data.map((submission: any) => (
        <Paper mt="sm" p="md" withBorder>
          <Group align="start">
            <Avatar src={submission.user.profile_picture_url} />
            <Stack>
              <Text>
                <strong>{submission.user.full_name}</strong> answered{' '}
                {submission.pulse.title} on{' '}
                <strong>{submission.created_at}</strong>
              </Text>
              <Stack gap="xs">
                {submission.pulse_responses.map((pulseResponse: any) => (
                  <>
                    <Text fw="bold" mb="0">
                      {pulseResponse.question_label}
                    </Text>
                    <Text>{pulseResponse.response}</Text>
                  </>
                ))}
              </Stack>
            </Stack>
          </Group>
        </Paper>
      ))}
    </>
  )
}

export default PulsesTab
