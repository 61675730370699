import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import en from '~/locales/en.json'

export type Language = {
  code: 'en' | 'tr'
  flag: string
  name: string
}

export const languages: Language[] = [
  { code: 'en', flag: '🇺🇸', name: 'English' },
  { code: 'tr', flag: '🇹🇷', name: 'Türkçe' },
]

export enum Currency {
  TRY = 'TRY',
  USD = 'USD',
}
// Please see Zod validations as well, I wasn't able to map this array into an enum
export const currencies = [
  { flag: '🇺🇸', name: 'US Dollar', sign: '$', value: Currency.USD },
  { flag: '🇹🇷', name: 'Türk Lirası', sign: '₺', value: Currency.TRY },
]

const defaultLanguage = languages[0]
const defaultTimezone = 'Europe/London'

export function getCurrentLanguage() {
  return languages.find((l) => l.code === i18next.language) || defaultLanguage
}

export function getCurrentLanguageCode() {
  return getCurrentLanguage().code
}

export function getCurrentLanguageById(id: string) {
  return languages.find((l) => l.code === id) || defaultLanguage
}

export function setupI18N() {
  const urlParams = new URLSearchParams(window.location.search)
  const initialLanguage = urlParams.get('lang') || defaultLanguage.code
  i18next.use(initReactI18next).init({
    fallbackLng: defaultLanguage.code,
    interpolation: {
      escapeValue: false,
    },
    lng: initialLanguage,
    react: {
      transKeepBasicHtmlNodesFor: ['strong'],
    },
    resources: {
      en,
    },
  })
  z.setErrorMap(makeZodI18nMap({ ns: ['errors'], t: i18next.t }))
}

export function getTimezoneFromBrowser() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    return defaultTimezone
  }
}
