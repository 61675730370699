import { MeetingKind } from '~/types/meeting_kind'
import { MeetingStatus } from '~/types/meeting_status'

export type MeetingFilter = {
  attendeeId?: string
  creatorId?: string
  kind?: MeetingKind
  status?: MeetingStatus
}

export type MeetingFilterPayload = {
  attendee_id?: string
  creator_id?: string
  kind?: string
  status?: string
}

export function filterToPayload(filter: MeetingFilter): MeetingFilterPayload {
  const payload: MeetingFilterPayload = {}

  if (filter.kind) {
    payload.kind = filter.kind
  }

  if (filter.status) {
    payload.status = filter.status
  }

  if (filter.attendeeId) {
    payload.attendee_id = filter.attendeeId
  }

  if (filter.creatorId) {
    payload.creator_id = filter.creatorId
  }

  return payload
}
