import { PasswordInput, Stepper, TextInput } from '@mantine/core'
import { z } from 'zod'

const companySizes = ['1-10', '10-50', '50-100', '100-250', '250+'] as const
const tenantCreateFormSchema = z.object({
  name: z.string().min(1, { message: 'companyNameInvalid' }),
  size: z.enum(companySizes),
})
type TenantCreateFormSchema = z.infer<typeof tenantCreateFormSchema>

function CreateTenantPage(props: any) {
  const { form } = props
  console.log('rendered')
  return (
    <Stepper.Step description="Profile settings" label="First step">
      <TextInput
        label="Username"
        placeholder="Username"
        {...form.getInputProps('username')}
      />
      <PasswordInput
        label="Password"
        mt="md"
        placeholder="Password"
        {...form.getInputProps('password')}
      />
    </Stepper.Step>
  )
}

export default CreateTenantPage
