import { Badge } from '@mantine/core'
import { IconClock } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

function MeetingTimer({
  duration,
  initialTime,
}: {
  duration: number
  initialTime: string
}) {
  const [elapsedTime, setElapsedTime] = useState<string>('')

  useEffect(() => {
    const intervalId = setInterval(() => {
      const current = dayjs()
      const initial = dayjs(initialTime)
      const diffMinutes = current.diff(initial, 'minute')
      const hours = Math.floor(diffMinutes / 60)
      const minutes = diffMinutes % 60
      setElapsedTime(
        `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}`,
      )
    }, 1000)

    return () => clearInterval(intervalId)
  }, [initialTime])

  return (
    <Badge leftSection={<IconClock size="0.75rem" />}>
      {elapsedTime} / {duration}:00m
    </Badge>
  )
}

export default MeetingTimer
