import { Button, NativeSelect, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { TFunction } from 'i18next'
import { z } from 'zod'

import { UserIndexView } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

import { mapUsersToSelectOptions } from './common'

const moveEmployeesSchema = z.object({
  new_manager_id: z.string().uuid(),
})

type MoveEmployeesSchemaType = z.infer<typeof moveEmployeesSchema>

export default function MoveReportsForm({
  manager,
  managers,
  onUpdate,
  tra,
}: {
  manager: UserIndexView
  managers: UserIndexView[]
  onUpdate: () => void
  tra: TFunction
}) {
  const form = useForm({
    initialValues: {
      new_manager_id: manager.id,
    },
    validate: (values) => {
      return validateForm(moveEmployeesSchema, values)
    },
  })

  const onSubmit = async (values: MoveEmployeesSchemaType) => {
    await Fetcher.post(`/api/v1/users/${manager.id}/move_reports`, {
      new_manager_id: values.new_manager_id,
    })
    onUpdate()
  }

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack gap="md">
        <input type="hidden" {...form.getInputProps('manager_id')} />
        <NativeSelect
          data={mapUsersToSelectOptions(managers, tra)}
          data-testid="manager-select"
          label={tra('admin:users:selectNewManager', {
            name: manager.first_name,
          })}
          name="new_manager_id"
          {...form.getInputProps('new_manager_id')}
        />
        <Button data-testid="move-reports-button" type="submit">
          {tra('admin:users:move_reports')}
        </Button>
      </Stack>
    </form>
  )
}
