import {
  Alert,
  Button,
  Center,
  Flex,
  Image,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { FaAt, FaCheck, FaLock } from 'react-icons/fa'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import InformationRightPane from '~/components/shared/information_right_pane'
import PageHeader from '~/components/shared/page_header'
import TopBar from '~/components/shared/top_bar'
import GetStartedImage from '~/images/get-started/sign-up.png'
import classes from '~/pages/pre-auth/login.module.css'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { getCurrentLanguageCode } from '~/utils/i18n'
import { emailSchema, passwordSchema } from '~/utils/schemas'
import { homePath } from '~/utils/session'
import validateForm from '~/utils/validate-form'

const loginFormSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
})
type LoginFormSchemaType = z.infer<typeof loginFormSchema>

function LoginPage() {
  const { t: tra } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isForgotPasswordSuccessful =
    searchParams.get('forgot_password') === 'true'
  const isResetPasswordSuccessful =
    searchParams.get('reset_password') === 'true'

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: (values) => {
      return validateForm(loginFormSchema, values)
    },
  })

  const onSubmit = async (formData: LoginFormSchemaType) => {
    const payload = {
      email: formData.email.toLowerCase(),
      password: formData.password,
    }

    try {
      await Fetcher.post<LoginFormSchemaType, LoginFormSchemaType>(
        '/api/v1/sessions',
        payload,
      )

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('redirect_to')) {
        window.location.pathname = urlParams.get('redirect_to')!
      } else {
        window.location.assign(homePath)
      }
    } catch (e) {
      showErrorNotification(e)
    }
  }

  const loginForm = (
    <form
      className={classes.loginForm}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <TextInput
        data-test="login:emailInput"
        label={tra('common:email')}
        leftSection={<FaAt />}
        placeholder={tra('common:emailPlaceholder')}
        size="md"
        withAsterisk
        {...form.getInputProps('email')}
      />
      <PasswordInput
        data-test="login:passwordInput"
        label={tra('common:password')}
        leftSection={<FaLock />}
        placeholder={tra('common:passwordPlaceholder')}
        size="md"
        withAsterisk
        {...form.getInputProps('password')}
      />
      <Button data-test="login:submitButton" size="md" type="submit">
        {tra('login:actionText')}
      </Button>
      <Center>
        <Link to={`/forgot_password?lang=${getCurrentLanguageCode()}`}>
          <Text>{tra('login:forgotPassword')}</Text>
        </Link>
      </Center>
    </form>
  )

  return (
    <>
      <PageHeader title="login:actionText" />
      <Flex className={classes.loginContainer}>
        <Flex className={classes.loginLeftContainer}>
          <TopBar />
          <Flex className={classes.loginFormContainer}>
            <Title data-test="login:header" ta="center">
              {tra('login:header')}
            </Title>
            <Text ta="center">{tra('login:description')}</Text>
            {isForgotPasswordSuccessful && (
              <Alert
                color="green"
                icon={<FaCheck size="1rem" />}
                mt="md"
                title={tra('common:checkYourInbox')}
              >
                {tra('login:forgotPasswordSuccess')}
              </Alert>
            )}
            {isResetPasswordSuccessful && (
              <Alert
                color="green"
                icon={<FaCheck size="1rem" />}
                mt="md"
                title={tra('login:resetPasswordSuccessHeader')}
              >
                {tra('login:resetPasswordSuccessText')}
              </Alert>
            )}
            {loginForm}
          </Flex>
        </Flex>
        <InformationRightPane
          button={
            <Button
              mt={8}
              size="md"
              variant="outline"
              onClick={() =>
                navigate(`/get_started?lang=${getCurrentLanguageCode()}`)
              }
            >
              {tra('login:signUpCta')}
            </Button>
          }
          description={tra('login:signUpDescription')}
          heading={tra('login:signUpHeader')}
          image={
            <Image
              alt="voyn.io Logo"
              fit="contain"
              maw="20em"
              mb="md"
              src={GetStartedImage}
            />
          }
        />
      </Flex>
    </>
  )
}

export function sum(a: number, b: number): number {
  return a + b
}

export default LoginPage
