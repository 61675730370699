import { Flex, Image } from '@mantine/core'

import LanguageSelector from '~/components/shared/language_selector'
import classes from '~/components/shared/top_bar.module.css'
import Logo from '~/images/logo.png'

export default function TopBar({
  isLanguageSelectEnabled = true,
}: {
  isLanguageSelectEnabled?: boolean
}) {
  return (
    <Flex className={classes.topBarContainer}>
      <Image alt="voyn.io Logo" fit="contain" src={Logo} w={160} />
      {isLanguageSelectEnabled && <LanguageSelector />}
    </Flex>
  )
}
