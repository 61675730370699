import {
  Box,
  Button,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconLock } from '@tabler/icons-react'
import React, { useState } from 'react'
import { z } from 'zod'

import { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'

const passwordChangeSchema = z
  .object({
    new_password: z.string().min(6, 'Password must be at least 6 characters'),
    new_password_confirmation: z.string(),
    password: z.string().min(6, 'Password must be at least 6 characters'),
  })
  .refine(
    (values) => values.new_password === values.new_password_confirmation,
    {
      message: 'Passwords do not match',
      path: ['new_password_confirmation'],
    },
  )

type PasswordChangeType = z.infer<typeof passwordChangeSchema>

export default function Security({ user }: { user: User }) {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<PasswordChangeType>({
    initialValues: {
      new_password: '',
      new_password_confirmation: '',
      password: '',
    },
    validate: zodResolver(passwordChangeSchema),
  })

  const updatePassword = async (values: PasswordChangeType): Promise<void> => {
    setIsLoading(true)
    try {
      await Fetcher.post(`/api/v1/users/${user.id}/change_password`, values)
      showNotification({
        color: 'green',
        message: 'Password updated successfully',
        title: 'Success',
      })
    } catch (e) {
      showNotification({
        color: 'red',
        message: (e as Error).message,
        title: 'Error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box maw={400} mx="auto">
      <LoadingOverlay
        overlayProps={{ blur: 2, radius: 'sm' }}
        visible={isLoading}
        zIndex={1000}
      />
      <Title mb="md" order={2}>
        Change Password
      </Title>
      <form onSubmit={form.onSubmit(updatePassword)}>
        <Stack gap="md">
          <PasswordInput
            label="Current Password"
            leftSection={<IconLock size={16} />}
            placeholder="Enter your current password"
            required
            {...form.getInputProps('password')}
          />
          <PasswordInput
            label="New Password"
            leftSection={<IconLock size={16} />}
            placeholder="Enter your new password"
            required
            {...form.getInputProps('new_password')}
          />
          <PasswordInput
            label="Confirm New Password"
            leftSection={<IconLock size={16} />}
            placeholder="Confirm your new password"
            required
            {...form.getInputProps('new_password_confirmation')}
          />
          <Button type="submit">Change Password</Button>
        </Stack>
      </form>
    </Box>
  )
}
