import { LoadingOverlay, rem, Skeleton } from '@mantine/core'

export default function VoynLoadingOverlay() {
  return (
    <LoadingOverlay
      data-testid="voyn-loading-overlay"
      overlayProps={{ blur: 2 }}
      pl={rem(96)}
      visible
    />
  )
}

export function VoynLoadingSkeleton() {
  return (
    <>
      <Skeleton circle height={50} mb="xl" mt="xl" />
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" width="70%" />
    </>
  )
}
