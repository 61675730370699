import { Button, Divider } from '@mantine/core'
import { useForm } from '@mantine/form'
import type { Editor } from '@tiptap/core'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import CheckinEditor from '~/pages/dashboard/checkins/editor'
import type { CheckinResponse } from '~/types/checkin'
import Fetcher from '~/utils/fetcher'
import { newCheckinResponseFormSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

export default function CheckinResponseComponent({
  checkinId,
  isEditable,
  isEditing,
  onResponseUpdated,
  responseToEdit,
}: {
  checkinId: string
  isEditable: boolean
  isEditing: boolean
  onResponseUpdated: () => void
  responseToEdit: CheckinResponse | null
}) {
  const { t: tra } = useTranslation()
  const editorRef = useRef<Editor>(null)

  const form = useForm<Partial<CheckinResponse>>({
    initialValues: {
      response: '',
    },
    validate: (values) => validateForm(newCheckinResponseFormSchema, values),
  })

  const onEditorContentUpdated = ({ editor }: { editor: Editor }) =>
    form.setFieldValue('response', editor.getHTML())

  const submitResponse = async () => {
    if (form.isValid()) {
      await Fetcher.post<
        { checkin_response: Partial<CheckinResponse> },
        CheckinResponse
      >(`/api/v1/checkins/${checkinId}/responses`, {
        checkin_response: {
          response: form.values.response,
        },
      })

      editorRef.current!.commands.clearContent(true)
    }
  }

  const updateResponse = async () => {
    if (form.isValid()) {
      await Fetcher.put<
        { checkin_response: Partial<CheckinResponse> },
        CheckinResponse
      >(`/api/v1/checkins/${checkinId}/responses/${responseToEdit?.id}`, {
        checkin_response: {
          response: form.values.response,
        },
      })

      editorRef.current!.commands.clearContent(true)
      editorRef.current!.setEditable(false)
      onResponseUpdated()
    }
  }

  return (
    <>
      <Divider />
      <CheckinEditor
        checkinId={checkinId}
        editorContent={responseToEdit?.response || ''}
        editorRef={editorRef as React.MutableRefObject<Editor>}
        isEditable={isEditable}
        onEditorContentUpdated={onEditorContentUpdated}
      />
      <Button
        style={{ alignSelf: 'flex-end', width: 200 }}
        onClick={isEditing ? updateResponse : submitResponse}
      >
        {tra(
          isEditing ? 'checkins:update_response' : 'checkins:submit_response',
        )}
      </Button>
    </>
  )
}
