import { Grid } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'

import ReportCard from '~/components/dashboard/report_card'
import VoynLoadingOverlay from '~/components/shared/loading'
import { UserReportView } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function AllReportsPage() {
  const { user: currentUser } = useSession()
  const navigate = useNavigate()

  const {
    data: users,
    error,
    isLoading,
  } = useSWRImmutable(`/api/v1/users/${currentUser.id}/reports`, (url) =>
    Fetcher.get<{}, UserReportView[]>(url),
  )

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (error) {
    return <h1>ERROR</h1>
  }

  if (users) {
    return (
      <Grid p="md">
        {users &&
          users.map((user) => (
            <Grid.Col key={user.id} span={3}>
              <ReportCard navigate={navigate} user={user} />
            </Grid.Col>
          ))}
      </Grid>
    )
  }

  return null
}

export default AllReportsPage
