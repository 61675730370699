import { Divider, List, Paper, Rating, Stack, Text, Title } from '@mantine/core'
import {
  IconCalendar,
  IconStar,
  IconUser,
  IconUsers,
} from '@tabler/icons-react'
import Collaboration from '@tiptap/extension-collaboration'
import { Editor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { toUint8Array } from 'js-base64'
import { useEffect, useState } from 'react'
import { KeyedMutator } from 'swr'
import * as Y from 'yjs'

import MeetingInfoItem from '~/components/dashboard/meeting_info_item'
import TodoNode from '~/components/dashboard/tiptap/todo_node'
import { MeetingDetailView } from '~/types/meeting'
import Fetcher from '~/utils/fetcher'

type MeetingInfoProps = {
  meetingDetail: MeetingDetailView
  mutateMeetingDetail: KeyedMutator<MeetingDetailView>
}

function MeetingInfo({ meetingDetail, mutateMeetingDetail }: MeetingInfoProps) {
  const doc = meetingDetail.ydoc ? toUint8Array(meetingDetail.ydoc) : null
  const [editorMap, setEditorMap] = useState<Record<string, Editor>>({})
  useEffect(() => {
    const yDoc = new Y.Doc()
    if (doc) {
      Y.applyUpdate(yDoc, doc)
    }

    const newMap: Record<string, Editor> = {}
    meetingDetail.agenda_items.forEach((item) => {
      const editor = new Editor({
        editable: false,
        extensions: [
          StarterKit.configure({
            history: false, // important because history will now be handled by Y.js
          }),
          Collaboration.configure({
            document: yDoc,
            field: item.id,
          }),
          TodoNode.configure({}),
        ],
      })
      newMap[item.id] = editor
      setEditorMap(newMap)
    })

    return () => {
      // Destroy all the editors
      Object.keys(editorMap).forEach((koko) => editorMap[koko].destroy())

      // Destroy the YDoc and unbind all the related stuff.
      yDoc.destroy()
    }
  }, [])

  return (
    <Stack gap="xs">
      <Title order={3}>{meetingDetail.title}</Title>
      <MeetingInfoItem
        icon={<IconCalendar size="0.75rem" />}
        title="DATE & DURATION"
      >
        {meetingDetail.actual_start_time} - {meetingDetail.actual_end_time}
      </MeetingInfoItem>
      <MeetingInfoItem icon={<IconUser size="0.75rem" />} title="CREATED BY">
        {meetingDetail.creator.full_name}
      </MeetingInfoItem>
      <MeetingInfoItem icon={<IconUsers size="0.75rem" />} title="ATTENDEES">
        {meetingDetail.attendees.length > 1 ? (
          <List>
            {meetingDetail.attendees.map((user) => (
              <List.Item>{user.full_name}</List.Item>
            ))}
          </List>
        ) : (
          <Text>{meetingDetail.attendees[0].full_name}</Text>
        )}
      </MeetingInfoItem>
      <MeetingInfoItem icon={<IconStar size="0.75rem" />} title="RATING">
        <Rating
          value={meetingDetail.rating}
          onChange={async (e) => {
            await Fetcher.post(`/api/v1/meetings/${meetingDetail.id}/rate`, {
              rating: e,
            })
            mutateMeetingDetail(
              { ...meetingDetail, rating: e },
              { revalidate: false },
            )
          }}
        />
      </MeetingInfoItem>
      <Divider />
      <Title order={4}>NOTES</Title>
      {meetingDetail.agenda_items.map((agendaItem, index) => (
        <Paper mb="sm" p="sm" withBorder>
          <Title order={4}>
            {index + 1}. {agendaItem.creator.full_name}: {agendaItem.title}
          </Title>
          <Text c="gray">{agendaItem.description}</Text>
          <Divider />

          <EditorContent editor={editorMap[agendaItem.id]} />
        </Paper>
      ))}
    </Stack>
  )
}

export default MeetingInfo
