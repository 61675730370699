export enum Role {
  ADMIN = 'admin',
  USER = 'user',
}

export const priorities = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
]
