import { Editor, Range } from '@tiptap/core'

type CommandProps = {
  editor: Editor
  range: Range
}

export type SlashMenuItem = {
  command: (props: CommandProps) => void
  title: string
}

/**
 * I don't know why but TS compiler gives warning if we don't explicitly give a type for each individual item's command.
 * That's the reason why we explicitly write CommandProps for each command function.
 */
function getSuggestionItems({ query }: { query: string }): SlashMenuItem[] {
  const items: SlashMenuItem[] = [
    {
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 1 })
          .run()
      },
      title: 'H1',
    },
    {
      command: ({ editor, range }: CommandProps) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 2 })
          .run()
      },
      title: 'H2',
    },
    {
      command: ({ editor, range }: { editor: Editor; range: Range }) => {
        editor.chain().focus().deleteRange(range).openTodoDrawer().run()
      },
      title: 'todo',
    },
  ]
    .filter((item) => item.title.toLowerCase().startsWith(query.toLowerCase()))
    .slice(0, 10)

  return items
}

export default getSuggestionItems
