import {
  ActionIcon,
  Avatar,
  Badge,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconCalendar, IconSquare, IconTrash } from '@tabler/icons-react'
import {
  mergeAttributes,
  Node as TiptapNode,
  NodeViewProps,
} from '@tiptap/core'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import { useTranslation } from 'react-i18next'
import useSWRImmutable from 'swr/immutable'

import classes from '~/components/dashboard/tiptap/todo_node.module.css'
import TodoDrawer from '~/components/dashboard/todo_drawer'
import { Todo } from '~/types/todo'
import { User } from '~/types/user'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'

interface TodoCardAttributes {
  dueDate: string
  id: string
  possibleAssignees: User[]
  title: string
}

function TodoNodeComponent(nodeProps: NodeViewProps) {
  const {
    editor,
    extension: { options },
    getPos,
    node,
  } = nodeProps
  const { id } = node.attrs
  const { possibleAssignees } = options
  const [isTodoDrawerOpened, { close: closeTodoDrawer, open: openTodoDrawer }] =
    useDisclosure(false)
  const { t: tra } = useTranslation()

  const {
    data,
    isLoading,
    mutate: mutateTodo,
  } = useSWRImmutable(`/api/v1/todos/${id}`, (url) =>
    Fetcher.get<{}, Todo>(url),
  )

  const deleteTodo = async () => {
    // Remove the todo and then the content node
    try {
      await Fetcher.delete(`/api/v1/todos/${id}`)

      if (typeof getPos === 'function') {
        const pos = getPos()
        editor
          .chain()
          .focus()
          .deleteRange({ from: pos, to: pos + node.nodeSize })
          .run()
      }
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const openDeleteTodoModal = () => {
    modals.openConfirmModal({
      children: <Text size="sm">{tra('todoForm:deleteConfirmation')}</Text>,
      labels: {
        cancel: tra('common:cancel'),
        confirm: tra('todoForm:deleteAction'),
      },
      onConfirm: deleteTodo,
      title: tra('todoForm:deleteTitle'),
    })
  }

  if (isLoading || !data) {
    return (
      <NodeViewWrapper>
        <Loader />
      </NodeViewWrapper>
    )
  }

  return (
    <NodeViewWrapper style={{ userSelect: 'none' }}>
      {isTodoDrawerOpened && (
        <TodoDrawer
          opened={isTodoDrawerOpened}
          todo={data}
          users={possibleAssignees}
          onClose={closeTodoDrawer}
          onSuccess={(updatedTodo) => {
            mutateTodo(updatedTodo, { revalidate: false })
            closeTodoDrawer()
          }}
        />
      )}

      <Paper
        className={classes.todoPaper}
        contentEditable={false}
        p="sm"
        radius="sm"
        withBorder
      >
        <Stack onClick={openTodoDrawer}>
          <Group align="flex-start" wrap="nowrap">
            <Group flex="1">
              <IconSquare color="var(--mantine-color-gray-4)" />
              {data && (
                <Stack gap="0">
                  <Text
                    flex="1"
                    lineClamp={8}
                    style={{ whiteSpace: 'pre-line' }}
                    truncate="end"
                  >
                    {data.title}
                  </Text>
                  <Text c="gray" fz="sm">
                    {data.description}
                  </Text>
                </Stack>
              )}
            </Group>
            <Tooltip label="Delete todo">
              <ActionIcon
                color="red"
                variant="subtle"
                onClick={(e) => {
                  e.stopPropagation()
                  openDeleteTodoModal()
                }}
              >
                <IconTrash size="1rem" />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Stack gap="xs">
            {data && (
              <Group>
                <Badge size="sm" variant="outline">
                  <Group gap="xs">
                    <Avatar
                      size="xs"
                      src={data?.assignee.profile_picture_url}
                    />
                    {data?.assignee.full_name}
                  </Group>
                </Badge>
                <Badge size="lg" variant="outline">
                  <IconCalendar size="0.75rem" />
                  {data?.due_date}
                </Badge>
              </Group>
            )}
          </Stack>
        </Stack>
      </Paper>
    </NodeViewWrapper>
  )
}

const TodoNode = TiptapNode.create<TodoCardAttributes>({
  addAttributes() {
    return {
      dueDate: {
        default: '',
      },
      id: {
        default: '',
      },
      title: {
        default: '',
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(TodoNodeComponent)
  },

  content: 'inline*',
  group: 'block',
  name: 'todoCard',

  parseHTML() {
    return [
      {
        tag: 'todo-card',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['todo-card', mergeAttributes(HTMLAttributes), 0]
  },
})

export default TodoNode
