import {
  Button,
  Fieldset,
  Group,
  Select,
  Stack,
  TagsInput,
  Text,
  TextInput,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { IconEyeQuestion } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { Day, Frequency, Time } from '~/types/common'
import { Pulse, Question as QuestionType } from '~/types/pulse'
import { arrayRange } from '~/utils/arrays'

type TimeOptions = {
  label: string
  value: string
}

export function Frequencies({ form }: { form: UseFormReturnType<Pulse> }) {
  const { t: tra } = useTranslation()
  const frequencies = ['daily', 'weekly', 'monthly', 'biweekly'].map((f) => ({
    label: tra(`pulse:${f}`),
    value: f,
  }))

  const setFrequency = (f: Frequency | null) => {
    form.setFieldValue('occurrence.frequency', f)
    if (f === 'daily') {
      form.setFieldValue('occurrence.day', null)
    }
  }

  return (
    <Select
      data={frequencies}
      placeholder={tra('pulse:frequency_label')}
      style={{ width: '170px' }}
      value={form.values.occurrence.frequency}
      onChange={(value, _option) => setFrequency(value as Frequency)}
    />
  )
}

export function Days({
  disabled = false,
  form,
}: {
  disabled: boolean
  form: UseFormReturnType<Pulse>
}) {
  const { t: tra } = useTranslation()
  const setDay = (day: string | null) => {
    const newValue = day === form.values.occurrence.day ? '' : day
    form.setFieldValue('occurrence.day', newValue as Day)
  }

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map(
    (day) => ({ label: tra(`calendar:${day}`), value: day }),
  )

  return (
    <Select
      data={days}
      disabled={disabled}
      placeholder={tra('pulse:day_label')}
      value={form.values.occurrence.day}
      onChange={(d) => setDay(d)}
    />
  )
}

export function Times({ form }: { form: UseFormReturnType<Pulse> }) {
  const { t: tra } = useTranslation()
  const timeData: TimeOptions[] = arrayRange(7, 19)
    .map((r) => r.toString().padStart(2, '0'))
    .reduce(
      (acc: TimeOptions[], hour) =>
        acc.concat([
          { label: `${hour}:00`, value: `${hour}:00` },
          { label: `${hour}:30`, value: `${hour}:30` },
        ]),
      [],
    )
  const setTime = (time: string | null) =>
    form.setFieldValue('occurrence.time', time as Time)

  return (
    <Select
      data={timeData}
      placeholder={tra('pulse:time_label')}
      style={{ width: '170px' }}
      value={form.values.occurrence.time}
      onChange={setTime}
    />
  )
}

export function Occurrence({ form }: { form: UseFormReturnType<Pulse> }) {
  const { t: tra } = useTranslation()
  const shouldDisplayDays = form.values.occurrence?.frequency !== 'daily'

  return (
    <Stack>
      <Text size="md">{tra('pulse:occurrence_label')}</Text>
      <Group gap="md">
        <Frequencies form={form} />
        <Days disabled={!shouldDisplayDays} form={form} />
        <Times form={form} />
      </Group>
    </Stack>
  )
}

export function Question({
  form,
  index,
  question,
}: {
  form: UseFormReturnType<Pulse>
  index: number
  question: Partial<QuestionType>
}) {
  if (question._destroy === '1') {
    return ''
  }

  const { t: tra } = useTranslation()
  const questionTypes = [
    { label: tra('pulse:free_text'), value: 'free_text' },
    { label: tra('pulse:single_choice'), value: 'single_choice' },
    { label: tra('pulse:multiple_choice'), value: 'multiple_choice' },
    { label: tra('pulse:sentiment'), value: 'sentiment' },
  ]
  const setQuestionLabel = (label: string) => {
    form.setFieldValue(`questions.${index}.label`, label)
  }
  const setQuestionType = (questionType: string | null) => {
    form.setFieldValue(`questions.${index}.question_type`, questionType)
    if (questionType === 'free_text') {
      form.setFieldValue(`questions.${index}.meta.element`, 'textarea')
    } else {
      form.setFieldValue(`questions.${index}.meta.element`, 'select')
    }
  }
  const setQuestionChoices = (choices: string[]) => {
    form.setFieldValue(
      `questions.${index}.meta.choices`,
      choices.map((v) => v.trim()),
    )
  }

  const removeQuestion = () => {
    if (question.id) {
      form.setFieldValue(`questions.${index}._destroy`, '1')
    } else {
      form.setFieldValue(
        'questions',
        form.values.questions.filter((_q: any, i: number) => i !== index),
      )
    }
  }

  const showOptions = !['free_text', 'sentiment'].includes(
    question.question_type as string,
  )

  return (
    <Fieldset
      legend={`Question #${index + 1}`}
      style={{ marginBottom: '20px' }}
      variant="default"
    >
      <Stack>
        <TextInput
          label={tra('pulse:question_label')}
          leftSection={<IconEyeQuestion />}
          size="md"
          value={form.values.questions[index].label}
          onChange={(e) => setQuestionLabel(e.currentTarget.value)}
        />
        <Select
          data={questionTypes}
          label={tra('pulse:question_type_label')}
          size="md"
          value={form.values.questions[index].question_type}
          onChange={setQuestionType}
        />
        {showOptions ? (
          <TagsInput
            label="What are the options?"
            size="md"
            value={form.values.questions[index].meta?.choices}
            onChange={setQuestionChoices}
          />
        ) : (
          ''
        )}
        <Button
          style={{ alignSelf: 'flex-end' }}
          variant="light"
          onClick={removeQuestion}
        >
          {tra('pulse:remove_question')}
        </Button>
      </Stack>
    </Fieldset>
  )
}
