import { Button, Loader, NumberInput, Switch, TextInput } from '@mantine/core'
import { DateInput, TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { IconCalendarPlus } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { z } from 'zod'

import UserMultiSelect from '~/components/dashboard/user_multi_select'
import { Meeting } from '~/types/meeting'
import { User } from '~/types/user'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { Session } from '~/utils/session'
import validateForm from '~/utils/validate-form'

type NewMeetingFormProps = {
  attendeeIds?: string[]
  onSuccess?: (newMeeting: Meeting) => void
  session: Session
}

const newMeetingFormSchema = z.object({
  attendeeIds: z
    .string()
    .array()
    .min(1, { message: 'meetings:attendeeRequired' }),
  date: z.date(),
  duration: z.number(),
  kind: z.string(),
  time: z.string().min(1),
  title: z.string().min(1, { message: 'meetings:titleRequired' }),
})
type NewMeetingFormValues = z.infer<typeof newMeetingFormSchema>

function NewMeetingForm({
  attendeeIds,
  onSuccess,
  session,
}: NewMeetingFormProps) {
  const {
    data: users,
    error,
    isLoading,
  } = useSWR(attendeeIds ? null : `/api/v1/users`, async (url) => {
    return Fetcher.get<{}, User[]>(url).then((data) => {
      return data.filter((user) => user.id !== session.user.id)
    })
  })
  const { t: tra } = useTranslation()

  const form = useForm({
    initialValues: {
      attendeeIds: [] as string[],
      date: new Date(),
      duration: 30,
      kind: 'one_on_one',
      time: dayjs().format('HH:mm'),
      title: '',
    },
    validate: (values) => {
      return validateForm(newMeetingFormSchema, values)
    },
  })

  useEffect(() => {
    if (attendeeIds) {
      form.setFieldValue('attendeeIds', attendeeIds)
    }
  }, [attendeeIds])

  const onSubmit = async (formValues: NewMeetingFormValues) => {
    try {
      const meetingAttendees = attendeeIds || formValues.attendeeIds
      const plannedStartTime = formValues.date
        .toString()
        .replace(/\d{2}:\d{2}:\d{2}/, `${formValues.time}:00`)
      const payload = {
        duration: formValues.duration,
        kind: formValues.kind,
        meeting_attendees_attributes: meetingAttendees.map((attendee) => {
          return { user_id: attendee }
        }),
        planned_start_time: plannedStartTime,
        title: formValues.title,
      }

      const meeting = await Fetcher.post<{}, Meeting>(
        '/api/v1/meetings',
        payload,
      )
      if (onSuccess) {
        onSuccess(meeting)
      }
    } catch (e) {
      showErrorNotification(e)
    }
  }

  if (isLoading) {
    return <Loader data-testid="new-meeting-form-loader" />
  }

  if (error) {
    return <h1>ERROR</h1> // TODO: Replace this with error message
  }

  return (
    <form
      className="flexColumnForm"
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      {!attendeeIds && (
        <UserMultiSelect
          description={tra('newMeetingForm:attendeesDescription')}
          label={tra('newMeetingForm:attendeesLabel')}
          placeholder={tra('newMeetingForm:attendeesPlaceholder')}
          users={users || []}
          withAsterisk
          {...form.getInputProps('attendeeIds')}
        />
      )}
      <TextInput
        data-testid="new-meeting-form-title-input"
        description={tra('newMeetingForm:titleDescription')}
        label={tra('newMeetingForm:titleLabel')}
        placeholder={tra('newMeetingForm:titlePlaceholder')}
        withAsterisk
        {...form.getInputProps('title')}
      />
      <NumberInput
        data-testid="new-meeting-form-duration-input"
        description={tra('newMeetingForm:durationDescription')}
        label={tra('newMeetingForm:durationLabel')}
        placeholder={tra('newMeetingForm:durationPlaceholder')}
        withAsterisk
        {...form.getInputProps('duration')}
      />
      <DateInput
        data-testid="new-meeting-form-date-input"
        description={tra('newMeetingForm:dateDescription')}
        label={tra('newMeetingForm:dateLabel')}
        minDate={new Date()}
        valueFormat="YYYY-MM-DD"
        withAsterisk
        {...form.getInputProps('date')}
      />
      <TimeInput
        data-testid="new-meeting-form-time-input"
        description={tra('newMeetingForm:timeDescription')}
        label={tra('newMeetingForm:timeLabel')}
        placeholder={tra('newMeetingForm:timePlaceholder')}
        withAsterisk
        {...form.getInputProps('time')}
      />
      <Switch
        defaultChecked
        label="1:1 Meeting"
        onChange={(event) =>
          form.setFieldValue(
            'kind',
            event.currentTarget.checked ? 'one_on_one' : 'generic',
          )
        }
      />

      <Button
        data-testid="new-meeting-form-submit-button"
        leftSection={<IconCalendarPlus />}
        mt="sm"
        type="submit"
      >
        {tra('newMeetingForm:submit')}
      </Button>
    </form>
  )
}

export default NewMeetingForm
