import { Avatar, AvatarProps, Tooltip } from '@mantine/core'

import { User } from '~/types/user'

type LabeledAvatarProps = {
  user: User
} & AvatarProps

function LabeledAvatar({ user, ...props }: LabeledAvatarProps) {
  return (
    <Tooltip label={user.full_name}>
      <Avatar src={user.profile_picture_url} {...props} />
    </Tooltip>
  )
}

export default LabeledAvatar
