import { FormErrors } from '@mantine/form'
import i18next from 'i18next'
import { z, ZodIssue } from 'zod'

type ValidationResult<T> = {
  [K in keyof T]?: string | null
}

export default function validateForm<T extends Record<string, any>>(
  schema: z.AnyZodObject,
  values: T,
): FormErrors {
  const result: ValidationResult<T> = {}

  Object.keys(values).forEach((key) => {
    const field = schema.pick({ [key]: true })
    const fieldResult = field.safeParse({ [key]: values[key] })

    if (!fieldResult.success) {
      if (field instanceof z.ZodObject) {
        fieldResult.error.errors.forEach((err) => {
          result[err.path.join('.') as keyof T] = i18next.t(
            `errors:${err.message}`,
          )
        })
      } else {
        result[key as keyof T] = fieldResult.error.issues
          .map((err: ZodIssue) => {
            return i18next.t(`errors:${err.message}`)
          })
          .join(' ')
      }
    } else {
      result[key as keyof T] = null
    }
  })

  return result
}
