import { MultiSelect } from '@mantine/core'

import { Question } from '~/types/pulse'

interface MultipleChoiceQuestionProps {
  handleChange: (id: string, questionLabel: string, value: string) => void
  question: Question
}

function MultipleChoiceQuestion(props: MultipleChoiceQuestionProps) {
  const { handleChange, question } = props
  return (
    <MultiSelect
      data={question.meta.choices}
      label={question.label}
      placeholder="Select one or more options"
      size="md"
      onChange={(values) =>
        handleChange(question.id!, question.label, values.sort().join(','))
      }
    />
  )
}

export default MultipleChoiceQuestion
