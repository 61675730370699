import { LoadingOverlay, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import FeedbackList from '~/components/dashboard/feedback/feedback_list'
import type { Feedback } from '~/types/feedback'
import Fetcher from '~/utils/fetcher'

export default function FeedbackReceivedPage() {
  const { t: tra } = useTranslation()
  const { data: feedbacks, isLoading } = useSWR(
    `/api/v1/feedbacks/?received=true`,
    (url) => Fetcher.get<{}, Feedback[]>(url),
  )

  const feedbackTableColumns = {
    created_at: { header: tra('calendar:date'), value_from: 'feedback' },
    question: { header: tra('common:question'), value_from: 'response' },
    'receiver.full_name': {
      header: tra('feedback_requests:given_by'),
      value_from: 'feedback',
    },
    response: {
      header: tra('common:feedback'),
      transform: (response: string) => {
        return response.split('\n\n').map((p) => (
          <Text data-testid="feedback-response" style={{ marginBottom: 10 }}>
            {p}
          </Text>
        ))
      },
      value_from: 'response',
    },
  } as const

  if (isLoading || !feedbacks) {
    return <LoadingOverlay visible />
  }

  return (
    <FeedbackList
      columns={feedbackTableColumns}
      dataTestId="feedback-received-table"
      feedbacks={feedbacks}
    />
  )
}
