import { Button, Group, Stack, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import type { FeedbackRequest } from '~/types/feedback'
import Fetcher from '~/utils/fetcher'
import { feedbackFormSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

export type FeedbackFormSchema = z.infer<typeof feedbackFormSchema>

export default function FeedbackForm({
  onSubmit,
  request,
}: {
  onSubmit: () => void
  request: FeedbackRequest
}) {
  const { t: tra } = useTranslation()
  const form = useForm<FeedbackFormSchema>({
    initialValues: {
      feedback_request_id: request.id,
      is_draft: false,
      receiver_id: request.requester.id,
      responses_attributes: request.questions.map((q) => ({
        question: q.label,
        response: '',
      })),
    },
    validate: (values) => validateForm(feedbackFormSchema, values),
  })

  const submitForm = async (values: FeedbackFormSchema) => {
    await Fetcher.post('/api/v1/feedbacks', { feedback: values })
    onSubmit()
    modals.close('feedback_form_modal')
  }

  return (
    <form
      data-testid="feedback-form-modal"
      onSubmit={form.onSubmit(submitForm)}
    >
      <Stack gap="lg">
        {request.questions.map((question: { label: string }, index: number) => (
          <Textarea
            autosize
            data-testid={`feedback-request-question-${index}-textarea`}
            key={form.key(`responses_attributes.${index}.response`)}
            label={question.label}
            minRows={4}
            style={{ width: '100%' }}
            {...form.getInputProps(`responses_attributes.${index}.response`)}
          />
        ))}
        <Group justify="flex-end">
          <Button
            data-testid="save-draft-feedback-button"
            type="submit"
            onClick={() => form.setFieldValue('is_draft', true)}
          >
            {tra('feedback_requests:save_draft')}
          </Button>
          <Button
            data-testid="submit-feedback-button"
            type="submit"
            onClick={() => form.setFieldValue('is_draft', false)}
          >
            {tra('feedback_requests:submit_feedback')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
