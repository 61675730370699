import {
  Avatar,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Table,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import UserInviteForm from '~/components/dashboard/user_invite_form'
import adminClasses from '~/layouts/admin.module.css'
import EditUserForm from '~/pages/dashboard/admin/users/edit_form'
import { UserIndexView } from '~/types/user'
import { Role } from '~/utils/constants'
import Fetcher from '~/utils/fetcher'

import MoveReportsForm from './admin/users/move_employees'

function UsersTable({
  tra,
  users,
}: {
  tra: TFunction
  users: UserIndexView[]
}) {
  const [userList, setUserList] = useState<UserIndexView[]>(users)
  const [userToEdit, setUserToEdit] = useState<UserIndexView | null>(null)
  const [reportsToMove, setReportsToMove] = useState<UserIndexView | null>(null)
  const [isEditModalOpen, { close: closeEditModal, open: openEditModal }] =
    useDisclosure(false)
  const [
    isMoveReportsModalOpen,
    { close: closeMoveReportsModal, open: openMoveReportsModal },
  ] = useDisclosure(false)

  const editUser = (user: UserIndexView) => {
    setUserToEdit(user)
    openEditModal()
  }

  const moveReports = (user: UserIndexView) => {
    setReportsToMove(user)
    openMoveReportsModal()
  }

  const resendInvitation = async (user: UserIndexView) => {
    await Fetcher.post(`/api/v1/users/${user.id}/resend_invitation`)
  }

  return (
    <>
      {isEditModalOpen && (
        <Modal
          opened={isEditModalOpen}
          title={tra('admin:users:edit_user', { name: userToEdit?.email })}
          onClose={closeEditModal}
        >
          <EditUserForm
            tra={tra}
            user={userToEdit!}
            users={users}
            onUpdate={(updatedUser) => {
              setUserToEdit(null)
              setUserList(
                users.map((u) => (u.id === updatedUser.id ? updatedUser : u)),
              )
              closeEditModal()
            }}
          />
        </Modal>
      )}
      {isMoveReportsModalOpen && (
        <Modal
          opened={isMoveReportsModalOpen}
          title={tra('admin:users:move_reports', {
            name: reportsToMove?.email,
          })}
          onClose={closeMoveReportsModal}
        >
          <MoveReportsForm
            manager={reportsToMove!}
            managers={users}
            tra={tra}
            onUpdate={() => {
              setReportsToMove(null)
              closeMoveReportsModal()
            }}
          />
        </Modal>
      )}
      <Table
        highlightOnHover
        striped
        verticalSpacing="xs"
        withColumnBorders
        withTableBorder
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{tra('common:firstName').toLocaleUpperCase()}</Table.Th>
            <Table.Th>{tra('common:lastName').toLocaleUpperCase()}</Table.Th>
            <Table.Th>{tra('common:email').toLocaleUpperCase()}</Table.Th>
            <Table.Th>{tra('common:actions').toLocaleUpperCase()}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {userList?.map((user) => (
            <Table.Tr key={user.id}>
              <Table.Td>
                <Group>
                  <Avatar src={user.profile_picture_url}>
                    {user.first_name ? user.first_name.charAt(0) : '?'}
                  </Avatar>
                  {user.first_name}
                </Group>
              </Table.Td>
              <Table.Td>{user.last_name}</Table.Td>
              <Table.Td>{user.email}</Table.Td>
              <Table.Td>
                {user.active === false && (
                  <>
                    <Button onClick={() => resendInvitation(user)}>
                      {tra('admin:users:resend_invitation')}
                    </Button>
                    &nbsp;
                  </>
                )}
                <Button onClick={() => editUser(user)}>
                  {tra('common:edit')}
                </Button>
                &nbsp;
                {user.role !== Role.ADMIN && (
                  <Button onClick={() => moveReports(user)}>
                    {tra('admin:users:move_reports')}
                  </Button>
                )}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </>
  )
}

function UsersPage() {
  const [opened, { close, open }] = useDisclosure(false)
  const { t: tra } = useTranslation()

  const { data, isLoading } = useSWR('/api/v1/users', (url) =>
    Fetcher.get<{}, UserIndexView[]>(url),
  )

  if (isLoading) return <LoadingOverlay visible />

  return (
    <>
      {opened && (
        <UserInviteForm tra={tra} users={data ?? []} onClose={close} />
      )}
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('common:users')}</Title>
        <Button onClick={open}>{tra('admin:users:inviteUser')}</Button>
      </Group>
      {data && <UsersTable tra={tra} users={data} />}
    </>
  )
}

export default UsersPage
