import { Container, Group, rem } from '@mantine/core'
import { IconBallpen, IconOutbound, IconWriting } from '@tabler/icons-react'
import { Outlet } from 'react-router-dom'

import SecondNav, { NavItem } from '~/components/dashboard/second_nav'
import IconInbound from '~/icons/inbound'
import { useSession } from '~/utils/session'

const subNavItems: NavItem[] = [
  {
    i18nKey: 'nav:feedback_received',
    icon: <IconInbound />,
    url: 'received',
  },
  {
    i18nKey: 'nav:feedback_requests',
    icon: <IconBallpen size={24} />,
    url: 'requests',
  },

  {
    i18nKey: 'nav:feedback_given',
    icon: <IconOutbound size={24} />,
    url: 'given',
  },
  {
    i18nKey: 'nav:draft_feedback',
    icon: <IconWriting size={24} />,
    url: 'drafts',
  },
]

export default function FeedbackPage() {
  return (
    <Group className="fullHeight">
      <SecondNav
        data-testid="feedback-subnav"
        items={subNavItems}
        title="feedback"
      />
      <Container
        className="yScroll"
        fluid
        h="100%"
        pl={rem(192)}
        pt="md"
        w="100%"
      >
        <Outlet context={useSession()} />
      </Container>
    </Group>
  )
}
