import { Button, Card, Grid, Group, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconBrandBitbucket,
  IconBrandGithub,
  IconBrandGoogle,
  IconBrandSlack,
  IconBrandTeams,
} from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Integration, IntegrationType } from '~/types/integration'
import Fetcher from '~/utils/fetcher'

function IntegrationModal({
  closeModal,
  integrationType,
  opened,
  tra,
  url,
}: {
  closeModal: () => void
  integrationType: IntegrationType
  opened: boolean
  tra: TFunction
  url: string
}) {
  return (
    <Modal
      opened={opened}
      title={tra(`integrations:add_${integrationType}`)}
      onClose={closeModal}
    />
  )
}

function IntegrationCard({
  integration,
  integrationType,
  tra,
}: {
  integration?: Integration
  integrationType: IntegrationType
  tra: TFunction
}) {
  const integrationIcon = {
    github: <IconBrandGithub />,
    google: <IconBrandGoogle />,
    jira_cloud: <IconBrandBitbucket />,
    slack: <IconBrandSlack />,
    teams: <IconBrandTeams />,
  }
  const integrationUrls = {
    github: `${window.location.origin}/oauth/github/authorize`,
    google: `${window.location.origin}/oauth/google/authorize`,
    jira_cloud: `${window.location.origin}/oauth/jira_cloud/authorize`,
    slack:
      'https://slack.com/oauth/v2/authorize?scope=team%3Aread%2Cusers%3Aread%2Cusers.profile%3Aread&user_scope=users.profile%3Aread%2Cusers%3Aread.email%2Cusers%3Aread&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth%2Fslack%2Finstallation_callback&client_id=5839641975265.5992495016386',
    teams: `${window.location.origin}/oauth/teams/authorize`,
  }

  const [opened, { close: closeModal }] = useDisclosure(false)

  const openIntegrationModal = (selectedIntegrationType: IntegrationType) => {
    return () => {
      const popWidth = 600
      const popHeight = 800
      const leftPos = window.screen.width / 2 - popWidth / 2
      const topPos = window.screen.height / 2 - popHeight / 2

      window.open(
        integrationUrls[selectedIntegrationType],
        integrationType,
        `width=600,height=800,left=${leftPos},top=${topPos}`,
      )
    }
  }

  return (
    <>
      <Card padding="md" radius="md" shadow="sm" withBorder>
        <Card.Section inheritPadding py="xs" withBorder>
          <Group gap="xl" justify="space-between">
            <Text fw={500}>{tra(`integrations:${integrationType}`)}</Text>
            {integrationIcon[integrationType]}
          </Group>
        </Card.Section>
        <Text mt="sm" size="sm">
          {tra(`integrations:${integrationType}_description`)}
        </Text>
        <Card.Section inheritPadding py="xs" withBorder>
          <Button
            fullWidth
            leftSection={integrationIcon[integrationType]}
            onClick={openIntegrationModal(integrationType)}
          >
            {tra(`integrations:add_${integrationType}`)}
          </Button>
        </Card.Section>
      </Card>
      <IntegrationModal
        closeModal={closeModal}
        integrationType={integrationType}
        opened={opened}
        tra={tra}
        url={integrationUrls[integrationType]}
      />
    </>
  )
}

export default function IntegrationsPage() {
  const { t: tra } = useTranslation()
  const { data: currentIntegrations, isLoading } = useSWR(
    [`/api/v1/integrations`],
    ([url]) => Fetcher.get<{}, Record<IntegrationType, Integration>>(url),
  )
  const integrationTypes: IntegrationType[] = [
    'github',
    'google',
    'jira_cloud',
    'slack',
    'teams',
  ]
  const tenantIntegrations =
    currentIntegrations || ({} as Record<IntegrationType, Integration>)

  return (
    <div>
      <h1>{tra('integrations:label')}</h1>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ width: '30%' }}>
          <Grid columns={2} justify="space-between">
            {integrationTypes?.map((integrationType) => (
              <Grid.Col key={integrationType} span={1}>
                <IntegrationCard
                  integration={tenantIntegrations[integrationType]}
                  integrationType={integrationType}
                  tra={tra}
                />
              </Grid.Col>
            ))}
          </Grid>
        </div>
      )}
    </div>
  )
}
