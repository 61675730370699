import { Divider, Loader, Stack, Tabs, Title } from '@mantine/core'
import useSWRImmutable from 'swr/immutable'

import MeetingUserInfo from '~/components/dashboard/meeting_user_info'
import ProfileTabs from '~/components/dashboard/profile_tabs'
import { MeetingDetailView } from '~/types/meeting'
import { MeetingKind } from '~/types/meeting_kind'
import { MeetingStatus } from '~/types/meeting_status'
import { User, UserProfileView } from '~/types/user'
import Fetcher from '~/utils/fetcher'

import { getOtherUser } from './meeting_helper'
import classes from './scheduled_meeting.module.css'
import MeetingsTab from './team/meetings_tab'
import PulsesTab from './team/pulses_tab'
import TalkingPointsTab from './team/talking_points_tab'
import TodosTab from './team/todos_tab'

function OnoMeeting({
  currentUser,
  meetingDetail,
}: {
  currentUser: User
  meetingDetail: MeetingDetailView
}) {
  const otherUser = getOtherUser(meetingDetail, currentUser.id)

  const { data, error, isLoading } = useSWRImmutable(
    `/api/v1/users/${otherUser.id}/profile`,
    (url) => Fetcher.get<{}, UserProfileView>(url),
  )

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <h1>ERROR</h1>
  }

  if (data) {
    return (
      <Stack className={classes.onoRightPanel} h="100%">
        <Tabs
          defaultValue="talking_points"
          h="100%"
          keepMounted={false}
          orientation="vertical"
          radius="0"
          style={{
            flex: 1,
          }}
        >
          <ProfileTabs
            defaultValue="talking_points"
            displayTabHeader={false}
            userProfile={data}
          />

          <Tabs.Panel p="sm" value="profile">
            <MeetingUserInfo userProfile={data} />
          </Tabs.Panel>

          <Tabs.Panel p="sm" value="pulses">
            <Title order={4}>Pulses</Title>
            <Divider />
            <PulsesTab userId={otherUser.id} />
          </Tabs.Panel>

          <Tabs.Panel p="sm" value="meetings">
            <Title order={4}>Previous 1-1 meetings</Title>
            <Divider />

            <MeetingsTab
              attendeeId={otherUser.id}
              kind={MeetingKind.ONO}
              status={MeetingStatus.FINISHED}
            />
          </Tabs.Panel>
          <Tabs.Panel p="sm" value="talking_points">
            <Title order={4}>Awaiting talking points</Title>
            <Divider />

            <TalkingPointsTab
              addresseeId={otherUser.id}
              completed={false}
              creatorId={currentUser.id}
              currentMeetingId={meetingDetail.id}
              meetingId={null}
            />
          </Tabs.Panel>

          <Tabs.Panel h="100%" p="sm" value="todos">
            <Title order={4}>Action items</Title>
            <Divider />
            <TodosTab direction="vertical" userId={otherUser.id} />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    )
  }

  return null
}

export default OnoMeeting
