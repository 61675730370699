import {
  Button,
  Grid,
  GridCol,
  Group,
  LoadingOverlay,
  MultiSelect,
  Stack,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconEyeQuestion, IconUsers } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import adminClasses from '~/layouts/admin.module.css'
import { Pulse, Question as QuestionType } from '~/types/pulse'
import { VNode } from '~/types/vnode'
import { newPulseFormSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

import { Occurrence, Question } from './common'

type PulseFormProps = {
  isLoading: boolean
  nodes: VNode[] | undefined
  onSave: (pulse: Pulse) => Promise<void>
  pulse: Pulse | undefined
}

function PulseForm({ isLoading, nodes, onSave, pulse }: PulseFormProps) {
  const { t: tra } = useTranslation()
  const emptyQuestion: Partial<QuestionType> = {
    id: undefined,
    label: '',
    meta: {
      choices: [],
      element: '',
    },
    order: 0,
    question_type: 'free_text',
  }

  const form = useForm<Pulse>({
    initialValues: {
      id: undefined,
      nodes: [],
      occurrence: {
        day: null,
        frequency: null,
        time: null,
      },
      questions: [emptyQuestion],
      title: '',
    },
    validate: (values) => validateForm(newPulseFormSchema, values),
  })

  useEffect(() => {
    if (pulse !== undefined) {
      form.setValues(pulse)
    }
  }, [pulse])

  const saveForm = async () => {
    await onSave(form.values)
  }

  const { questions } = form.values

  if (isLoading) {
    return <LoadingOverlay />
  }

  const addNewQuestion = () => {
    form.setFieldValue('questions', [...form.values.questions, emptyQuestion])
  }

  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>
          {pulse === undefined ? tra('pulse:create') : tra('pulse:edit')}
        </Title>
        <Button onClick={saveForm}>{tra('common:save')}</Button>
      </Group>
      <Grid gutter="md" style={{ marginTop: '10px' }}>
        <GridCol span={7}>
          {questions.map((question, index) => (
            <Question
              form={form}
              index={index}
              key={question.id ?? index + 1}
              question={question}
            />
          ))}
          <Button onClick={addNewQuestion}>
            {tra('pulse:add_another_question')}
          </Button>
        </GridCol>
        <GridCol span={5}>
          <Stack gap="lg">
            <TextInput
              label={tra('pulse:title_label')}
              leftSection={<IconEyeQuestion />}
              placeholder={tra('common:title')}
              size="md"
              {...form.getInputProps('title')}
            />
            <MultiSelect
              data={nodes?.map((node) => {
                return { label: node.name, value: node.id }
              })}
              label={tra('pulse:nodes_label')}
              leftSection={<IconUsers />}
              placeholder={tra('pulse:select_a_team')}
              searchable
              size="md"
              value={form.values.nodes as string[]}
              onChange={(nodeIds: string[]) =>
                form.setFieldValue('nodes', nodeIds)
              }
            />
            <Occurrence form={form} />
          </Stack>
        </GridCol>
      </Grid>
    </>
  )
}

export default PulseForm
