import { Avatar, Group, List, Stack, Text } from '@mantine/core'
import { IconCheck, IconSquare } from '@tabler/icons-react'

import { AgendaItemIndexView } from '~/types/agenda_item'

import LabeledAvatar from './labeled_avatar'
import classes from './meeting_agenda.module.css'

type MeetingAgendaProps = {
  activeItemId: string
  agendaItems: AgendaItemIndexView[]
  goTo: (itemId?: string) => void
}

function AgendaItem({
  goTo,
  isActive,
  item,
}: {
  goTo: (itemId?: string) => void
  isActive: boolean
  item: AgendaItemIndexView
}) {
  const isCompleted = !!item.completed_at

  return (
    <List.Item
      icon={
        isCompleted ? (
          <Avatar color="brand" ml="sm" size="sm">
            <IconCheck />
          </Avatar>
        ) : (
          <LabeledAvatar ml="sm" size="sm" user={item.creator} />
        )
      }
      onClick={() => goTo(item.id)}
    >
      <Group
        align="start"
        gap="0"
        justify="space-between"
        w="100%"
        wrap="nowrap"
      >
        <Stack gap="0">
          <Text
            c={isActive ? 'black' : 'gray'}
            fw={isActive ? 'bold' : 'normal'}
            td={isCompleted ? 'line-through' : ''}
          >
            {item.title}
          </Text>
          <Text c="gray" fz="sm" td={isCompleted ? 'line-through' : ''}>
            {item.description}
          </Text>
        </Stack>
        <Text>
          <IconSquare size="1rem" />5
        </Text>
      </Group>
    </List.Item>
  )
}

function sortAgendaItems(agendaItems: AgendaItemIndexView[]) {
  return agendaItems.sort((a, b) => {
    if (a.completed_at && b.completed_at) {
      // Both items are completed, sort by completed_at ascending
      return Date.parse(a.completed_at) - Date.parse(b.completed_at)
    }
    if (a.completed_at) {
      // Only a is completed, it should come first
      return -1
    }
    if (b.completed_at) {
      // Only b is completed, it should come first
      return 1
    }
    // Neither item is completed, sort by order ascending
    // TODO: Fix this
    return (a.order || 1) - (b.order || 0)
  })
}

function MeetingAgenda(props: MeetingAgendaProps) {
  const { activeItemId, agendaItems, goTo } = props

  return (
    <List
      classNames={{
        itemLabel: classes.itemLabel,
        itemWrapper: classes.itemWrapper,
        root: classes.root,
      }}
      spacing="sm"
    >
      {sortAgendaItems(agendaItems).map((agendaItem) => (
        <AgendaItem
          goTo={goTo}
          isActive={agendaItem.id === activeItemId}
          item={agendaItem}
          key={agendaItem.id}
        />
      ))}
    </List>
  )
}

export default MeetingAgenda
