import {
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Stack,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconEyeQuestion, IconUsers } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import adminClasses from '~/layouts/admin.module.css'
import { Checkin } from '~/types/checkin'
import { VNode } from '~/types/vnode'
import { newCheckinFormSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

import { Occurrence } from './common'

type CheckinFormProps = {
  checkin: Checkin | undefined
  isLoading: boolean
  nodes: VNode[] | undefined
  onSave: (checkin: Partial<Checkin>) => Promise<void>
}

function CheckinForm({ checkin, isLoading, nodes, onSave }: CheckinFormProps) {
  const { t: tra } = useTranslation()

  const form = useForm<Checkin>({
    initialValues: {
      checkin_users: undefined,
      id: undefined,
      is_paused: false,
      nodes: [],
      occurrence: {
        day: null,
        frequency: null,
        time: null,
      },
      question: '',
      users_count: undefined,
    },
    validate: (values) => validateForm(newCheckinFormSchema, values),
  })

  useEffect(() => {
    if (checkin !== undefined) {
      form.setValues(checkin)
    }
  }, [checkin])

  const saveForm = async () => {
    await onSave(form.values)
  }

  const stopAsking = async () => {
    await onSave({ ...form.values, is_paused: true })
  }

  const startAsking = async () => {
    await onSave({ ...form.values, is_paused: false })
  }

  if (isLoading) {
    return <LoadingOverlay />
  }

  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>
          {checkin === undefined ? tra('checkin:create') : tra('checkin:edit')}
        </Title>
        <Group>
          {checkin?.is_paused ? (
            <Button onClick={startAsking}>
              {tra('checkins:start_asking')}
            </Button>
          ) : (
            <Button onClick={stopAsking}>{tra('checkins:stop_asking')}</Button>
          )}

          <Button onClick={saveForm}>{tra('common:save')}</Button>
        </Group>
      </Group>
      <Stack gap="md" style={{ marginTop: '10px' }}>
        <TextInput
          label={tra('checkin:question_label')}
          leftSection={<IconEyeQuestion />}
          size="md"
          value={form.values.question}
          onChange={(e) =>
            form.setFieldValue('question', e.currentTarget.value)
          }
        />
        <MultiSelect
          data={nodes?.map((node) => {
            return { label: node.name, value: node.id }
          })}
          label={tra('checkin:nodes_label')}
          leftSection={<IconUsers />}
          placeholder={tra('checkin:select_a_team')}
          searchable
          size="md"
          value={form.values.nodes as string[]}
          onChange={(nodeIds: string[]) => form.setFieldValue('nodes', nodeIds)}
        />
        <Occurrence form={form} />
      </Stack>
    </>
  )
}

export default CheckinForm
