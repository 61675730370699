import {
  Button,
  Group,
  LoadingOverlay,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import JobFunctionForm from '~/components/dashboard/jobs/job_function_form'
import adminClasses from '~/layouts/admin.module.css'
import type { JobFunction, JobFunctionsResponse } from '~/types/jobs'
import Fetcher from '~/utils/fetcher'

export default function JobFunctionsPage() {
  const { t: tra } = useTranslation()
  const {
    data: jobFunctionsResponse,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/job_functions`, (url) =>
    Fetcher.get<{}, JobFunctionsResponse>(url),
  )

  const { job_functions: jobFunctions, user_counts: userCounts } =
    jobFunctionsResponse ?? {}

  const onJobFunctionSave = () => {
    mutate()
    modals.close('job_function_modal')
  }

  const openJobFunctionForm = (jobFunction?: JobFunction) =>
    modals.open({
      children: (
        <JobFunctionForm jobFunction={jobFunction} onSave={onJobFunctionSave} />
      ),
      modalId: 'job_function_modal',
      title: tra('jobs:edit_function'),
    })

  const deleteJobFunction = async (jobFunction: JobFunction) => {
    modals.openConfirmModal({
      children: [<Text>{tra('jobs:delete_function_confirmation')}</Text>],
      confirmProps: { color: 'red' },
      labels: { cancel: tra('common:cancel'), confirm: tra('common:delete') },
      onConfirm: async () => {
        await Fetcher.delete(`/api/v1/job_functions/${jobFunction.id}`)
        mutate()
      },
      title: tra('jobs:delete_function'),
    })
  }

  const tableData = jobFunctions?.map((jobFunction: JobFunction) => (
    <Table.Tr key={jobFunction.id}>
      <Table.Td>{jobFunction.name}</Table.Td>
      <Table.Td>
        {userCounts === undefined ? 0 : userCounts[jobFunction.id] ?? 0}
      </Table.Td>
      <Table.Td>
        <Group>
          <Button onClick={() => openJobFunctionForm(jobFunction)}>
            {tra('common:edit')}
          </Button>
          <Button onClick={() => deleteJobFunction(jobFunction)}>
            {tra('common:delete')}
          </Button>
        </Group>
      </Table.Td>
    </Table.Tr>
  ))

  if (isLoading) {
    return <LoadingOverlay visible />
  }
  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('jobs:menu_title')}</Title>
        <Button onClick={() => openJobFunctionForm()}>
          {tra('jobs:add_new_function')}
        </Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{tra('jobs:function_name')}</Table.Th>
            <Table.Th>{tra('jobs:employee_count')}</Table.Th>
            <Table.Th>{tra('common:actions')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {jobFunctions !== undefined && jobFunctions.length > 0 ? (
            tableData
          ) : (
            <Table.Tr>
              <Table.Td colSpan={3}>{tra('jobs:no_functions_found')}</Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </>
  )
}
