import { Button, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import type { JobFunction } from '~/types/jobs'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const jobFunctionFormSchema = z.object({
  name: z.string().min(1),
})

type JobFunctionFormSchema = z.infer<typeof jobFunctionFormSchema>

export default function JobFunctionForm({
  jobFunction,
  onSave,
}: {
  jobFunction?: JobFunction
  onSave?: () => void
}) {
  const { t: tra } = useTranslation()
  const form = useForm<JobFunctionFormSchema>({
    initialValues: jobFunction ?? {
      name: '',
    },
    validate: (values) => validateForm(jobFunctionFormSchema, values),
  })

  const submitForm = async (values: JobFunctionFormSchema) => {
    if (jobFunction?.id) {
      // Update job function
      await Fetcher.put(`/api/v1/job_functions/${jobFunction.id}`, {
        job_function: values,
      })
    } else {
      await Fetcher.post('/api/v1/job_functions', { job_function: values })
    }
    onSave?.()
  }

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <Stack gap="lg">
        <TextInput
          label={tra('jobs:job_function_name')}
          placeholder="Enter job function name"
          required
          {...form.getInputProps('name')}
        />
        <Button type="submit" variant="outline">
          {jobFunction ? tra('common:update') : tra('common:create')}
        </Button>
      </Stack>
    </form>
  )
}
