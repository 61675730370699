import { Drawer } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import TodoForm from '~/components/dashboard/todo_form'
import { Todo } from '~/types/todo'
import { User } from '~/types/user'

type TodoDrawerProps = {
  agendaItemId?: string
  onClose: () => void
  onSuccess: (todo: Todo) => void
  opened: boolean
  todo?: Todo
  users: User[]
}
function TodoDrawer(props: TodoDrawerProps) {
  const { agendaItemId, onClose, onSuccess, opened, todo, users } = props
  const { t: tra } = useTranslation()

  return (
    <Drawer
      opened={opened}
      position="right"
      title={
        todo && todo.id
          ? tra('todoForm:editTitle')
          : tra('todoForm:createTitle')
      }
      onClose={onClose}
    >
      <TodoForm
        agendaItemId={agendaItemId}
        todo={todo}
        users={users}
        onSuccess={onSuccess}
      />
    </Drawer>
  )
}

export default TodoDrawer
