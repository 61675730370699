import { Button, Group, LoadingOverlay, Table, Title } from '@mantine/core'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import adminClasses from '~/layouts/admin.module.css'
import { Checkin } from '~/types/checkin'
import Fetcher from '~/utils/fetcher'

function CheckinsTable({
  checkins,
  tra,
}: {
  checkins: Checkin[]
  tra: TFunction
}) {
  const navigate = useNavigate()
  const deleteCheckin = async (checkinId: string | undefined) => {
    if (checkinId === undefined) {
      return
    }
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(tra('checkin:are_you_sure_delete'))) {
      await Fetcher.delete(`/api/v1/checkins/${checkinId}`)
    }
  }

  return (
    <Table
      highlightOnHover
      striped
      verticalSpacing="xs"
      withColumnBorders
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{tra('common:question').toLocaleUpperCase()}</Table.Th>
          <Table.Th>{tra('checkins:occurrence').toLocaleUpperCase()}</Table.Th>
          <Table.Th>{tra('checkins:receivers').toLocaleUpperCase()}</Table.Th>
          <Table.Th>{tra('common:actions').toLocaleUpperCase()}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {checkins?.map((checkin) => (
          <Table.Tr key={checkin.id}>
            <Table.Td>{checkin.question}</Table.Td>
            <Table.Td>
              {tra(
                `checkins:occurrence_description_${checkin.occurrence.frequency}`,
                {
                  day: checkin.occurrence.day,
                  time: checkin.occurrence.time,
                },
              )}
            </Table.Td>
            <Table.Td>
              {tra('checkins:num_receivers', {
                count: checkin.users_count,
              })}
            </Table.Td>
            <Table.Td>
              <Button onClick={() => navigate(`${checkin.id}/edit`)}>
                Edit
              </Button>

              <Button onClick={() => deleteCheckin(checkin.id)}>Delete</Button>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}

function AdminCheckinsPage() {
  const { t: tra } = useTranslation()
  const { data, isLoading } = useSWR([`/api/v1/checkins`], ([url]) =>
    Fetcher.get<{}, Checkin[]>(url),
  )
  const navigate = useNavigate()

  if (isLoading) return <LoadingOverlay visible />
  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('common:checkins')}</Title>
        <Button onClick={() => navigate('new')}>{tra('checkin:create')}</Button>
      </Group>
      {data && <CheckinsTable checkins={data} tra={tra} />}
    </>
  )
}

export default AdminCheckinsPage
