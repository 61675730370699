import { Button, Container, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconArrowRight, IconAt, IconLock } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import ErrorAlert, { hasFormAnyErrors } from '~/components/shared/error_alert'
import PageHeader from '~/components/shared/page_header'
import TopBar from '~/components/shared/top_bar'
import VoynPasswordInput from '~/components/shared/voyn_password_input'
import classes from '~/pages/sign-up/get_started.module.css'
import Fetcher from '~/utils/fetcher'
import { getCurrentLanguage, getTimezoneFromBrowser } from '~/utils/i18n'
import { emailSchema, passwordSchema } from '~/utils/schemas'
import validateForm from '~/utils/validate-form'

const getStartedFormSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
})

type GetStartedFormSchema = z.infer<typeof getStartedFormSchema>
type GetStartedRequestSchema = GetStartedFormSchema & {
  language: string
  timezone: string
}

function GetStartedPage() {
  const { t: tra } = useTranslation()

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    onValuesChange: () => {
      form.clearFieldError('api')
    },
    validate: (values) => {
      return validateForm(getStartedFormSchema, values)
    },
  })

  const onSubmit = async (formData: GetStartedFormSchema) => {
    const payload = {
      email: formData.email.toLowerCase(),
      language: getCurrentLanguage().code,
      password: formData.password,
      timezone: getTimezoneFromBrowser(),
    }

    try {
      await Fetcher.post<GetStartedRequestSchema, {}>(
        '/api/v1/sign_up',
        payload,
      )

      window.location.assign('/onboarding')
    } catch (e) {
      form.setErrors({ api: <ErrorAlert error={e} radius="xl" /> })
    }
  }

  const getStartedForm = (
    <form
      className={classes.registrationForm}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <TextInput
        label={tra('common:email')}
        leftSection={<IconAt />}
        placeholder={tra('common:emailPlaceholder')}
        radius="xl"
        size="lg"
        withAsterisk
        {...form.getInputProps('email')}
      />
      <VoynPasswordInput
        label={tra('common:password')}
        leftSection={<IconLock />}
        placeholder={tra('common:passwordPlaceholder')}
        radius="xl"
        size="lg"
        withAsterisk
        {...form.getInputProps('password')}
      />
      {form.errors.api}
      <Button
        disabled={hasFormAnyErrors(form.errors)}
        radius="xl"
        rightSection={<IconArrowRight />}
        size="lg"
        type="submit"
      >
        {tra('getStarted:cta')}
      </Button>
    </form>
  )

  return (
    <>
      <PageHeader title="getStarted:pageTitle" />
      <div className={classes.registrationContainer}>
        <TopBar />
        <Container className={classes.registrationFormContainer}>
          <Title mb="xs">{tra('getStarted:header')}</Title>
          <Text size="lg">{tra('getStarted:description')}</Text>
          {getStartedForm}
        </Container>
      </div>
    </>
  )
}

export default GetStartedPage
