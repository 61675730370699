export function arrayRange(
  start: number,
  stop: number,
  step: number = 1,
): number[] {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_value, index) => start + index * step,
  )
}

export function zip<T, U>(a: T[], b: U[]): [T, U][] {
  return a.map((e, i) => [e, b[i]])
}
export function combinations<T, U>(arr1: T[], arr2: U[]): [T, U][] {
  return arr1.flatMap((a) => arr2.map((b) => [a, b])) as [T, U][]
}
