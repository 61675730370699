import {
  Avatar,
  Group,
  Menu,
  Paper,
  Stack,
  Tabs,
  Text,
  Timeline,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import {
  IconBuilding,
  IconCalendar,
  IconCalendarPlus,
  IconDotsVertical,
  IconMessagePlus,
  IconSettings,
  IconTextPlus,
} from '@tabler/icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBrain } from 'react-icons/fa'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'

import AgendaItemForm from '~/components/dashboard/agenda_item_form'
import { openNewMeetingModal } from '~/components/dashboard/new_action_modal'
import ProfileTabs from '~/components/dashboard/profile_tabs'
import ErrorAlert from '~/components/shared/error_alert'
import VoynLoadingOverlay from '~/components/shared/loading'
import ManagerNotesTab from '~/pages/dashboard/team/manager_notes_tab'
import MeetingsTab from '~/pages/dashboard/team/meetings_tab'
import OneOnOneSettingsModal from '~/pages/dashboard/team/one_on_one_settings_modal'
import OverviewTab from '~/pages/dashboard/team/overview_tab'
import PulsesTab from '~/pages/dashboard/team/pulses_tab'
import TalkingPointsTab from '~/pages/dashboard/team/talking_points_tab'
import TodosTab from '~/pages/dashboard/team/todos_tab'
import { ManagerNoteDetailView } from '~/types/manager_note'
import { UserProfileView } from '~/types/user'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function MemberProfilePage() {
  const session = useSession()
  const { user: currentUser } = session
  const { id: userId } = useParams()
  const navigate = useNavigate()
  const { t: tra } = useTranslation()
  const [selectedManagerNote, setSelectedManagerNote] =
    useState<ManagerNoteDetailView | null>(null)
  const [
    managerNoteModalOpened,
    { close: closeManagerNoteModal, open: openManagerNoteModal },
  ] = useDisclosure(false)
  const [
    isOneOnOneSettingsModalOpened,
    { close: closeOneOnOneSettingsModal, open: openOneOneOneSettingsModal },
  ] = useDisclosure(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('active_tab') || 'overview'

  const openAgendaItemModal = () => {
    modals.open({
      children: (
        <AgendaItemForm
          addresseeId={userId}
          onSuccess={(newItem) => {
            modals.closeAll()
          }}
        />
      ),
      title: 'Add new talking point',
    })
  }

  const {
    data,
    error,
    isLoading,
    mutate: mutateUserProfile,
  } = useSWRImmutable(`/api/v1/users/${userId}/profile`, (url) =>
    Fetcher.get<{}, UserProfileView>(url),
  )

  const createMeeting = async () => {
    try {
      const meeting: { id: string } = await Fetcher.post('/api/v1/meetings', {
        meeting_attendees_attributes: [
          { user_id: userId },
          { user_id: currentUser.id },
        ],
        title: 'Ad-hoc meeting',
      })
      navigate(`/dashboard/meetings/${meeting.id}`)
    } catch (err) {
      console.log('err', err)
      showErrorNotification(err)
    }
  }

  if (isLoading) {
    return <VoynLoadingOverlay />
  }

  if (!data || error) {
    return <ErrorAlert error={error} />
  }

  return (
    <>
      {isOneOnOneSettingsModalOpened && (
        <OneOnOneSettingsModal
          opened={isOneOnOneSettingsModalOpened}
          tra={tra}
          user={data}
          onClose={() => closeOneOnOneSettingsModal()}
        />
      )}
      <Paper p="md" withBorder>
        <Group align="flex-start" justify="space-between">
          <Group>
            <Avatar size="xl" src={data.profile_picture_url} />
            <Stack gap="0.25rem">
              <Title mt="sm" order={4}>
                {data.full_name}
              </Title>
              {data.nodes.map((node) => (
                <Text c="dimmed" key={node.id} size="sm">
                  <IconBuilding size="0.75rem" /> {node.name}
                </Text>
              ))}
              <Text c="dimmed" size="sm">
                <IconCalendar size="0.75rem" /> Next Meeting with You:
              </Text>
            </Stack>
          </Group>
          <Menu position="bottom-end" shadow="md" trigger="hover" withArrow>
            <Menu.Target>
              <IconDotsVertical />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconCalendarPlus />}
                onClick={() => openNewMeetingModal(session, [data.id])}
              >
                Create meeting
              </Menu.Item>
              <Menu.Item
                leftSection={<IconMessagePlus />}
                onClick={() => openAgendaItemModal()}
              >
                Create talking item
              </Menu.Item>
              <Menu.Item
                leftSection={<IconTextPlus />}
                onClick={() => openManagerNoteModal()}
              >
                Create new manager note
              </Menu.Item>
              <Menu.Item
                leftSection={<IconSettings />}
                onClick={() => openOneOneOneSettingsModal()}
              >
                One-on-one settings
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Paper>
      <Tabs
        defaultValue="overview"
        h="100%"
        keepMounted={false}
        mt="sm"
        value={activeTab}
        onChange={(val) => {
          if (val) {
            setSearchParams({ active_tab: val })
          }
        }}
      >
        <ProfileTabs displayTabHeader userProfile={data} />
        <Tabs.Panel value="overview">
          <OverviewTab userId={userId!} />
        </Tabs.Panel>

        <Tabs.Panel value="notes">
          <ManagerNotesTab
            openManagerNoteModal={openManagerNoteModal}
            setSelectedManagerNote={setSelectedManagerNote}
            userId={userId!}
          />
        </Tabs.Panel>
        <Tabs.Panel value="talking_points">
          <TalkingPointsTab
            addresseeId={userId!}
            completed={false}
            meetingId={undefined}
          />
        </Tabs.Panel>

        <Tabs.Panel value="github">
          <Timeline active={1} bulletSize={24} lineWidth={2} mt="md">
            <Timeline.Item bullet={<FaBrain size={12} />} title="New branch">
              <Text c="dimmed" size="sm">
                You&apos;ve created new branch{' '}
                <Text component="span" inherit variant="link">
                  fix-notifications
                </Text>{' '}
                from master
              </Text>
              <Text mt={4} size="xs">
                2 hours ago
              </Text>
            </Timeline.Item>

            <Timeline.Item bullet={<FaBrain size={12} />} title="Commits">
              <Text c="dimmed" size="sm">
                You&apos;ve pushed 23 commits to
                <Text component="span" inherit variant="link">
                  fix-notifications branch
                </Text>
              </Text>
              <Text mt={4} size="xs">
                52 minutes ago
              </Text>
            </Timeline.Item>

            <Timeline.Item
              bullet={<FaBrain size={12} />}
              lineVariant="dashed"
              title="Pull request"
            >
              <Text c="dimmed" size="sm">
                You&apos;ve submitted a pull request
                <Text component="span" inherit variant="link">
                  Fix incorrect notification message (#187)
                </Text>
              </Text>
              <Text mt={4} size="xs">
                34 minutes ago
              </Text>
            </Timeline.Item>

            <Timeline.Item bullet={<FaBrain size={12} />} title="Code review">
              <Text c="dimmed" size="sm">
                <Text component="span" inherit variant="link">
                  Robert Gluesticker
                </Text>{' '}
                left a code review on your pull request
              </Text>
              <Text mt={4} size="xs">
                12 minutes ago
              </Text>
            </Timeline.Item>
          </Timeline>
        </Tabs.Panel>
        <Tabs.Panel value="pulses">
          <PulsesTab userId={userId!} />
        </Tabs.Panel>
        <Tabs.Panel value="meetings">
          <MeetingsTab attendeeId={userId!} />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="todos">
          <TodosTab userId={userId!} />
        </Tabs.Panel>
        <Tabs.Panel value="jira">Jira</Tabs.Panel>
      </Tabs>
    </>
  )
}

export default MemberProfilePage
