import { useOutletContext } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'

import { Tenant } from '~/types/tenant'
import { User } from '~/types/user'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'

export const homePath = '/dashboard/home'

export type Session = {
  nodes: VNode[]
  tenant: Tenant
  user: User
}

interface SessionResponse {
  error: string | null
  isLoading: boolean
  session: Session | undefined
}

export function fetchSession(): SessionResponse {
  const { data: session, error } = useSWRImmutable<Session>(
    '/api/v1/users/me',
    (url) => Fetcher.get<{}, Session>(url),
  )

  const isLoading = !error && !session

  return {
    error,
    isLoading,
    session,
  }
}

export async function logout() {
  await Fetcher.delete('/api/v1/sessions')
  window.location.assign('/login')
}

export function useSession() {
  return useOutletContext<Session>()
}

export function isUserToBeOnboarded(session: Session) {
  return session && !session.tenant
}

export function isUserToBeTakenDashboard(session: Session) {
  return (
    session &&
    session.tenant &&
    session.user.first_name &&
    session.user.last_name
  )
}
