import { Button, Loader, Select, Textarea, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { IconMessagePlus } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import UserSingleSelect from '~/components/dashboard/user_single_select'
import {
  AgendaItem,
  AgendaItemFormSchemaType,
  AgendaItemIndexView,
} from '~/types/agenda_item'
import { User } from '~/types/user'
import { priorities } from '~/utils/constants'
import { showErrorNotification } from '~/utils/error'
import Fetcher from '~/utils/fetcher'
import { agendaItemFormSchema } from '~/utils/schemas'

type AgendaItemFormProps = {
  addresseeId?: string
  agendaItem?: AgendaItem
  meetingId?: string
  onSuccess: (agendaItem: AgendaItemIndexView) => void
}

type AgendaItemRequestParams = {
  addressee_id?: string
  description: string
  meeting_id?: string
  priority: string
  title: string
}

export default function AgendaItemForm(props: AgendaItemFormProps) {
  const { addresseeId, agendaItem, meetingId, onSuccess } = props
  const isAddresseeKnown = addresseeId || meetingId
  const { t: tra } = useTranslation()

  const {
    data: users,
    error,
    isLoading,
  } = useSWR(
    isAddresseeKnown ? null : `/api/v1/users`,
    (url) => Fetcher.get<{}, User[]>(url),
    { fallbackData: [] },
  )

  const form = useForm({
    initialValues: {
      addresseeId: agendaItem?.addressee_id ?? addresseeId,
      description: agendaItem?.description ?? '',
      priority: agendaItem?.priority ?? 'medium',
      title: agendaItem?.title ?? '',
    },
    validate: zodResolver(agendaItemFormSchema),
  })

  const onSubmit = async (formData: AgendaItemFormSchemaType) => {
    try {
      const payload = {
        addressee_id: formData.addresseeId,
        description: formData.description,
        meeting_id: meetingId,
        priority: formData.priority,
        title: formData.title,
      }

      const requestMethod = agendaItem !== undefined ? 'put' : 'post'
      const requestPath =
        agendaItem !== undefined
          ? `/api/v1/agenda_items/${agendaItem?.id}`
          : '/api/v1/agenda_items'
      const agendaItemResponse = await Fetcher[requestMethod]<
        AgendaItemRequestParams,
        AgendaItemIndexView
      >(requestPath, payload)

      if (onSuccess) {
        onSuccess(agendaItemResponse)
      }

      notifications.show({
        color: 'green',
        message: tra('agendaItemForm:successMessage'),
        title: tra('agendaItemForm:successTitle'),
      })
    } catch (e) {
      showErrorNotification(e)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <h1 data-testid="new-agenda-item-form-error">{error.message}</h1> // TODO: Replace this
  }

  return (
    <form
      className="flexColumnForm"
      data-testid="new-agenda-item-form"
      onSubmit={form.onSubmit(onSubmit)}
    >
      {!isAddresseeKnown && (
        <UserSingleSelect
          description={tra('agendaItemForm:addresseeDescription')}
          label={tra('agendaItemForm:addresseeLabel')}
          placeholder={tra('agendaItemForm:addresseePlaceholder')}
          users={users}
          withAsterisk
          {...form.getInputProps('addresseeId')}
        />
      )}
      <TextInput
        data-testid="new-agenda-item-form-title"
        description={tra('agendaItemForm:titleDescription')}
        label={tra('agendaItemForm:titleLabel')}
        placeholder={tra('agendaItemForm:titlePlaceholder')}
        withAsterisk
        {...form.getInputProps('title')}
      />
      <Textarea
        data-testid="new-agenda-item-form-description"
        description={tra('agendaItemForm:descriptionDescription')}
        label={tra('agendaItemForm:descriptionLabel')}
        placeholder={tra('agendaItemForm:descriptionPlaceholder')}
        {...form.getInputProps('description')}
      />

      <Select
        data={priorities}
        description={tra('agendaItemForm:priorityDescription')}
        label={tra('agendaItemForm:priorityLabel')}
        {...form.getInputProps('priority')}
      />
      <Button
        data-testid="new-agenda-item-form-submit-btn"
        leftSection={<IconMessagePlus />}
        mt="sm"
        type="submit"
      >
        {tra('agendaItemForm:create')}
      </Button>
    </form>
  )
}
