import {
  ActionIcon,
  Avatar,
  Badge,
  Divider,
  Group,
  Menu,
  Modal,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconCalendarClock,
  IconCalendarEvent,
  IconClock,
  IconDots,
  IconEdit,
  IconFlag,
  IconMessagePlus,
  IconNotes,
  IconTrash,
  IconUser,
  IconUserCheck,
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import TodoDrawer from '~/components/dashboard/todo_drawer'
import { Todo } from '~/types/todo'

const priorityColors: Record<string, string> = {
  high: 'red',
  low: 'blue',
  medium: 'yellow',
}

type TodoDetailProps = {
  isModalOpen: boolean
  onDelete: () => void
  onEdit: (todo: Todo) => void
  onModalClosed: () => void
  onStatusChange: (status: string) => void
  todo: Todo
}

export default function ToDoDetail({
  isModalOpen,
  onDelete,
  onEdit,
  onModalClosed,
  onStatusChange,
  todo,
}: TodoDetailProps) {
  const { t: tra } = useTranslation()
  const [isTodoDrawerOpen, { close: closeTodoDrawer, open: openTodoDrawer }] =
    useDisclosure(false)

  return (
    <Modal
      closeOnClickOutside
      data-testid="todo-detail-modal"
      opened={isModalOpen}
      size="xl"
      withCloseButton={false}
      onClose={onModalClosed}
    >
      <Paper p="md" shadow="sm" withBorder>
        <Stack gap="md">
          <Text size="xl" w={700}>
            {todo.title}
          </Text>
          <Group gap="xs" justify="space-between">
            <Badge
              color={priorityColors[todo.priority]}
              size="lg"
              variant="filled"
            >
              <Group gap="xs">
                <IconFlag size={14} />
                {todo.priority}
              </Group>
            </Badge>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon>
                  <IconDots size={18} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{tra('common:actions')}</Menu.Label>
                <Menu.Item
                  leftSection={<IconEdit size={14} />}
                  onClick={openTodoDrawer}
                >
                  {tra('common:edit')}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Label>{tra('common:change_status')}</Menu.Label>
                <Menu.Item onClick={() => onStatusChange('open')}>
                  {tra('common:todo')}
                </Menu.Item>
                <Menu.Item onClick={() => onStatusChange('in_progress')}>
                  {tra('common:in_progress')}
                </Menu.Item>
                <Menu.Item onClick={() => onStatusChange('completed')}>
                  {tra('common:completed')}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} />}
                  onClick={onDelete}
                >
                  {tra('common:delete')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          {todo.description && (
            <Group gap="xs" justify="flex-start" wrap="nowrap">
              <IconNotes size={20} />
              <Text>{todo.description}</Text>
            </Group>
          )}

          {todo.due_date && (
            <Group gap="xs" justify="flex-start" wrap="nowrap">
              <IconCalendarClock size={20} />
              <Text>
                Due date: {dayjs(todo.due_date).format('MMMM DD, YYYY')}
              </Text>
            </Group>
          )}

          {todo.agenda_item?.meeting && (
            <Stack gap="xs">
              <Group gap="xs" wrap="nowrap">
                <IconCalendarEvent size={20} />
                <Text>
                  Created in meeting: {todo.agenda_item?.meeting?.title}
                </Text>
              </Group>
              <Group gap="xs" wrap="nowrap">
                <IconMessagePlus size={20} />
                <Text>For agenda item: {todo.agenda_item?.title}</Text>
              </Group>
            </Stack>
          )}

          <Group gap="xs">
            <Group gap="xs" wrap="nowrap">
              <IconUser size={20} />
              <Text>Creator: &nbsp;</Text>
              <Avatar
                radius="xl"
                size="sm"
                src={todo.creator.profile_picture_url}
              />
              <Text w={500}>{todo.creator.full_name}</Text>
            </Group>

            <Group gap="xs" wrap="nowrap">
              <IconUserCheck size={20} />
              <Text>Assignee:</Text>
              <Avatar
                radius="xl"
                size="sm"
                src={todo.assignee.profile_picture_url}
              />
              <Text w={500}>{todo.assignee.full_name}</Text>
            </Group>
          </Group>

          <Divider />

          <Group gap="xs" wrap="nowrap">
            <IconClock size={20} />
            <Text c="dimmed" size="sm">
              Created on {dayjs(todo.created_at).format('MMMM DD, YYYY')} at{' '}
              {dayjs(todo.created_at).format('h:mm A')}
            </Text>
          </Group>

          <Badge radius="sm" size="lg" variant="outline">
            {tra(`common:${todo.status}`)}
          </Badge>
        </Stack>
      </Paper>
      {isTodoDrawerOpen && (
        <TodoDrawer
          opened={isTodoDrawerOpen}
          todo={todo}
          users={[todo.assignee, todo.creator]}
          onClose={closeTodoDrawer}
          onSuccess={(updatedTodo) => {
            closeTodoDrawer()
            onEdit(updatedTodo)
          }}
        />
      )}
    </Modal>
  )
}
