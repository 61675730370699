import { Alert, AlertProps } from '@mantine/core'
import { IconExclamationCircle } from '@tabler/icons-react'
import i18next from 'i18next'

import VoynError from '~/utils/error'

export function hasFormAnyErrors(errors = {}) {
  return Object.keys(errors).length > 0
}

function ErrorAlert(props: { error: unknown } & AlertProps) {
  const { error } = props

  const message =
    error instanceof VoynError ? error.message : i18next.t('errors:oops')

  return (
    <Alert color="red" icon={<IconExclamationCircle />}>
      {message}
    </Alert>
  )
}

export default ErrorAlert
