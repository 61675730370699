import { Loader, Paper, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useSWRImmutable from 'swr/immutable'

import AgendaItemCard from '~/components/dashboard/agenda_item_card'
import { AgendaItemIndexView } from '~/types/agenda_item'
import {
  AgendaItemFilter,
  AgendaItemFilterPayload,
  filterToPayload,
} from '~/utils/agenda_item_filter'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function TalkingPointsAddressingYouCard() {
  const { user: currentUser } = useSession()

  const aiFilters: AgendaItemFilter = {
    addresseeId: currentUser.id,
    completed: false,
    meetingId: null,
  }

  const { t: tra } = useTranslation()

  const {
    data: agendaItems,
    error,
    isLoading,
  } = useSWRImmutable(
    {
      ...aiFilters,
      url: '/api/v1/agenda_items',
    },
    ({ url, ...rest }) =>
      Fetcher.get<AgendaItemFilterPayload, AgendaItemIndexView[]>(
        url,
        filterToPayload(rest),
      ),
  )
  const hasAgendaItems = agendaItems && agendaItems.length > 0

  if (error) {
    return <h1 data-testid="talking-points-addressing-you-error">ERROR</h1>
  }

  if (isLoading) {
    return <Loader data-testid="talking-points-addressing-you-loader" />
  }

  if (agendaItems) {
    return (
      <Paper data-testid="talking-points-addressing-you" p="md" withBorder>
        <Title order={4}>{tra('home:talkingPointsAddressingYouHeader')}</Title>
        {agendaItems.map((ai) => (
          <AgendaItemCard agendaItem={ai} displayCreator key={ai.id} />
        ))}
      </Paper>
    )
  }

  return null
}

export default TalkingPointsAddressingYouCard
