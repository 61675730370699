import { LoadingOverlay } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { Checkin } from '~/types/checkin'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'

import CheckinForm from './form'

function CreateCheckinPage() {
  const { data: nodes, isLoading } = useSWR(`/api/v1/nodes`, (url) =>
    Fetcher.get<{}, VNode[]>(url),
  )
  const navigate = useNavigate()

  const createCheckin = async (checkin: Partial<Checkin>) => {
    await Fetcher.post<{ checkin: Partial<Checkin> }, Checkin>(
      '/api/v1/checkins',
      {
        checkin,
      },
    )
    navigate('/dashboard/admin/checkins')
  }

  if (isLoading) {
    return <LoadingOverlay />
  }

  return (
    <CheckinForm
      checkin={undefined}
      isLoading={isLoading}
      nodes={nodes}
      onSave={createCheckin}
    />
  )
}

export default CreateCheckinPage
