import { Button, NativeSelect, Stack, Switch, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { TFunction } from 'i18next'
import { z } from 'zod'

import UserSingleSelect from '~/components/dashboard/user_single_select'
import { mapRolesToSelectOptions } from '~/pages/dashboard/admin/users/common'
import { UserIndexView } from '~/types/user'
import { Role } from '~/utils/constants'
import Fetcher from '~/utils/fetcher'
import validateForm from '~/utils/validate-form'

const editUserFormSchema = z.object({
  active: z.boolean(),
  email: z.string().email(),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  parent_id: z.string().uuid().nullable(),
  role: z.nativeEnum(Role),
})

type EditUserFormSchemaType = z.infer<typeof editUserFormSchema>

export default function EditUserForm({
  onUpdate,
  tra,
  user,
  users,
}: {
  onUpdate: (updatedUser: UserIndexView) => void
  tra: TFunction
  user: UserIndexView
  users: UserIndexView[]
}) {
  const form = useForm({
    initialValues: {
      active: user.active,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      parent_id: user.parent_id,
      role: user.role,
    },
    validate: (values) => {
      return validateForm(editUserFormSchema, values)
    },
  })

  const updateUser = async (formData: EditUserFormSchemaType) => {
    // @TODO: Wrap this in a try/catch block
    const response: UserIndexView = await Fetcher.put(
      `/api/v1/users/${user.id}`,
      {
        user: formData,
      },
    )
    onUpdate(response)
  }

  return (
    <form onSubmit={form.onSubmit((values) => updateUser(values))}>
      <Stack gap="sm">
        <TextInput
          data-testid="first-name-input"
          label={tra('common:firstName')}
          {...form.getInputProps('first_name')}
        />
        <TextInput
          data-testid="last-name-input"
          label={tra('common:lastName')}
          {...form.getInputProps('last_name')}
        />
        <TextInput
          data-testid="email-input"
          label={tra('common:email')}
          {...form.getInputProps('email')}
        />
        <NativeSelect
          data={mapRolesToSelectOptions(tra)}
          data-testid="role-select"
          label={tra('common:role')}
          {...form.getInputProps('role')}
        />
        <UserSingleSelect
          label={tra('common:manager')}
          users={users}
          {...form.getInputProps('parent_id')}
        />
        <Switch
          checked={form.values.active}
          data-testid="active-switch"
          {...form.getInputProps('active')}
          label={tra('users:active')}
        />
        <Button type="submit">{tra('common:save')}</Button>
      </Stack>
    </form>
  )
}
