import {
  AppShell,
  Avatar,
  Image,
  LoadingOverlay,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core'
import {
  IconCalendar,
  IconCampfire,
  IconCheckbox,
  IconClockCheck,
  IconHome,
  IconMessages,
  IconPlus,
  IconSearch,
  IconSettings,
} from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import openNewActionModal from '~/components/dashboard/new_action_modal'
import { searchHandlers, VoynSpotlight } from '~/components/dashboard/spotlight'
import Logo from '~/images/logo-icon.png'
import classes from '~/layouts/dashboard.module.css'
import { fetchSession, isUserToBeOnboarded } from '~/utils/session'

type NavItem = {
  href?: string
  icon: ReactNode
  label: string
  onClick?: () => void
}

interface NavbarLinkProps {
  icon: ReactNode
  isActive?: boolean
  label: string
  onClick?(): void
  tra: TFunction
}

function NavbarLink({ icon, isActive, label, onClick, tra }: NavbarLinkProps) {
  return (
    <Tooltip
      label={tra(label)}
      position="right"
      transitionProps={{ duration: 0 }}
    >
      <UnstyledButton
        className={`${classes.link} ${isActive ? classes.active : ''}`}
        ta="center"
        onClick={onClick}
      >
        {icon}
      </UnstyledButton>
    </Tooltip>
  )
}

function topNavItems(role: string): NavItem[] {
  const items = [
    {
      href: 'home',
      icon: <IconHome size={28} />,
      label: 'navigation:home',
    },
    {
      href: 'team',
      icon: <IconCampfire size={28} />,
      label: 'navigation:my_team',
    },
    {
      href: 'todos',
      icon: <IconCheckbox size={28} />,
      label: 'navigation:todos',
    },
    {
      href: 'checkins',
      icon: <IconClockCheck size={24} />,
      label: 'navigation:checkins',
    },
    {
      href: 'feedback',
      icon: <IconMessages size={24} />,
      label: 'navigation:feedback',
    },
    {
      href: 'calendar',
      icon: <IconCalendar size={24} />,
      label: 'navigation:calendar',
      order: 1,
    },
  ]

  if (role === 'admin') {
    items.push({
      href: 'admin',
      icon: <IconSettings size={28} />,
      label: 'navigation:admin',
    })
  }

  return items
}

function DashboardLayout() {
  const { error, isLoading, session } = fetchSession()
  const navigate = useNavigate()
  const { i18n, t: tra } = useTranslation()

  useEffect(() => {
    if (session?.user) {
      i18n.changeLanguage(session.user.language)
    }
  }, [session])

  if (isLoading) {
    return <LoadingOverlay overlayProps={{ blur: 2 }} visible />
  }

  if (!session || error) {
    return <Navigate replace to="/login" />
  }

  if (isUserToBeOnboarded(session)) {
    return <Navigate replace to="/onboarding" />
  }

  const bottomNavItems = [
    {
      icon: <IconSearch />,
      label: 'Search',
      onClick: () => {
        searchHandlers.open()
      },
    },
    {
      icon: <IconPlus />,
      label: 'Create new talking point, meeting, or feedback',
      onClick: () => {
        openNewActionModal(session)
      },
    },
    {
      href: 'profile',
      icon: (
        <Stack align="center" gap="0">
          <Avatar
            radius="xl"
            size="md"
            src={session.user.profile_picture_url}
          />
          <Text c="black" size="sm" visibleFrom="sm">
            {session.user.first_name}
          </Text>
        </Stack>
      ),
      label: 'navigation:profile',
    },
  ]

  const createNavList = (items: NavItem[]) => {
    return items.map((link) => (
      <NavbarLink
        {...link}
        isActive={
          link.href
            ? window.location.pathname === `/dashboard/${link.href}`
            : false
        }
        key={link.label}
        tra={tra}
        onClick={() => {
          if (link.href) {
            navigate(`/dashboard/${link.href}`)
          } else if (link.onClick) {
            link.onClick()
          }
        }}
      />
    ))
  }

  const desktopTopNav = (
    <Stack align="center" gap="md">
      <Image alt="Login Image" fit="contain" src={Logo} w={48} />
      {createNavList(topNavItems(session.user.role))}
    </Stack>
  )

  const desktopBottomNav = (
    <Stack align="center" gap="md">
      {createNavList(bottomNavItems)}
    </Stack>
  )

  return (
    <AppShell
      navbar={{
        breakpoint: 'sm',
        width: 96,
      }}
      padding="0"
    >
      <AppShell.Navbar>
        <Stack className={classes.desktopNav}>
          {desktopTopNav}
          <Stack>{desktopBottomNav}</Stack>
        </Stack>
      </AppShell.Navbar>
      <VoynSpotlight />
      <AppShell.Main bg="gray.0">
        <div id="dashboardContainer">
          <Outlet context={{ ...session }} />
        </div>
      </AppShell.Main>
    </AppShell>
  )
}

export default DashboardLayout
