import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import FeedbackForm from '~/components/dashboard/feedback/feedback_form'
import type { FeedbackRequest } from '~/types/feedback'
import { User } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function RequestsTable({
  onAccept,
  onReject,
  requests,
}: {
  onAccept?: () => void
  onReject?: () => void
  requests?: FeedbackRequest[]
}) {
  const { t: tra } = useTranslation()
  const { user: currentUser } = useSession()

  const requestFromSomeoneElse = (requester: Partial<User>) =>
    requester.id !== currentUser.id

  const generateDetail = (
    requester: Partial<User>,
    requestedFrom: Partial<User>,
  ) =>
    requestFromSomeoneElse(requester) ? (
      <Trans
        i18nKey="feedback_requests:request_sent_by"
        values={{ name: requester.full_name }}
      />
    ) : (
      <Trans
        i18nKey="feedback_requests:request_sent_to"
        values={{ name: requestedFrom.full_name }}
      />
    )

  const acceptRequest = async (request: FeedbackRequest) => {
    modals.open({
      children: [
        <FeedbackForm request={request} onSubmit={() => onAccept?.()} />,
      ],
      closeOnClickOutside: false,
      key: 'feedback_form_modal',
      modalId: 'feedback_form_modal',
      size: 'xl',
      title: tra('feedback_requests:provide_feedback', {
        name: request.requester.full_name,
      }),
    })
  }

  const rejectRequest = async (request: FeedbackRequest) => {
    modals.openConfirmModal({
      children: [
        <Text>{tra('feedback_requests:reject_request_confirmation')}</Text>,
      ],
      confirmProps: { color: 'red' },
      labels: {
        cancel: tra('common:cancel'),
        confirm: tra('common:reject_request'),
      },
      onConfirm: async () => {
        await Fetcher.post(`/api/v1/feedback_requests/${request.id}/reject`)
        onReject?.()
      },
      title: tra('feedback_requests:reject_request'),
    })
  }

  const generateRow = (request: FeedbackRequest) => (
    <Table.Tr key={request.id}>
      <Table.Td>
        {generateDetail(request.requester, request.requested_from)}
      </Table.Td>
      <Table.Td>{request.created_at}</Table.Td>
      <Table.Td>{request.deadline ?? tra('common:na')}</Table.Td>
      <Table.Td>{tra(`common:${request.status}`)}</Table.Td>
      <Table.Td>
        {requestFromSomeoneElse(request.requester) &&
          request.status === 'pending' && (
            <Group gap="xs">
              <Button
                data-testid="accept-feedback-request"
                onClick={() => acceptRequest(request)}
              >
                {tra('common:accept')}
              </Button>
              <Button
                data-testid="reject-feedback-request"
                onClick={() => rejectRequest(request)}
              >
                {tra('common:reject')}
              </Button>
            </Group>
          )}
        {!requestFromSomeoneElse(request.requester) && (
          <Text c="gray" size="sm">
            {tra('common:na')}
          </Text>
        )}
      </Table.Td>
    </Table.Tr>
  )

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{tra('common:detail')}</Table.Th>
          <Table.Th>{tra('common:request_date')}</Table.Th>
          <Table.Th>{tra('common:deadline')}</Table.Th>
          <Table.Th>{tra('common:status')}</Table.Th>
          <Table.Th>{tra('common:actions')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {requests === undefined || requests?.length === 0 ? (
          <Table.Tr>
            <Table.Td colSpan={4}>
              {tra('feedback_requests:no_request')}
            </Table.Td>
          </Table.Tr>
        ) : (
          requests!.map(generateRow)
        )}
      </Table.Tbody>
    </Table>
  )
}

export default function FeedbackRequests() {
  const { t: tra } = useTranslation()
  const {
    data: feedbackRequests,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/feedback_requests`, (url) =>
    Fetcher.get<{}, FeedbackRequest[]>(url),
  )
  const { user: currentUser } = useSession()

  const requestsReceived = feedbackRequests?.filter(
    (request: FeedbackRequest) => request.requested_from.id === currentUser.id,
  )
  const requestsSent = feedbackRequests?.filter(
    (request: FeedbackRequest) => request.requester.id === currentUser.id,
  )

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <Grid data-testid="feedback-requests-grid">
      <Grid.Col span={6}>
        <Paper radius="md" shadow="xs" withBorder>
          <Title order={2}>{tra('feedback_requests:sent_requests')}</Title>
          <RequestsTable requests={requestsSent} />
        </Paper>
      </Grid.Col>
      <Grid.Col span={6}>
        <Paper radius="md" shadow="xs" withBorder>
          <Title order={2}>{tra('feedback_requests:received_requests')}</Title>
          <RequestsTable
            requests={requestsReceived}
            onAccept={mutate}
            onReject={mutate}
          />
        </Paper>
      </Grid.Col>
    </Grid>
  )
}
