import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import {
  Anchor,
  Avatar,
  Badge,
  Button,
  Center,
  Divider,
  Group,
  Menu,
  Paper,
  rem,
  Stack,
  Title,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import {
  IconBrandGoogle,
  IconBrandOffice,
  IconCalendar,
  IconChevronDown,
  IconClock,
  IconPlus,
} from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { KeyedMutator, useSWRConfig } from 'swr'

import AgendaItemForm from '~/components/dashboard/agenda_item_form'
import DraggableAgendaItem from '~/components/dashboard/draggable_agenda_item'
import Header from '~/components/dashboard/header'
import LabeledAvatar from '~/components/dashboard/labeled_avatar'
import RescheduleMeetingForm from '~/components/dashboard/meetings/reschedule_meeting_form'
import { Meeting, MeetingDetailView } from '~/types/meeting'
import { MeetingKind } from '~/types/meeting_kind'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'
import useSocket from '~/utils/use_socket'

import {
  getOtherUser,
  removeTalkingPointFromMeeting,
  startTheMeeting,
} from './meeting_helper'
import OnoMeeting from './ono_meeting'

function generateGoogleCalendarLink(meetingDetail: MeetingDetailView) {
  const {
    duration,
    planned_start_time: plannedStartTime,
    title,
  } = meetingDetail
  const startTime = new Date(plannedStartTime)
  const formattedStartTime = startTime.toISOString().replace(/-|:|\.\d{3}/g, '')
  const endTime = new Date(startTime.getTime() + duration * 60 * 1000)
  const formattedEndTime = endTime.toISOString().replace(/-|:|\.\d{3}/g, '')
  const guests = meetingDetail.attendees
    .map((attendee) => attendee.email)
    .join(',')

  const titleEncoded = encodeURIComponent(title)
  const detailsEncoded = encodeURIComponent(
    `Join the meeting at ${window.location.origin}/meetings/${meetingDetail.id}`,
  )

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${titleEncoded}&dates=${formattedStartTime}/${formattedEndTime}&details=${detailsEncoded}&add=${guests}`
}

function generateOffice365Link(meetingDetail: MeetingDetailView) {
  const {
    duration,
    planned_start_time: plannedStartTime,
    title,
  } = meetingDetail
  const startTime = new Date(plannedStartTime)
  const formattedStartTime = startTime.toISOString().replace(/-|:|\.\d{3}/g, '')
  const endTime = new Date(startTime.getTime() + duration * 60 * 1000)
  const formattedEndTime = endTime.toISOString().replace(/-|:|\.\d{3}/g, '')
  const guests = meetingDetail.attendees
    .map((attendee) => attendee.email)
    .join(',')

  const titleEncoded = encodeURIComponent(title)
  const detailsEncoded = encodeURIComponent(
    `Join the meeting at ${window.location.origin}/meetings/${meetingDetail.id}`,
  )

  return `https://outlook.office.com/calendar/deeplink/compose?path=/calendar/action/compose&rru=addevent&to=${guests}&startdt=${formattedStartTime}&enddt=${formattedEndTime}&subject=${titleEncoded}&body=${detailsEncoded}`
}

function MeetingDetailPage(props: {
  meetingDetail: MeetingDetailView
  mutateMeetingDetail: KeyedMutator<MeetingDetailView>
}) {
  const { id: meetingId } = useParams()
  const { meetingDetail, mutateMeetingDetail } = props
  const { user: currentUser } = useSession()
  const { mutate: mutateAgendaItems } = useSWRConfig()
  const isOno = meetingDetail.kind === MeetingKind.ONO

  useSocket({
    channelName: 'PreMeetingChannel',
    onDataReceived: (data) => {
      mutateMeetingDetail(JSON.parse(data), { revalidate: false })
    },
    roomId: meetingDetail.id,
    roomName: 'pre_meeting',
  })

  const rescheduleMeeting = (meeting: Meeting) => {
    modals.open({
      children: (
        <RescheduleMeetingForm
          meeting={meeting}
          onSubmit={() => {
            mutateMeetingDetail()
            modals.closeAll()
          }}
        />
      ),
      title: 'Reschedule Meeting',
    })
  }

  const items = meetingDetail
    ? meetingDetail.agenda_items.map((item, index) => (
        <DraggableAgendaItem
          agendaItem={item}
          index={index}
          key={item.id}
          remove={(id) =>
            removeTalkingPointFromMeeting(id, () => {
              mutateMeetingDetail()

              mutateAgendaItems([
                '/api/v1/agenda_items',
                getOtherUser(meetingDetail, currentUser.id).id,
                false,
                currentUser.id,
                null,
              ])
            })
          }
        />
      ))
    : null

  if (!meetingDetail) {
    return null
  }

  return (
    <>
      <Header>
        <Group justify="space-between">
          <Group gap="xs">
            <Title order={3}>{meetingDetail.title}</Title>
            <Avatar.Group>
              {meetingDetail.attendees.map((user) => (
                <LabeledAvatar key={user.id} size="sm" user={user} />
              ))}
            </Avatar.Group>
            <Badge
              leftSection={
                <IconCalendar style={{ height: rem(12), width: rem(12) }} />
              }
              variant="outline"
            >
              {meetingDetail.planned_start_time}
            </Badge>
            <Badge
              leftSection={
                <IconClock style={{ height: rem(12), width: rem(12) }} />
              }
              variant="outline"
            >
              {meetingDetail.duration}m
            </Badge>
          </Group>
          <Group gap="xs">
            <Button
              variant="outline"
              onClick={() => rescheduleMeeting(meetingDetail)}
            >
              Reschedule
            </Button>
            <Menu>
              <Menu.Target>
                <Button
                  leftSection={<IconCalendar />}
                  rightSection={<IconChevronDown />}
                  size="sm"
                  variant="outline"
                >
                  Add to Calendar
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item leftSection={<IconBrandGoogle />}>
                  <Anchor
                    href={generateGoogleCalendarLink(meetingDetail)}
                    target="_blank"
                    underline="never"
                  >
                    Add to Google Calendar
                  </Anchor>
                </Menu.Item>
                <Menu.Item leftSection={<IconBrandOffice />}>
                  <Anchor
                    href={generateOffice365Link(meetingDetail)}
                    target="_blank"
                    underline="never"
                  >
                    Add to Office365
                  </Anchor>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Button
              onClick={() =>
                startTheMeeting(meetingDetail.id, mutateMeetingDetail)
              }
            >
              Start the Meeting
            </Button>
          </Group>
        </Group>
      </Header>
      <Group gap="0" h="100%">
        {isOno && (
          <OnoMeeting currentUser={currentUser} meetingDetail={meetingDetail} />
        )}
        <Stack gap="0" h="100%" p="sm" style={{ flex: 1 }}>
          <Title order={4}>Agenda</Title>
          <Divider mb="sm" />
          <Paper p="sm" shadow="0" withBorder>
            {meetingDetail.agenda_items && (
              <DragDropContext
                onDragEnd={({ destination, source }) => {
                  if (!destination) {
                    return
                  }

                  // If the item is dropped into the same place, do nothing
                  if (
                    destination.droppableId === source.droppableId &&
                    destination.index === source.index
                  ) {
                    return
                  }

                  // Creating a new copy of meetingAgendaItems array
                  const newMeetingAgendaItems = Array.from(
                    meetingDetail.agenda_items,
                  )

                  // Removing the dragged item from its original position
                  const [reorderedItem] = newMeetingAgendaItems.splice(
                    source.index,
                    1,
                  )

                  console.log('reordered', reorderedItem)

                  // Inserting the dragged item into its new position
                  newMeetingAgendaItems.splice(
                    destination.index,
                    0,
                    reorderedItem,
                  )

                  console.log('buradaki detail', meetingDetail)
                  mutateMeetingDetail(
                    {
                      ...meetingDetail,
                      agenda_items: newMeetingAgendaItems,
                    },
                    { revalidate: false },
                  ).then(() => {
                    Fetcher.post(
                      `/api/v1/meetings/${meetingId}/reorder_agenda`,
                      {
                        agenda_item_ids: newMeetingAgendaItems.map(
                          (item) => item.id,
                        ),
                      },
                    )
                  })
                }}
              >
                <Droppable direction="vertical" droppableId="dnd-list">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {items}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <Center>
              <Button
                leftSection={<IconPlus />}
                variant="subtle"
                onClick={() => {
                  modals.open({
                    children: (
                      <AgendaItemForm
                        addresseeId={
                          isOno
                            ? getOtherUser(meetingDetail, currentUser.id).id
                            : undefined
                        }
                        meetingId={meetingDetail.id}
                        onSuccess={(newItem) => {
                          modals.closeAll()
                          mutateMeetingDetail(
                            {
                              ...meetingDetail,
                              agenda_items: [
                                ...meetingDetail.agenda_items,
                                newItem,
                              ],
                            },
                            { revalidate: false },
                          )
                        }}
                      />
                    ),
                    title: 'Add new talking point',
                  })
                }}
              >
                Add agenda item
              </Button>
            </Center>
          </Paper>
        </Stack>
      </Group>
    </>
  )
}

export default MeetingDetailPage
