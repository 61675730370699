import { Divider, Paper, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import CheckinResponseComp from '~/pages/dashboard/checkins/checkin_response'
import Response from '~/pages/dashboard/checkins/response'
import type {
  Checkin,
  CheckinResponse,
  CheckinResponseView,
} from '~/types/checkin'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

export default function CheckinDetail({ checkin }: { checkin: Checkin }) {
  const { user } = useSession()
  const { t: tra } = useTranslation()
  const { data: responseData, mutate } = useSWR(
    [`/api/v1/checkins/${checkin.id}/responses`],
    ([url]) => Fetcher.get<{}, CheckinResponseView>(url),
  )

  const [responseToEdit, setResponseToEdit] = useState<CheckinResponse | null>(
    null,
  )
  const [isEditingPost, setIsEditingPost] = useState<boolean>(false)

  const onEditResponse = (response: CheckinResponse) => {
    setResponseToEdit(response)
    setIsEditingPost(true)
  }

  const [isEditable, setIsEditable] = useState<boolean>(false)
  useEffect(() => {
    if (isEditingPost === true) {
      setIsEditable(true)
    } else {
      setIsEditable(responseData?.responded === false)
    }
  }, [isEditingPost, responseData])

  const onResponseUpdated = () => {
    setIsEditingPost(false)
    setResponseToEdit(null)
    mutate({ ...responseData })
  }

  return (
    <Paper p="xl" radius="md" shadow="md" withBorder>
      <Stack>
        <Text fw={600} size="xl" ta="center">
          {checkin.question}
        </Text>
        <Text c="dimmed" size="md" ta="center">
          {tra('checkins:asking_x_people', {
            count: checkin.users_count,
            frequency: checkin.occurrence.frequency,
          })}
        </Text>
        <Stack>
          <CheckinResponseComp
            checkinId={checkin.id!}
            isEditable={isEditable}
            isEditing={isEditingPost}
            responseToEdit={responseToEdit}
            onResponseUpdated={onResponseUpdated}
          />
        </Stack>
        <Divider />
        {responseData?.responses.map((response) => (
          <div key={response.id}>
            <Response
              canEdit={response.user.id === user.id}
              response={response}
              onEditResponse={onEditResponse}
            />
            <Divider />
          </div>
        ))}
      </Stack>
    </Paper>
  )
}
