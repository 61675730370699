import { LoadingOverlay } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { Pulse } from '~/types/pulse'
import { VNode } from '~/types/vnode'
import Fetcher from '~/utils/fetcher'

import PulseForm from './form'

function CreatePulsePage() {
  const { data: nodes, isLoading } = useSWR(`/api/v1/nodes`, (url) =>
    Fetcher.get<{}, VNode[]>(url),
  )
  const navigate = useNavigate()

  const createPulse = async (pulse: Pulse) => {
    await Fetcher.post<{ pulse: Pulse }, Pulse>('/api/v1/pulses', {
      pulse,
    })
    navigate('/dashboard/admin/pulses')
  }

  if (isLoading) {
    return <LoadingOverlay />
  }

  return (
    <PulseForm
      isLoading={isLoading}
      nodes={nodes}
      pulse={undefined}
      onSave={createPulse}
    />
  )
}

export default CreatePulsePage
