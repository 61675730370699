import { Loader, Stack } from '@mantine/core'
import useSWR from 'swr'

import OrgChart from '~/components/dashboard/org_chart'
import { UserOrgChartView } from '~/types/user'
import Fetcher from '~/utils/fetcher'
import { useSession } from '~/utils/session'

function OrgChartPage() {
  const { user: currentUser } = useSession()
  const {
    data: users,
    error,
    isLoading,
  } = useSWR(`/api/v1/tenants/${currentUser.tenant_id}/org_chart`, (url) =>
    Fetcher.get<{}, UserOrgChartView[]>(url),
  )

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <h1>ERROR</h1>
  }

  if (users) {
    return (
      <Stack>
        <h1>ORG CHART</h1>
        <OrgChart users={users} />
      </Stack>
    )
  }

  return null
}

export default OrgChartPage
