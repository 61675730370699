import {
  Avatar,
  Checkbox,
  Combobox,
  Group,
  Pill,
  PillsInput,
  Stack,
  Text,
  TextInputProps,
  useCombobox,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { User } from '~/types/user'

const MAX_OPTIONS = 7

type UserMultiSelectProps = TextInputProps & {
  onChange: (value: string[] | null) => void
  users: User[]
}

function getFilteredOptions(users: User[], search: string, limit: number) {
  const normalizedSearch = search.trim().toLowerCase()

  return users
    .filter((user) => user.full_name.toLowerCase().includes(normalizedSearch))
    .slice(0, limit)
}

function SelectOption({
  currentValue,
  user,
}: {
  currentValue: string[]
  user: User
}) {
  return (
    <Group gap="sm">
      <Checkbox
        aria-hidden
        checked={currentValue ? currentValue.includes(user.id) : false}
        style={{ pointerEvents: 'none' }}
        tabIndex={-1}
        onChange={() => {}}
      />
      <Avatar size="sm" src={user.profile_picture_url} />
      <Stack gap="0">
        <Text fz="sm">{user.full_name}</Text>
        <Text c="gray" fz="xs">
          {user.title}
        </Text>
      </Stack>
    </Group>
  )
}

function initialVal(value: unknown): string[] {
  if (Array.isArray(value) && value.every((v) => typeof v === 'string')) {
    return value as string[]
  }

  return []
}

function UserMultiSelect(props: UserMultiSelectProps) {
  const { description, error, label, onChange, placeholder, users, value } =
    props
  const { t: tra } = useTranslation()
  const [val, setVal] = useState<string[]>(initialVal(value))
  const [search, setSearch] = useState<string>('')
  const selectedOptions = users.filter((u) => val.includes(u.id))

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch('')
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput()
    },
  })

  useEffect(() => {
    onChange(val)
    setSearch('')
  }, [value])

  const filteredOptions = getFilteredOptions(users, search, MAX_OPTIONS)

  const handleValueSelect = (newVal: string) => {
    setVal((current) =>
      current.includes(newVal)
        ? current.filter((v) => v !== newVal)
        : [...current, newVal],
    )

    combobox.focusSearchInput()
  }

  const handleValueRemove = (removedVal: string) =>
    setVal((current) => current.filter((v) => v !== removedVal))

  const values = selectedOptions.map((item) => (
    <Pill
      key={item.id}
      withRemoveButton
      onRemove={() => handleValueRemove(item.id)}
    >
      {item.full_name}
    </Pill>
  ))

  const options = filteredOptions.map((u) => (
    <Combobox.Option key={u.id} value={u.id}>
      <SelectOption currentValue={val} user={u} />
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.Target data-testid="user-multi-select-input-combobox">
        <PillsInput
          description={description}
          error={error}
          label={label}
          pointer
          rightSection={<Combobox.Chevron />}
          withAsterisk
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group>
            {values && values.length > 0 ? (
              values
            ) : (
              <PillsInput.Field placeholder={placeholder} />
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault()
                    handleValueRemove(val[val.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          placeholder={tra('common:searchUsers')}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              if (filteredOptions && filteredOptions.length > 0) {
                handleValueSelect(filteredOptions[0].id)
              }
            }
          }}
        />
        {options}
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default UserMultiSelect
