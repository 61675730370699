import { Editor, Extension, Range } from '@tiptap/core'
import Suggestion, { SuggestionProps } from '@tiptap/suggestion'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    slashMenu: {
      openTodoDrawer: () => ReturnType
    }
  }
}

const SlashMenu = Extension.create({
  addCommands() {
    return {
      openTodoDrawer: () => () => {
        this.options.openTodoDrawer()
        return true
      },
    }
  },
  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({
          editor,
          props,
          range,
        }: {
          editor: Editor
          props: SuggestionProps
          range: Range
        }) => {
          props.command({ editor, props, range })
        },
        startOfLine: true,
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },

  name: 'slashMenu',
})

export default SlashMenu
