import { HocuspocusProvider } from '@hocuspocus/provider'
import { Collaboration } from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import * as Y from 'yjs'

import createCollaborationCursor, {
  type CollaborationUser,
} from '~/components/dashboard/tiptap/collaboration_cursor'
import renderItems from '~/components/dashboard/tiptap/render_items'
import SlashMenu from '~/components/dashboard/tiptap/slash_menu'
import slashMenuItems from '~/components/dashboard/tiptap/slash_menu_items'
import TodoNode from '~/components/dashboard/tiptap/todo_node'
import { AgendaItemIndexView } from '~/types/agenda_item'
import { User } from '~/types/user'

interface CreateEditorProps {
  agendaItem: AgendaItemIndexView
  cssClasses: CSSModuleClasses
  doc: Y.Doc
  meetingUsers: User[]
  openTodoDrawer: () => void
  provider: HocuspocusProvider
  user: User
}

export default function createEditor(props: CreateEditorProps): Editor {
  const {
    agendaItem,
    cssClasses,
    doc,
    meetingUsers,
    openTodoDrawer,
    provider,
    user,
  } = props
  return new Editor({
    editorProps: {
      attributes: {
        class: cssClasses.editor,
      },
    },
    extensions: [
      StarterKit.configure({
        history: false, // important because history will now be handled by Y.js
      }),
      Collaboration.configure({
        document: doc,
        field: agendaItem.id,
      }),
      CollaborationCursor.configure({
        provider,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        render: (user: CollaborationUser) => {
          return createCollaborationCursor(user, cssClasses)
        },
        user: {
          avatar: user.profile_picture_url,
          name: user.full_name,
        },
      }),
      SlashMenu.configure({
        openTodoDrawer,
        suggestion: {
          items: slashMenuItems,
          render: renderItems,
        },
      }),
      TodoNode.configure({
        possibleAssignees: meetingUsers,
      }),
      Placeholder.configure({
        emptyNodeClass: cssClasses.emptyNode,
        placeholder: '/ to view all the commands', // TODO: Translate this
      }),
    ],
  })
}
