import { Group, Select, Stack, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useTranslation } from 'react-i18next'

import { Checkin } from '~/types/checkin'
import { Day, Frequency, Time } from '~/types/common'
import { arrayRange } from '~/utils/arrays'

type TimeOptions = {
  label: string
  value: string
}

export function Frequencies({ form }: { form: UseFormReturnType<Checkin> }) {
  const { t: tra } = useTranslation()
  const frequencies = [
    'daily',
    'weekly',
    'biweekly',
    'monthly',
    'quarterly',
    'annually',
  ].map((f) => ({ label: tra(`checkin:${f}`), value: f }))

  const setFrequency = (f: Frequency | null) => {
    form.setFieldValue('occurrence.frequency', f)
    if (f === 'daily') {
      form.setFieldValue('occurrence.day', null)
    }
  }

  return (
    <Select
      data={frequencies}
      placeholder={tra('checkin:frequency_label')}
      style={{ width: '170px' }}
      value={form.values.occurrence.frequency}
      onChange={(value, _) => setFrequency(value as Frequency)}
    />
  )
}

export function Days({
  disabled = false,
  form,
}: {
  disabled: boolean
  form: UseFormReturnType<Checkin>
}) {
  const { t: tra } = useTranslation()
  const setDay = (day: string | null) => {
    const newValue = day === form.values.occurrence.day ? '' : day
    form.setFieldValue('occurrence.day', newValue as Day)
  }

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map(
    (day) => ({ label: tra(`calendar:${day}`), value: day }),
  )

  return (
    <Select
      data={days}
      disabled={disabled}
      placeholder={tra('checkin:day_label')}
      value={form.values.occurrence.day}
      onChange={(d) => setDay(d)}
    />
  )
}

export function Times({ form }: { form: UseFormReturnType<Checkin> }) {
  const { t: tra } = useTranslation()
  const timeData: TimeOptions[] = arrayRange(7, 19)
    .map((r) => r.toString().padStart(2, '0'))
    .reduce(
      (acc: TimeOptions[], hour) =>
        acc.concat([
          { label: `${hour}:00`, value: `${hour}:00` },
          { label: `${hour}:30`, value: `${hour}:30` },
        ]),
      [],
    )
  const setTime = (time: string | null) =>
    form.setFieldValue('occurrence.time', time as Time)

  return (
    <Select
      data={timeData}
      placeholder={tra('checkin:time_label')}
      style={{ width: '170px' }}
      value={form.values.occurrence.time}
      onChange={setTime}
    />
  )
}

export function Occurrence({ form }: { form: UseFormReturnType<Checkin> }) {
  const { t: tra } = useTranslation()
  const shouldDisplayDays = form.values.occurrence?.frequency !== 'daily'

  return (
    <Stack>
      <Text size="md">{tra('checkin:occurrence_label')}</Text>
      <Group gap="md">
        <Frequencies form={form} />
        <Days disabled={!shouldDisplayDays} form={form} />
        <Times form={form} />
      </Group>
    </Stack>
  )
}
