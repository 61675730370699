import { Container, Paper } from '@mantine/core'
import { KeyedMutator } from 'swr'

import MeetingInfo from '~/components/dashboard/meeting_info'
import { MeetingDetailView } from '~/types/meeting'

type FinishedMeetingPageProps = {
  meetingDetail: MeetingDetailView
  mutateMeetingDetail: KeyedMutator<MeetingDetailView>
}

function FinishedMeetingPage({
  meetingDetail,
  mutateMeetingDetail,
}: FinishedMeetingPageProps) {
  return (
    <Container fluid py="sm">
      <Paper p="sm" withBorder>
        <MeetingInfo
          meetingDetail={meetingDetail}
          mutateMeetingDetail={mutateMeetingDetail}
        />
      </Paper>
    </Container>
  )
}

export default FinishedMeetingPage
