import { Container, Paper, Tabs, Title } from '@mantine/core'
import { IconLock, IconPlugConnected, IconUser } from '@tabler/icons-react'

import { useSession } from '~/utils/session'

import BasicInfo from './profile/basic_info'
import Integrations from './profile/integrations'
import Security from './profile/security'

export default function ProfilePage() {
  const { user } = useSession()

  return (
    <Container py="xl" size="lg">
      <Title mb="xl" order={1}>
        Your Profile
      </Title>
      <Paper p="md" shadow="xs">
        <Tabs defaultValue="basicInfo">
          <Tabs.List>
            <Tabs.Tab
              leftSection={<IconUser size="0.8rem" />}
              value="basicInfo"
            >
              Basic Info
            </Tabs.Tab>
            <Tabs.Tab
              leftSection={<IconPlugConnected size="0.8rem" />}
              value="integrations"
            >
              Integrations
            </Tabs.Tab>
            <Tabs.Tab leftSection={<IconLock size="0.8rem" />} value="security">
              Security
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel pt="xs" value="basicInfo">
            <BasicInfo user={user} />
          </Tabs.Panel>

          <Tabs.Panel pt="xs" value="integrations">
            <Integrations user={user} />
          </Tabs.Panel>

          <Tabs.Panel pt="xs" value="security">
            <Security user={user} />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Container>
  )
}
