import {
  Button,
  Group,
  LoadingOverlay,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import JobLevelForm from '~/components/dashboard/jobs/job_level_form'
import adminClasses from '~/layouts/admin.module.css'
import { type JobLevel, JobLevelsResponse } from '~/types/jobs'
import Fetcher from '~/utils/fetcher'

export default function JobLevelsPage() {
  const { t: tra } = useTranslation()
  const {
    data: jobLevelResponse,
    isLoading,
    mutate,
  } = useSWR(`/api/v1/job_levels`, (url) =>
    Fetcher.get<{}, JobLevelsResponse>(url),
  )

  const { job_levels: jobLevels, user_counts: userCounts } =
    jobLevelResponse ?? {}

  const onJobLevelSave = () => {
    mutate()
    modals.close('job_level_form_modal')
  }

  const openJobLevelForm = (jobLevel?: JobLevel) =>
    modals.open({
      children: <JobLevelForm jobLevel={jobLevel} onSave={onJobLevelSave} />,
      modalId: 'job_level_form_modal',
      title:
        jobLevel !== undefined
          ? tra('jobs:edit_level')
          : tra('jobs:add_new_level'),
    })

  const deleteJobLevel = async (jobLevel: JobLevel) => {
    modals.openConfirmModal({
      children: [<Text>{tra('jobs:delete_level_confirmation')}</Text>],
      confirmProps: { color: 'red' },
      labels: { cancel: tra('common:cancel'), confirm: tra('common:delete') },
      onConfirm: async () => {
        await Fetcher.delete(`/api/v1/job_levels/${jobLevel.id}`)
        mutate()
      },
      title: tra('jobs:delete_level'),
    })
  }

  const tableData = jobLevels?.map((level: JobLevel) => (
    <Table.Tr key={level.id}>
      <Table.Td>{level.job_function.name}</Table.Td>
      <Table.Td>{level.name}</Table.Td>
      <Table.Td>{level.title}</Table.Td>
      <Table.Td>
        {userCounts === undefined ? 0 : userCounts[level.id] ?? 0}
      </Table.Td>
      <Table.Td>
        <Group>
          <Button onClick={() => openJobLevelForm(level)}>
            {tra('common:edit')}
          </Button>
          <Button onClick={() => deleteJobLevel(level)}>
            {tra('common:delete')}
          </Button>
        </Group>
      </Table.Td>
    </Table.Tr>
  ))

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <>
      <Group className={adminClasses.adminMenuContentHeader}>
        <Title order={3}>{tra('jobs:menu_title')}</Title>
        <Button onClick={() => openJobLevelForm()}>
          {tra('jobs:add_new_level')}
        </Button>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{tra('jobs:function_name')}</Table.Th>
            <Table.Th>{tra('jobs:level_name')}</Table.Th>
            <Table.Th>{tra('jobs:job_title')}</Table.Th>
            <Table.Th>{tra('jobs:employee_count')}</Table.Th>
            <Table.Th>{tra('common:actions')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {jobLevels !== undefined && jobLevels.length > 0 ? (
            tableData
          ) : (
            <Table.Tr>
              <Table.Td colSpan={3}>{tra('jobs:no_levels_found')}</Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </>
  )
}
